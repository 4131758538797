import { IconButton, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { find } from 'lodash';

import useRoles from 'common/hooks/useRoles';

import EditShipmentDialog from './EditShipmentDialog';

const ShippingOptions = ({ orderShipmentId, mpi }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const hasAccess = useRoles();
  const patientInfo = useSelector(({ patient }) => patient[mpi]);
  const patientShipment = find(patientInfo?.shipmentHistory.shipments, { orderShipmentId });

  const spOrderId = patientShipment?.spOrderId;
  const trackingNumber = patientShipment?.shipmentData?.trackingNumber;
  const carrierCode = patientShipment?.shipmentData?.carrierCode;

  const handleMenuOpen = (event) => setAnchorEl(event.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);
  const menuOpen = !!anchorEl;

  const handleOpenModal = () => {
    setAnchorEl(null);
    setShowModal(true);
  };

  const handleCloseModal = (_, reason) => {
    if (reason !== 'backdropClick') {
      setShowModal(false);
    }
  };

  const handleCloseEditDialog = () => handleCloseModal();

  return (
    <>
      <IconButton
        id={`Shipments-${orderShipmentId}-options-button`}
        aria-label={`Shipments options ${orderShipmentId}`}
        onClick={handleMenuOpen}
      >
        <MoreVertIcon />
      </IconButton>

      <Menu
        id={`Shipments-${orderShipmentId}-options-menu`}
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={handleMenuClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem disabled={!hasAccess.editOrderShipment} onClick={handleOpenModal}>
          Update Shipment
        </MenuItem>
      </Menu>

      {showModal && (
        <EditShipmentDialog
          onClose={handleCloseEditDialog}
          open={showModal}
          orderShipmentId={orderShipmentId}
          mpi={mpi}
          spOrderId={spOrderId}
          trackingNumber={trackingNumber}
          carrierCode={carrierCode}
        />
      )}
    </>
  );
};

ShippingOptions.propTypes = {
  orderShipmentId: PropTypes.string.isRequired,
  mpi: PropTypes.string.isRequired,
};

export default ShippingOptions;
