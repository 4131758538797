import { format } from 'date-fns';
import {
  Box,
  Grid,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { includes, isEmpty, isNil, map, toLower } from 'lodash';

import LoadingModule from 'common/components/LoadingModule';
import NothingFound from 'common/components/NothingFound';
import PracticeProfileFieldKeys from 'enums/practice.enum';
import { visuallyHidden } from '@mui/utils';
import { SortOrder } from 'enums/common.enum';
import { listPracticeProfiles, updatePracticeProfileSortBy } from 'store/thunks/practiceThunks';

const headCells = [
  { id: PracticeProfileFieldKeys.PRACTICE_NAME, label: 'Practice Name', align: 'left' },
  { id: PracticeProfileFieldKeys.PRACTICE_SHORT_CODE, label: 'Practice ID', align: 'left' },
  { id: PracticeProfileFieldKeys.PARTNER_NAME, label: 'Partner Name', align: 'left' },
  { id: PracticeProfileFieldKeys.CREATED_AT, label: 'Created At', align: 'left' },
  { id: PracticeProfileFieldKeys.UPDATED_AT, label: 'Updated At', align: 'left' },
];

const sortableFields = [
  PracticeProfileFieldKeys.PRACTICE_NAME,
  PracticeProfileFieldKeys.PARTNER_NAME,
  PracticeProfileFieldKeys.CREATED_AT,
  PracticeProfileFieldKeys.UPDATED_AT,
];

const PracticeTable = () => {
  const dispatch = useDispatch();

  const { pages, sortBy, currentPage, count } = useSelector(
    ({ practice }) => practice.practiceManagement
  );

  const practiceProfileList = pages[currentPage];
  const totalPages = Math.ceil(count / 25);

  const handleSort = (event) => {
    const sortKey = event.currentTarget?.dataset?.headProperty;
    dispatch(
      updatePracticeProfileSortBy({
        sortBy: {
          sortKey,
          sortOrder:
            sortBy.sortKey === sortKey && sortBy.sortOrder === SortOrder.ASCENDING
              ? SortOrder.DESCENDING
              : SortOrder.ASCENDING,
        },
      })
    );
  };

  const handlePageChange = (_, page) => {
    if (currentPage === page) {
      return;
    }

    dispatch(listPracticeProfiles({ page }));

    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    dispatch(listPracticeProfiles({ page: 1 }));
  }, [dispatch]);

  return (
    <Grid container direction='column'>
      <Grid item>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {headCells.map((headCell) => (
                  <TableCell key={headCell.id} align={headCell.align}>
                    {!includes(sortableFields, headCell.id) ? (
                      headCell.label
                    ) : (
                      <TableSortLabel
                        active={sortBy?.sortKey === headCell.id}
                        direction={
                          sortBy?.sortKey === headCell.id ? toLower(sortBy?.sortOrder) : 'asc'
                        }
                        onClick={handleSort}
                        data-head-property={headCell.id}
                      >
                        {headCell.label}
                        {sortBy.sortKey === headCell.id ? (
                          <Box component='span' sx={visuallyHidden}>
                            {toLower(sortBy.sortOrder) === 'desc'
                              ? 'sorted descending'
                              : 'sorted ascending'}
                          </Box>
                        ) : null}
                      </TableSortLabel>
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {map(
                practiceProfileList,
                ({ partnerName, practiceName, practiceId, createdAt, updatedAt }) => (
                  <TableRow key={practiceId} hover>
                    <TableCell align='left'>{practiceName}</TableCell>
                    <TableCell align='left'>{practiceId}</TableCell>
                    <TableCell align='left'>{partnerName}</TableCell>
                    <TableCell align='left'>{format(new Date(createdAt), 'MM/dd/yyyy')}</TableCell>
                    <TableCell align='left'>{format(new Date(updatedAt), 'MM/dd/yyyy')}</TableCell>
                  </TableRow>
                )
              )}
            </TableBody>
            {!isNil(practiceProfileList) && totalPages > 1 && (
              <TableFooter>
                <TableRow>
                  <TableCell colSpan={9} align='right' sx={{ borderBottom: 'none' }}>
                    <Pagination
                      sx={{ justifyContent: 'flex-end', alignItems: 'flex-end', display: 'flex' }}
                      count={totalPages}
                      page={currentPage}
                      size='small'
                      onChange={handlePageChange}
                    />
                  </TableCell>
                </TableRow>
              </TableFooter>
            )}
          </Table>
        </TableContainer>
      </Grid>

      {isNil(practiceProfileList) && (
        <Grid item>
          <LoadingModule />
        </Grid>
      )}

      {!isNil(practiceProfileList) && isEmpty(practiceProfileList) && (
        <Grid>
          <NothingFound />
        </Grid>
      )}
    </Grid>
  );
};

export default PracticeTable;
