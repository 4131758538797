/* istanbul ignore file */
import { Grid, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Lottie from 'lottie-react';

import underMaintenance from 'assets/illustrations/underMaintenance.json';
import perfectRxLogo from 'assets/img/perfectRxLogo.png';

const UnderMaintenance = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Grid container justifyContent='center' sx={{ height: '100vh', px: 2 }}>
      <Grid
        container
        direction='column'
        sx={{ maxWidth: theme.maxWidth / 2, mt: isMobile ? '5vh' : '10vh' }}
      >
        <Grid item>
          <img src={perfectRxLogo} alt='SmartScripts' height={35} />
        </Grid>

        <Grid item sx={{ p: 3 }}>
          <Lottie animationData={underMaintenance} loop />
        </Grid>

        <Grid item>
          <Typography
            component='h1'
            variant={isMobile ? 'h5' : 'h4'}
            sx={{
              textAlign: 'center',
              fontWeight: 'bold',
              color: theme.palette.customTheme.headerText,
            }}
          >
            Scheduled maintenance is under progress
          </Typography>
        </Grid>

        <Grid item sx={{ mt: 1 }}>
          <Typography variant='h6' sx={{ textAlign: 'center' }}>
            We will be up and running soon. Please check again later.
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default UnderMaintenance;
