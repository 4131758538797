import { useEffect } from 'react';
import { Button, Chip, Stack } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import { compact, find, isEmpty, omit, values } from 'lodash';

import orderEnum from 'enums/order.enum';

import { filterOrders } from 'store/thunks/workQueueThunks';
import { getPartnerPractices } from 'store/thunks/partnerThunks';
import FacilityFilterStatusChip from './FacilityFilterStatusChip';

const defaultOrderStatus = [orderEnum.ORDER_VERIFICATION, orderEnum.ORDER_RE_VERIFICATION];

const OrderVerificationStatusBar = () => {
  const dispatch = useDispatch();
  const filter = useSelector(({ workQueue: { orderVerification } }) => orderVerification.filter);
  const partners = useSelector(({ auth, partner }) => partner[auth.currentTenantId]);

  const practices = find(partners, { partnerUuid: filter?.partnerId })?.partnerPractices;
  const practiceName = find(practices, {
    practiceId: filter?.practiceId,
  })?.practiceName;

  const isPracticeCleared = filter?.practiceId && !practiceName;

  useEffect(() => {
    if (isPracticeCleared) {
      dispatch(getPartnerPractices({ partnerId: filter.partnerId }));
    }
  }, [dispatch, isPracticeCleared, filter]);

  const removeFilter = (filterName) => () => {
    dispatch(
      filterOrders({
        filter: {
          ...filter,
          [filterName]: undefined,
          ...(filterName === 'orderStatus' && {
            orderStatus: defaultOrderStatus,
          }),
          ...(filterName === 'partnerId' && { practiceId: undefined }),
        },
      })
    );
  };

  const removeAllFilter = () =>
    dispatch(filterOrders({ filter: { orderStatus: defaultOrderStatus } }));

  return (
    <Stack direction='row' spacing={1}>
      <FacilityFilterStatusChip
        removeFilter={removeFilter}
        selectedFacilities={filter?.fulfillingPharmacyIds || []}
      />

      {filter.orderId && (
        <Chip
          label={`Order Id = ${filter.orderId}`}
          variant='contained'
          onDelete={removeFilter('orderId')}
        />
      )}

      {filter.needsByDate && (
        <Chip
          label={`Needs By Date = ${format(new Date(filter.needsByDate), 'MM/dd/yyyy')}`}
          variant='contained'
          onDelete={removeFilter('needsByDate')}
        />
      )}

      {filter.partnerId && (
        <Chip
          label={`Partner = ${find(partners, { partnerUuid: filter.partnerId }).partnerName}`}
          variant='contained'
          onDelete={removeFilter('partnerId')}
        />
      )}

      {filter.partnerId && filter.practiceId && practiceName && (
        <Chip
          label={`Practice = ${practiceName}`}
          variant='contained'
          onDelete={removeFilter('practiceId')}
        />
      )}
      {filter.patientName && (
        <Chip
          label={`Patient Name = ${filter.patientName}`}
          variant='contained'
          onDelete={removeFilter('patientName')}
        />
      )}

      {filter.partnerAccount && (
        <Chip
          label={`Partner Account = ${filter.partnerAccount}`}
          variant='contained'
          onDelete={removeFilter('partnerAccount')}
        />
      )}

      {filter?.orderStatus?.length < 2 && (
        <Chip
          label={`Status= ${filter.orderStatus}`}
          variant='contained'
          onDelete={removeFilter('orderStatus')}
        />
      )}

      {!isEmpty(compact(values(omit(filter, ['orderStatus'])))) && (
        <Button onClick={removeAllFilter}>Remove All</Button>
      )}
    </Stack>
  );
};

export default OrderVerificationStatusBar;
