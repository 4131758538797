import React from 'react';
import { Grid } from '@mui/material';

import PartnersTable from './components/PartnersTable';
import AddPartnerButton from './components/AddPartnerButton';
import PartnerFilterButton from './components/PartnerFilterButton';
import PartnerFilterStatusBar from './components/PartnerFilterStatusBar';

const PartnerManagement = () => {
  return (
    <Grid container sx={{ py: 2 }}>
      <Grid item sx={{ minHeight: 40, flexGrow: 1 }} alignItems='center'>
        <PartnerFilterStatusBar />
      </Grid>
      <Grid item sx={{ mb: 2 }}>
        <AddPartnerButton />
        <PartnerFilterButton />
      </Grid>

      <PartnersTable />
    </Grid>
  );
};

export default PartnerManagement;
