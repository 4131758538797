import { Button, Chip, Stack } from '@mui/material';
import { compact, isBoolean, isEmpty, values } from 'lodash';
import PracticeProfileFieldKeys from 'enums/practice.enum';
import { useDispatch, useSelector } from 'react-redux';
import { updatePracticeProfileFilter } from 'store/thunks/practiceThunks';

const PracticeFilterStatusBar = () => {
  const dispatch = useDispatch();

  const practiceFilter = useSelector(({ practice }) => practice.practiceManagement.filter);

  const removeFilter = (filterName) => () => {
    dispatch(
      updatePracticeProfileFilter({
        filter: {
          ...practiceFilter,
          [filterName]: undefined,
        },
      })
    );
  };

  const removeAllFilter = () => dispatch(updatePracticeProfileFilter({ filter: {} }));
  return (
    <Stack direction='row' spacing={1}>
      {practiceFilter?.practiceName && (
        <Chip
          label={`Practice Name = ${practiceFilter.practiceName}`}
          onDelete={removeFilter(PracticeProfileFieldKeys.PRACTICE_NAME)}
        />
      )}
      {practiceFilter?.partnerName && (
        <Chip
          label={`Partner Name = ${practiceFilter.partnerName}`}
          onDelete={removeFilter(PracticeProfileFieldKeys.PARTNER_NAME)}
        />
      )}
      {practiceFilter?.createdAt && (
        <Chip
          label={`Created At = ${practiceFilter.createdAt}`}
          onDelete={removeFilter(PracticeProfileFieldKeys.CREATED_AT)}
        />
      )}
      {practiceFilter?.updatedAt && (
        <Chip
          label={`Updated At = ${practiceFilter.updatedAt}`}
          onDelete={removeFilter(PracticeProfileFieldKeys.UPDATED_AT)}
        />
      )}
      {!isEmpty(compact(values(practiceFilter))) && !isBoolean(compact(values(practiceFilter))) && (
        <Button onClick={removeAllFilter}>Remove All</Button>
      )}
    </Stack>
  );
};
export default PracticeFilterStatusBar;
