import React from 'react';
import { Grid } from '@mui/material';
import AddPracticeButton from './components/AddPracticeButton';
import PracticeFilterButton from './components/PracticeFilterButton';
import PracticeTable from './components/PracticeTable';
import PracticeFilterStatusBar from './components/PracticeFilterStatusBar';

const PracticeManagement = () => {
  return (
    <Grid container sx={{ py: 2 }}>
      <Grid item sx={{ minHeight: 40, flexGrow: 1 }} alignItems='center'>
        <PracticeFilterStatusBar />
      </Grid>
      <Grid item sx={{ mb: 2 }}>
        <AddPracticeButton />
        <PracticeFilterButton />
      </Grid>

      <PracticeTable />
    </Grid>
  );
};

export default PracticeManagement;
