import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  practiceManagement: {
    currentPage: 1,
    pages: {},
    count: 0,
    filter: {
      practiceName: undefined,
      partnerName: undefined,
      createdAt: undefined,
      updatedAt: undefined,
    },
    sortBy: {
      sortKey: 'createdAt',
      sortOrder: 'ASC',
    },
  },
};

const practiceSlice = createSlice({
  name: 'practice',
  initialState,
  reducers: {
    listPracticeProfiles(state, action) {
      const { page, items, count } = action.payload;

      return {
        ...state,
        practiceManagement: {
          ...state.practiceManagement,
          count,
          pages: {
            ...state.practiceManagement.pages,
            [page]: items,
          },
        },
      };
    },
    updateCurrentPage(state, action) {
      const { page } = action.payload;

      return {
        ...state,
        practiceManagement: {
          ...state.practiceManagement,
          currentPage: page,
        },
      };
    },
    updateSortBy(state, action) {
      const { sortBy } = action.payload;

      return {
        ...state,
        practiceManagement: {
          ...state.practiceManagement,
          pages: {},
          count: 0,
          sortBy,
        },
      };
    },
    updateFilter(state, action) {
      const { filter } = action.payload;

      return {
        ...state,
        practiceManagement: {
          ...state.practiceManagement,
          pages: {},
          count: 0,
          filter,
        },
      };
    },
  },
});

export const practiceActions = practiceSlice.actions;
export default practiceSlice.reducer;
