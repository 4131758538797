// store/thunks/listPracticeProfiles.js
import { debounce } from 'lodash';
import { practiceActions } from 'store/slices/practiceSlice';
import { createPracticeApi, listPracticeApi } from 'api/practice';
import { handleError } from './errorHandlerThunks';

export const listPracticeProfiles =
  ({ page }) =>
  (dispatch, getState) => {
    const { sortBy, filter } = getState().practice.practiceManagement;

    dispatch(practiceActions.updateCurrentPage({ page }));

    return listPracticeApi({ page, sortBy, filter })
      .then(({ count, result }) => {
        dispatch(practiceActions.listPracticeProfiles({ page, items: result, count }));
      })
      .catch((error) => {
        dispatch(handleError({ error }));
      });
  };

export const debouncedListPracticeProfiles = debounce(({ dispatch }) => {
  return dispatch(listPracticeProfiles({ page: 1 }));
}, 1000);

export const updatePracticeProfileFilter =
  ({ filter }) =>
  (dispatch) => {
    dispatch(practiceActions.updateFilter({ filter }));
    debouncedListPracticeProfiles({ dispatch });
  };

export const updatePracticeProfileSortBy =
  ({ sortBy }) =>
  (dispatch) => {
    dispatch(practiceActions.updateSortBy({ sortBy }));
    debouncedListPracticeProfiles({ dispatch });
  };

export const createPractice =
  ({ practiceData }) =>
  (dispatch, getState) => {
    const { filter } = getState().practice.practiceManagement;
    const { practiceInput, partnerUUID } = practiceData;

    return createPracticeApi({ practiceInput, partnerUUID })
      .then((practice) => {
        dispatch(updatePracticeProfileFilter({ filter }));
        return practice;
      })
      .catch((error) => {
        dispatch(handleError({ error }));
      });
  };
