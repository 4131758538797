/* eslint-disable import/prefer-default-export */

import { map, sortBy } from 'lodash';

import { formatName } from 'common/utils';

export const transformListApiOrdersStatus = ({ listApiOrdersStatus }) => {
  const { result, count } = listApiOrdersStatus;
  const data = map(result, (order) => {
    const { patient, partner } = order;
    const { firstName, lastName } = patient;

    return {
      ...order,
      partnerName: partner.partnerName,
      patientName: `${formatName(lastName)}, ${formatName(firstName)}`,
    };
  });

  return { count, result: data };
};

export const transformPomRequestDetails = ({ getPomRequestByReferenceId: request }) => {
  const { events, practice, errors } = request;
  const { patient, externalPatientId, referenceId, prescription } = request;

  const eventData = map(events, (event) => ({
    id: event.id,
    actionItemType: event.actionItemType,
    interactionData: event.interactionData,
    timestamp: event.createdTimestamp,
    isProcessSuccess: true,
    user: event?.user,
    orderShipmentId: event?.orderShipmentId,
  }));

  const errorData = map(errors, (error) => ({
    id: error.id,
    actionItemType: error.actionItemType,
    interactionData: error.interactionData,
    timestamp: error.createdTimestamp,
    isProcessSuccess: false,
    user: error?.user,
  }));

  const sortedEvents = sortBy([...eventData, ...errorData], 'timestamp');

  return {
    patient: { ...patient, externalPatientId },
    referenceId,
    prescription,
    practice: { practiceId: practice.practiceId, practiceName: practice.practiceName },
    events: sortedEvents,
    errors: errorData,
  };
};
