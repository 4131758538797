import { useDispatch } from 'react-redux';
import { Suspense, cloneElement, useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { AppBar, Box, Toolbar, useMediaQuery, useScrollTrigger } from '@mui/material';
import { lighten, styled, useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';

import { workQueueActions } from 'store/slices/workQueueSlice';
import AppErrorBoundary from 'common/errors/AppErrorBoundary';
import perfectRxLogo from 'assets/img/perfectRxLogo.png';

import LoadingModule from '../components/LoadingModule';
import NavigationBar from './NavigationBar';
import UserAvatar from './UserAvatar';
import PatientSearch from './PatientSearch';
import PatientComboSearchSelect from './PatientComboSearchSelect';

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const RootContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  height: `calc(100vh - ${theme.mixins.toolbar.minHeight}px - 8px - 2.5em)`,
}));

const ElevationScroll = ({ children }) => {
  const theme = useTheme();
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 20,
  });

  return cloneElement(children, {
    elevation: /* istanbul ignore next */ trigger ? 4 : 0,
    sx: {
      bgcolor: /* istanbul ignore next */ trigger
        ? lighten(theme.palette.primary.main, 0.92)
        : 'inherit',
      pl: `calc(${theme.spacing(7)} + 1px)`,
      zIndex: theme.zIndex.drawer - 1,
    },
  });
};

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
};

const AppLayout = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [comboSearchType, setComboSearchType] = useState();

  useEffect(() => {
    dispatch(workQueueActions.setShowLimitedTabs({ showLimitedTabs: true }));
  }, [dispatch]);

  return (
    <RootContainer>
      <ScrollToTop />
      <ElevationScroll>
        <AppBar position='fixed'>
          <Toolbar variant='dense'>
            <div
              style={{
                flexGrow: 1,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {!isMobile && <img src={perfectRxLogo} alt='SmartScripts' height={15} />}
            </div>
            <PatientComboSearchSelect
              searchType={comboSearchType}
              setComboSearchType={setComboSearchType}
            />
            <PatientSearch searchType={comboSearchType} />
            <UserAvatar />
          </Toolbar>
        </AppBar>
      </ElevationScroll>

      <NavigationBar />

      <Box component='main' sx={{ flexGrow: 1, pl: `calc(${theme.spacing(7)} + 1px)` }}>
        <DrawerHeader />
        <AppErrorBoundary>
          <Suspense fallback={<LoadingModule />}>
            <Outlet />
          </Suspense>
        </AppErrorBoundary>
      </Box>
    </RootContainer>
  );
};

export default AppLayout;
