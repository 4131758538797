/* eslint-disable max-lines */
/* eslint-disable import/prefer-default-export */
import { gql } from 'graphql-request';

export const listOrdersGql = gql`
  query listOrders(
    $limit: Int = 25
    $offset: Int = 0
    $filter: OrdersFilter
    $sortBy: OrdersVerificationSortBy = { sortKey: needsByDate, sortOrder: DESC }
  ) {
    listOrders(limit: $limit, offset: $offset, filter: $filter, sortBy: $sortBy) {
      count
      orders: orders {
        orderId
        orderStatus
        requestNeedsByDate
        lastUpdated
        createdAt
        waitingOnFill
        totalInOrder
        patient {
          mpi: patientId
          patientDemographics {
            firstName
            lastName
          }
        }
        partner {
          partnerName
        }
        lineItems {
          itemName
          needsByDate
          requestedDate
          itemType
          lineItemId
          quantity
          rxNumber
          lineItemStatus
          form
          strength
          fillType
          shippingOption
          inBatchShipmentQueue
        }
        partnerAccountId
      }
    }
  }
`;

export const manageProductFulfillmentGql = gql`
  mutation manageProductFulfillmentStatus(
    $mpi: String!
    $orderId: String!
    $lineItemId: String!
    $state: ProductFulfillmentStatus!
    $failureReason: ProductFulfillmentFailureReason
  ) {
    manageProductFulfillmentStatus(
      lineItemId: $lineItemId
      mpi: $mpi
      orderId: $orderId
      state: $state
      failureReason: $failureReason
    ) {
      orderId
      status: orderStatus
      orderCost
      requestId
      requestNeedsByDate
      orderType
      isProcessedExternal
      fulfillingPharmacyId
      shippingAddress {
        line1
        line2
        city
        state
        zip
      }
      partner {
        partnerName
      }
      lineItems {
        itemName
        needsByDate
        requestedDate
        itemType
        lineItemId
        hasPinnedNote
        rxNumber
        strength
        form
        quantity
        lineItemStatus
        lastUpdated
        prescriptionId
      }
      partnerAccountId
    }
  }
`;

export const createOrderShipmentGql = gql`
  mutation createOrderShipment($mpi: String!, $shipmentType: String, $lineItemIds: [String!]!) {
    createOrderShipment(mpi: $mpi, shipmentType: $shipmentType, lineItemIds: $lineItemIds) {
      orderShipmentId
      shipmentData
      shipmentSpType
      shipmentStatus
      spOrderId
      orderIds
    }
  }
`;

export const verifyOrderGql = gql`
  mutation verifyOrder($orderId: String!, $mpi: UUID!) {
    verifyOrder(orderId: $orderId, mpi: $mpi) {
      orderId
      status: orderStatus
      orderCost
      shippingAddress {
        line1
        line2
        city
        state
        zip
      }
      partner {
        partnerName
      }
      lineItems {
        lineItemId
        cost
      }
      partnerAccountId
    }
  }
`;

export const listOrdersForShipmentGql = gql`
  query listOrdersForShipment(
    $limit: Int = 25
    $offset: Int = 0
    $filter: OrdersFilter
    $sortBy: OrdersSortBy = { sortKey: needsByDate, sortOrder: DESC }
  ) {
    listOrdersForShipment(limit: $limit, offset: $offset, filter: $filter, sortBy: $sortBy) {
      count
      orders: orders {
        orderId
        orderStatus
        requestNeedsByDate
        lastUpdated
        patient {
          mpi: patientId
          patientDemographics {
            firstName
            lastName
          }
        }
      }
    }
  }
`;

export const cancelOrderGql = gql`
  mutation cancelOrder(
    $orderId: String!
    $cancelReason: CancelReason!
    $cancelNote: CreateNoteInput!
  ) {
    cancelOrder(orderId: $orderId, cancelReason: $cancelReason, cancelNote: $cancelNote) {
      orderId
    }
  }
`;

export const cancelLineItemGql = gql`
  mutation cancelLineItem(
    $orderId: String!
    $lineItemId: String!
    $cancelReason: CancelReason!
    $cancelNote: CreateNoteInput!
  ) {
    cancelLineItem(
      orderId: $orderId
      lineItemId: $lineItemId
      cancelReason: $cancelReason
      cancelNote: $cancelNote
    ) {
      orderId
    }
  }
`;

export const orderNeedsReviewGql = gql`
  mutation markOrderForExternalProcessing($orderId: String!, $mpi: UUID!) {
    markOrderForExternalProcessing(orderId: $orderId, mpi: $mpi)
  }
`;

export const updateOrderAddressGql = gql`
  mutation updateOrderAddress($orderId: String!, $addressInput: AddPatientAddressInput!) {
    updateOrderAddress(orderId: $orderId, addressInput: $addressInput) {
      __typename
      ... on PatientAddress {
        address {
          addressLine1: line1
          addressLine2: line2
          city
          state
          zip
        }
        nameOnAddress
        tags
        patientAddressId
      }
      ... on Addresses {
        addresses {
          line1
          line2
          city
          state
          zip
        }
      }
    }
  }
`;

export const prescriptionNeedsReviewGql = gql`
  mutation prescriptionNeedsReview($prescriptionUUIDs: [String]!, $patientId: UUID!) {
    prescriptionNeedsReview(prescriptionUUIDs: $prescriptionUUIDs, patientId: $patientId)
  }
`;

export const getOrdersGql = gql`
  query listPomOrders(
    $limit: Int = 25
    $offset: Int = 0
    $filter: PomOrdersFilter
    $sortBy: PomOrdersSortBy = { sortKey: createdAt, sortOrder: DESC }
  ) {
    listPomOrders(limit: $limit, offset: $offset, filter: $filter, sortBy: $sortBy) {
      count
      result {
        orderId
        referenceId
        status
        lineItemId
        rxNumber
        patient {
          mpi
          firstName
          lastName
        }
        createdAt
        updatedAt
        partnerName
        partnerAccountId
        batchShipmentId
        inBatchShipping
        shippingOption
      }
    }
  }
`;

export const reverifyOrderGql = gql`
  mutation reverifyOrder($orderId: String!, $mpi: UUID!) {
    reverifyOrder(orderId: $orderId, mpi: $mpi) {
      orderId
      status: orderStatus
    }
  }
`;

export const listProcessedExternalOrdersGql = gql`
  query listProcessedExternalOrders(
    $limit: Int
    $offset: Int
    $filter: ExternalSystemOrderFilter
    $sortBy: ExternalSystemOrderSortBy
  ) {
    listProcessedExternalOrders(limit: $limit, offset: $offset, filter: $filter, sortBy: $sortBy) {
      count
      orders {
        orderId
        status
        createdAt
        partnerName
        partnerAccount
        patientFirstName
        patientLastName
        patientId
      }
    }
  }
`;

export const listBatchShipmentsGql = gql`
  query listBatchShipments(
    $limit: Int
    $offset: Int
    $sortOrder: SortOrder
    $filter: BatchShipmentsFilter
  ) {
    listBatchShipments(limit: $limit, offset: $offset, sortOrder: $sortOrder, filter: $filter) {
      count
      result {
        addressDirectoryId
        totalItems
        address {
          addressLine1
          addressLine2
          addressZip
          addressState
          addressCity
        }
      }
    }
  }
`;

export const listShipmentLineItemsGql = gql`
  query listShipmentLineItems($addressDirectoryId: String!, $limit: Int, $offset: Int) {
    listShipmentLineItems(addressDirectoryId: $addressDirectoryId, limit: $limit, offset: $offset) {
      count
      result {
        drugName
        rxNumber
        orderId
        lineItemId
        needsByDate
        lineItemStatus
        shippingOption
        patient {
          mpi
          firstName
          lastName
          middleName
          email
          mobilePhone
          gender
          dob
        }
      }
    }
  }
`;

export const updateAddressOrderLineItemStatusGql = gql`
  mutation updateAddressOrderLineItemStatus(
    $lineItemIds: [UUID!]!
    $addressDirectoryId: UUID!
    $status: AddressOrderLineItemsStatus
  ) {
    updateAddressOrderLineItemStatus(
      lineItemIds: $lineItemIds
      addressDirectoryId: $addressDirectoryId
      status: $status
    )
  }
`;

export const createShipmentForLineItemsGql = gql`
  mutation createShipmentForLineItems(
    $lineItemIds: [String!]!
    $addressDirectoryId: String!
    $nameOnAddress: String!
    $shippingOption: String!
  ) {
    createShipmentForLineItems(
      lineItemIds: $lineItemIds
      addressDirectoryId: $addressDirectoryId
      nameOnAddress: $nameOnAddress
      shippingOption: $shippingOption
    ) {
      orderIds
    }
  }
`;

export const listShippingOptionsGql = gql`
  query listShippingOptions {
    listShippingOptions {
      count
      shippingOptions {
        optionCode
        shippingOption
        daysToAdd
        carrierCode
        carrierShipmentCode
      }
    }
  }
`;

export const getBatchShipmentsGql = gql`
  query getBatchShipments($limit: Int, $offset: Int, $batchShipmentId: String!) {
    getBatchShipments(limit: $limit, offset: $offset, batchShipmentId: $batchShipmentId) {
      count
      response {
        lineItemId
        rxNumber
        patientId
        patientFirstName
        patientLastName
        drugName
        orderId
        trackingNumber
        createdAt
        fillType
      }
    }
  }
`;
