import { useState } from 'react';
import { Form } from 'react-final-form';
import { map } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Grid, MenuItem, Popover, Typography } from '@mui/material';

import TextField from 'common/forms/TextField';
import SelectField from 'common/forms/SelectField';
import usStateAbbreviation from 'common/constants/usStateAbbreviation';
import { updateBatchShipmentFilter } from 'store/thunks/batchShipmentThunk';
import { formatZipCode } from 'common/utils';
import { validateMinLength, validateZipCode } from 'common/forms/formValidations';

const BatchShipmentFilterButton = () => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const { filter } = useSelector(({ batchShipment }) => batchShipment);
  const popoverId = anchorEl ? 'BatchShipmentFilterButton-Popover' : undefined;

  const handleOpen = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const handleApplyFilter = (formData) => {
    setAnchorEl(null);

    dispatch(updateBatchShipmentFilter({ filter: formData }));
  };

  return (
    <>
      <Button
        variant='outlined'
        color='secondary'
        onClick={handleOpen}
        aria-describedby={popoverId}
      >
        Filter
      </Button>

      <Popover
        id={popoverId}
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Grid container sx={{ p: 2, maxWidth: 300 }} spacing={2} direction='column'>
          <Grid item>
            <Typography component='h2'>Filter</Typography>
          </Grid>

          <Grid item>
            <Form
              initialValues={filter}
              onSubmit={handleApplyFilter}
              render={({ handleSubmit, invalid }) => (
                <form onSubmit={handleSubmit} noValidate>
                  <TextField
                    label='Order Id'
                    name='orderId'
                    id='BatchShipmentFilterButton-orderId-input'
                    validations={[validateMinLength('Minimum of 3 characters', 3)]}
                  />

                  <TextField
                    label='Address Line 1'
                    name='line1'
                    id='BatchShipmentFilterButton-line1-input'
                    validations={[validateMinLength('Minimum of 3 characters', 3)]}
                  />

                  <TextField
                    label='Address Line 2'
                    name='line2'
                    id='BatchShipmentFilterButton-line2-input'
                  />

                  <TextField
                    label='City'
                    name='city'
                    id='BatchShipmentFilterButton-city-input'
                    validations={[validateMinLength('Minimum of 3 characters', 3)]}
                  />

                  <SelectField
                    id='BatchShipmentFilterButton-state-dropdown'
                    label='State'
                    name='state'
                  >
                    <MenuItem value={undefined}> None </MenuItem>
                    {map(usStateAbbreviation, ({ name, abbreviation }) => (
                      <MenuItem value={abbreviation} key={abbreviation}>
                        {name}
                      </MenuItem>
                    ))}
                  </SelectField>

                  <TextField
                    id='BatchShipmentFilterButton-zip-input'
                    label='Zip'
                    name='zip'
                    type='zip'
                    maxLength={10}
                    format={formatZipCode}
                    validations={[validateZipCode()]}
                  />

                  <Grid container justifyContent='flex-end'>
                    <Button variant='contained' type='submit' disabled={invalid}>
                      Apply
                    </Button>
                  </Grid>
                </form>
              )}
            />
          </Grid>
        </Grid>
      </Popover>
    </>
  );
};

export default BatchShipmentFilterButton;
