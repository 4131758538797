import { IconButton, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PropTypes from 'prop-types';

import { useState } from 'react';
import EditShipmentDialog from 'modules/PatientView/components/ShippingTab/EditShipmentDialog';

const ProcessedExternalOrderOptions = ({ orderId }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [showDialog, setShowDialog] = useState(false);

  const menuOpen = !!anchorEl;
  const handleMenuOpen = (event) => setAnchorEl(event.target);
  const handleMenuClose = () => setAnchorEl(null);
  const openDialog = () => setShowDialog(true);
  const closeDialog = () => setShowDialog(false);

  return (
    <>
      <IconButton
        id={`ProcessedExternalOrderOptions-${orderId}`}
        aria-label={`Order Options ${orderId}`}
        onClick={handleMenuOpen}
      >
        <MoreVertIcon />
      </IconButton>

      <Menu
        id={`Processed-external-order-${orderId}-options-menu`}
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={openDialog}>Edit Shipment</MenuItem>
      </Menu>

      {showDialog && (
        <EditShipmentDialog orderId={orderId} open={showDialog} onClose={closeDialog} />
      )}
    </>
  );
};

export default ProcessedExternalOrderOptions;

ProcessedExternalOrderOptions.propTypes = {
  orderId: PropTypes.string.isRequired,
};
