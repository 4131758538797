import { createSlice } from '@reduxjs/toolkit';

/* eslint no-param-reassign: ["error", { "ignorePropertyModificationsFor": ["state"] }] */
const shippingOptionsSlice = createSlice({
  name: 'systemConfigs',
  initialState: { shippingOptions: [] },
  reducers: {
    updateShippingOptions(state, action) {
      const { shippingOptions } = action.payload;

      state.shippingOptions = shippingOptions;
    },
  },
});

export const shippingOptionsActions = shippingOptionsSlice.actions;

export default shippingOptionsSlice.reducer;
