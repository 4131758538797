import { useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { lighten, useTheme } from '@mui/material/styles';
import StickyNote2Icon from '@mui/icons-material/StickyNote2';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { format } from 'date-fns';
import { includes, isEmpty, map, pickBy } from 'lodash';
import PropTypes from 'prop-types';

import lineItemStatus from 'common/constants/lineItemStatus';
import lineItemEnum from 'enums/lineItem.enum';

import CheckEligibilityButton from './CheckEligibilityButton';
import VerifyPrescriptionButton from '../PrescriptionVerification/VerifyPrescriptionButton';
import ReviewButton from './ReviewButton';
import ECommPrescriptionEligibilityButton from './ECommPrescriptionEligibilityButton';
import ReviewPrescriptionForm from '../PrescriptionVerification/ReviewPrescriptionForm';
import TransferRequestNoteOptions from './TransferRequestNoteOptions';

const headCells = [
  {
    id: 'needsByDate',
    label: 'Needs By Date',
  },
  {
    id: 'medication',
    label: 'Medication',
  },
  {
    id: 'form',
    label: 'Form',
  },
  {
    id: 'strength',
    label: 'Strength',
  },
  {
    id: 'status',
    label: 'Status',
    align: 'center',
  },
  {
    id: 'notes',
    label: '',
  },
  {
    id: 'checkState',
    label: '',
  },
  {
    id: 'options',
    label: '',
  },
];

const TransferRequestAccordion = ({ transferRequest, mpi, requestCreatedAt, requestId }) => {
  const theme = useTheme();
  const [activeNoteItem, setActiveNoteItem] = useState(null);

  const nonVerifiedItems = pickBy(transferRequest, ({ status }) =>
    includes(
      [
        lineItemEnum.CONTROLLED_SUBSTANCE_CHECK,
        lineItemEnum.PRESCRIPTION_CLARIFICATION_CHECK,
        lineItemEnum.CLAIM_PAY_CHECK,
        lineItemEnum.CLAIM_PROFITABLE_CHECK,
        lineItemEnum.PRESCRIPTION_VERIFICATION,
        lineItemEnum.RX_ENTRY,
        lineItemEnum.RX_REQUEST,
        lineItemEnum.PROVIDER_CONTACTED,
        lineItemEnum.RX_RECEIVED,
      ],
      status
    )
  );

  if (isEmpty(nonVerifiedItems)) {
    return null;
  }

  return (
    <Grid container direction='column' sx={{ mb: 1 }}>
      <Accordion defaultExpanded disableGutters>
        <AccordionSummary
          sx={{ bgcolor: lighten(theme.palette.text.primary, 0.9) }}
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`${requestId}-panel-content`}
          id={`${requestId}-panel-header`}
        >
          <Typography variant='subtitle2'>
            {format(new Date(requestCreatedAt), 'MM/dd/yyyy')} - {`Request ${requestId}`}
          </Typography>
        </AccordionSummary>

        <AccordionDetails>
          <Grid sx={{ my: 1 }} container direction='column'>
            <Grid item>
              <TableContainer>
                <Table size='small'>
                  <TableHead>
                    <TableRow hover>
                      {headCells.map((headCell) => (
                        <TableCell key={headCell.id} align={headCell.align}>
                          {headCell.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {map(nonVerifiedItems, (item) => (
                      <TableRow key={item.requestLineItemId} hover>
                        <TableCell>{format(new Date(item.needsByDate), 'MM/dd/yyyy')}</TableCell>
                        <TableCell>{item.name}</TableCell>
                        <TableCell>{item.form}</TableCell>
                        <TableCell>{item.strength}</TableCell>
                        <TableCell align='center'>{lineItemStatus[item.status]}</TableCell>
                        <TableCell align='right'>
                          {item.hasPinnedNote && (
                            <IconButton
                              onClick={() => setActiveNoteItem(item.requestLineItemId)}
                              aria-label={`View pinned notes ${item.requestLineItemId}`}
                            >
                              <StickyNote2Icon color='primary' />
                            </IconButton>
                          )}
                        </TableCell>
                        <TableCell align='center' sx={{ width: '16%' }}>
                          {!item.taggedForReview &&
                            includes(
                              [
                                lineItemEnum.CONTROLLED_SUBSTANCE_CHECK,
                                lineItemEnum.PRESCRIPTION_CLARIFICATION_CHECK,
                                lineItemEnum.CLAIM_PAY_CHECK,
                                lineItemEnum.CLAIM_PROFITABLE_CHECK,
                              ],
                              item.status
                            ) && <CheckEligibilityButton mpi={mpi} lineItem={item} />}

                          {!item.taggedForReview &&
                            includes(
                              [lineItemEnum.RX_ENTRY, lineItemEnum.PRESCRIPTION_VERIFICATION],
                              item.status
                            ) && (
                              <VerifyPrescriptionButton
                                mpi={mpi}
                                lineItem={item}
                                requestId={requestId}
                              />
                            )}

                          {item.taggedForReview &&
                            includes(
                              [
                                lineItemEnum.CONTROLLED_SUBSTANCE_CHECK,
                                lineItemEnum.PRESCRIPTION_CLARIFICATION_CHECK,
                                lineItemEnum.CLAIM_PAY_CHECK,
                                lineItemEnum.CLAIM_PROFITABLE_CHECK,
                              ],
                              item.status
                            ) && <ReviewButton mpi={mpi} lineItem={item} />}

                          {item.taggedForReview &&
                            includes([lineItemEnum.PRESCRIPTION_VERIFICATION], item.status) && (
                              <ReviewPrescriptionForm lineItem={item} mpi={mpi} />
                            )}

                          {includes(
                            [
                              lineItemEnum.RX_REQUEST,
                              lineItemEnum.PROVIDER_CONTACTED,
                              lineItemEnum.RX_RECEIVED,
                            ],
                            item.status
                          ) && <ECommPrescriptionEligibilityButton lineItem={item} mpi={mpi} />}
                        </TableCell>
                        <TableCell align='right' sx={{ width: 3, px: 0 }}>
                          <TransferRequestNoteOptions
                            itemId={item.requestLineItemId}
                            mpi={mpi}
                            requestId={item.requestId}
                            activeNoteItem={activeNoteItem}
                            setActiveNoteItem={setActiveNoteItem}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};

TransferRequestAccordion.propTypes = {
  mpi: PropTypes.string.isRequired,
  requestCreatedAt: PropTypes.string.isRequired,
  requestId: PropTypes.string.isRequired,
  transferRequest: PropTypes.shape({
    requestLineItemId: PropTypes.string,
    needsByDate: PropTypes.string,
    name: PropTypes.string,
    form: PropTypes.string,
    strength: PropTypes.string,
    status: PropTypes.string,
  }).isRequired,
};

export default TransferRequestAccordion;
