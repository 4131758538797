import { Button, Chip, Stack } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import { compact, find, isEmpty, values } from 'lodash';

import { filterPrescriptionsNeedsReview } from 'store/thunks/workQueueThunks';
import { SortKey } from 'enums/common.enum';
import { prescriptionReviewStatus } from 'common/constants/prescriptionStatus';

import orderFillType from 'common/constants/orderFillType';
import FacilityFilterStatusChip from './FacilityFilterStatusChip';

const PrescriptionReviewFilterStatusBar = () => {
  const dispatch = useDispatch();
  const filter = useSelector(({ workQueue }) => workQueue.prescriptionReview?.filter);
  const partners = useSelector(({ auth, partner }) => partner[auth.currentTenantId]);
  const practices = find(partners, { partnerUuid: filter?.partnerId })?.partnerPractices;
  const practiceName = find(practices, {
    practiceId: filter?.practiceId,
  })?.practiceName;

  const removeFilter = (filterName) => () => {
    dispatch(
      filterPrescriptionsNeedsReview({
        filter: {
          ...filter,
          [filterName]: undefined,
        },
      })
    );
  };

  const removeAllFilter = () => dispatch(filterPrescriptionsNeedsReview({ filter: {} }));

  return (
    <Stack direction='row' spacing={1}>
      <FacilityFilterStatusChip
        removeFilter={removeFilter}
        selectedFacilities={filter?.fulfillingPharmacyIds || []}
      />

      {filter?.drugName && (
        <Chip
          label={`Drug Name = ${filter.drugName}`}
          variant='contained'
          onDelete={removeFilter(SortKey.DRUG_NAME)}
        />
      )}

      {filter?.requestedDate && (
        <Chip
          label={`Requested Date = ${format(new Date(filter.requestedDate), 'MM/dd/yyyy')}`}
          variant='contained'
          onDelete={removeFilter(SortKey.REQUESTED_DATE)}
        />
      )}

      {filter.orderId && (
        <Chip
          label={`Order Id = ${filter.orderId}`}
          variant='contained'
          onDelete={removeFilter(SortKey.ORDER_ID)}
        />
      )}

      {filter.fillType && (
        <Chip
          label={`Fill Type = ${orderFillType[filter.fillType]}`}
          variant='contained'
          onDelete={removeFilter(SortKey.FILL_TYPE)}
        />
      )}

      {filter?.reviewStatus && (
        <Chip
          label={`Status =  ${prescriptionReviewStatus[filter.reviewStatus]}`}
          variant='contained'
          onDelete={removeFilter(SortKey.REVIEW_STATUS)}
        />
      )}

      {filter.partnerId && (
        <Chip
          label={`Partner = ${find(partners, { partnerUuid: filter.partnerId }).partnerName}`}
          variant='contained'
          onDelete={removeFilter('partnerId')}
        />
      )}

      {filter.partnerId && filter.practiceId && practiceName && (
        <Chip
          label={`Practice = ${practiceName}`}
          variant='contained'
          onDelete={removeFilter('practiceId')}
        />
      )}

      {!isEmpty(compact(values(filter))) && <Button onClick={removeAllFilter}>Remove All</Button>}
    </Stack>
  );
};

export default PrescriptionReviewFilterStatusBar;
