import {
  Box,
  Grid,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import { useEffect } from 'react';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { capitalize, isEmpty, isNil, map, toLower } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import visuallyHidden from '@mui/utils/visuallyHidden';

import LoadingModule from 'common/components/LoadingModule';
import NothingFound from 'common/components/NothingFound';
import orderStatus from 'common/constants/orderStatus';
import { SortOrder } from 'enums/common.enum';

import { ExternalOrdersFieldKeys } from 'enums/order.enum';
import {
  listProcessedExternalOrdersByPage,
  updateProcessedExternalOrdersSortBy,
} from 'store/thunks/workQueueThunks';
import ProcessedExternalOrderOptions from './ProcessedExternalOrderOptions';

const headCells = [
  {
    id: ExternalOrdersFieldKeys.ORDER_ID,
    label: 'Order Id',
  },
  {
    id: ExternalOrdersFieldKeys.PATIENT_NAME,
    label: 'Patient Name',
    sortable: true,
  },
  {
    id: ExternalOrdersFieldKeys.PARTNER_NAME,
    label: 'Partner Name',
    sortable: true,
  },
  {
    id: ExternalOrdersFieldKeys.PARTNER_ACCOUNT,
    label: 'Partner Account',
    sortable: true,
  },
  {
    id: ExternalOrdersFieldKeys.STATUS,
    label: 'Status',
    sortable: true,
  },
  {
    id: ExternalOrdersFieldKeys.CREATED_AT,
    label: 'Created At',
    sortable: true,
  },
];

const ProcessedExternalOrdersTable = () => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const { sortBy, pages, currentPage, count } = useSelector(
    ({ workQueue }) => workQueue.processedExternalOrders
  );

  const totalPages = Math.ceil(count / 25);
  const externalOrders = pages[currentPage];

  const handleSort = (event) => {
    const sortKey = event.currentTarget?.dataset?.headProperty;

    dispatch(
      updateProcessedExternalOrdersSortBy({
        sortBy: {
          sortKey,
          sortOrder:
            sortBy?.sortKey === sortKey && sortBy?.sortOrder === SortOrder.ASCENDING
              ? SortOrder.DESCENDING
              : SortOrder.ASCENDING,
        },
      })
    );
  };

  const handlePageChange = (_, page) => {
    if (currentPage === page) {
      return;
    }

    dispatch(listProcessedExternalOrdersByPage({ page }));

    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    dispatch(listProcessedExternalOrdersByPage({ page: 1 }));
  }, [dispatch]);

  return (
    <Grid container direction='column'>
      <Grid item>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {headCells.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    sortDirection={
                      sortBy.sortKey === headCell.id ? toLower(sortBy.sortOrder) : false
                    }
                  >
                    {headCell.sortable ? (
                      <TableSortLabel
                        active={sortBy.sortKey === headCell.id}
                        direction={
                          sortBy.sortKey === headCell.id ? toLower(sortBy.sortOrder) : 'asc'
                        }
                        onClick={handleSort}
                        data-head-property={headCell.id}
                      >
                        {headCell.label}
                        {sortBy.sortKey === headCell.id ? (
                          <Box component='span' sx={visuallyHidden}>
                            {toLower(sortBy.sortOrder) === SortOrder.DESCENDING
                              ? 'sorted descending'
                              : 'sorted ascending'}
                          </Box>
                        ) : null}
                      </TableSortLabel>
                    ) : (
                      headCell.label
                    )}
                  </TableCell>
                ))}
                <TableCell />
              </TableRow>
            </TableHead>

            <TableBody>
              {map(
                externalOrders,
                (
                  {
                    orderId,
                    status,
                    patientName,
                    partnerAccount,
                    partnerName,
                    createdAt,
                    patientId,
                  },
                  index
                ) => (
                  <TableRow key={`orderId${index}`} hover>
                    <TableCell>{orderId}</TableCell>
                    <TableCell component='th' scope='row'>
                      <Link
                        to={`/patients/${patientId}`}
                        style={{ color: theme.palette.text.primary }}
                      >
                        {patientName}
                      </Link>
                    </TableCell>
                    <TableCell>{partnerName}</TableCell>
                    <TableCell>{partnerAccount || 'N/A'} </TableCell>
                    <TableCell>{orderStatus[status] || capitalize(toLower(status))}</TableCell>
                    <TableCell>{format(new Date(createdAt), 'MM/dd/yyyy')}</TableCell>
                    <TableCell>
                      <ProcessedExternalOrderOptions orderId={orderId} />
                    </TableCell>
                  </TableRow>
                )
              )}
            </TableBody>

            {!isNil(externalOrders) && totalPages > 1 && (
              <TableFooter>
                <TableRow>
                  <TableCell colSpan={8} align='right' sx={{ borderBottom: 'none' }}>
                    <Pagination
                      sx={{ justifyContent: 'flex-end', alignItems: 'flex-end', display: 'flex' }}
                      count={totalPages}
                      page={currentPage}
                      size='small'
                      onChange={handlePageChange}
                    />
                  </TableCell>
                </TableRow>
              </TableFooter>
            )}
          </Table>
        </TableContainer>
      </Grid>

      {isNil(externalOrders) && (
        <Grid item>
          <LoadingModule />
        </Grid>
      )}

      {!isNil(externalOrders) && isEmpty(externalOrders) && (
        <Grid item>
          <NothingFound />
        </Grid>
      )}
    </Grid>
  );
};

export default ProcessedExternalOrdersTable;
