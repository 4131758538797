/* eslint-disable max-lines */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { lighten, useTheme } from '@mui/material/styles';
import StickyNote2Icon from '@mui/icons-material/StickyNote2';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { format } from 'date-fns';
import { capitalize, every, filter, find, includes, map, orderBy, some } from 'lodash';
import PropTypes from 'prop-types';

import orderStatus from 'common/constants/orderStatus';
import usePrintLabelContext, {
  PrintLabelCheckBox,
  SelectAllCheckBox,
} from 'common/hooks/usePrintLabelContext';
import { LoadingButton } from '@mui/lab';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { verifyOrder } from 'store/thunks/patientThunks';
import { useSnackbar } from 'notistack';
import useRoles from 'common/hooks/useRoles';
import { useGetPartnerAccountName } from 'common/utils';
import { SortKey, SortOrderEnum } from 'enums/common.enum';

import orderEnum from 'enums/order.enum';
import lineItemStatusEnum from 'enums/lineItem.enum';
import ShippingIcon from '@mui/icons-material/LocalShipping';
import orderFillType from 'common/constants/orderFillType';
import PatientNoteOptions from './PatientNoteOptions';
import PatientFillProductButton from './PatientFillProductButton';
import SendToShippingButton from './SendToShippingButton';
import CancelOrderButton from './CancelOrderButton';
import PatientPrescriptionDialog from '../PrescriptionsTab/PatientPrescriptionDialog';
import ReviewOrderVerificationButton from './ReviewOrderVerificationButton';
import EditOrderAddressButton from './EditOrderAddressButton';
import ReviewPrescriptionButton from './ReviewPrescriptionButton';
import ReverifyOrderButton from './ReVerifyOrderButton';
import TransferOrderButton from './TransferOrderButton';

const headCells = [
  {
    id: 'needsByDate',
    label: 'Needs By Date',
  },
  {
    id: 'itemName',
    label: 'Item Name',
  },
  {
    id: 'requestedDate',
    label: 'Requested Date',
  },
  {
    id: 'type',
    label: 'Type',
  },
  {
    id: 'rxNumber',
    label: 'RX Number',
  },
  {
    id: 'strength',
    label: 'Strength',
    align: 'center',
  },
  {
    id: 'form',
    label: 'Form',
  },
  {
    id: 'quantity',
    label: 'Quantity',
    align: 'center',
  },
  {
    id: 'partnerAccount',
    label: 'Partner Account',
    align: 'center',
  },
  {
    id: 'docutrackId',
    label: 'Docutrack Number',
    align: 'center',
  },
];

const PatientOrdersAccordion = ({ order, mpi }) => {
  const theme = useTheme();
  const orderLineItemIds = map(
    filter(
      order.lineItems,
      ({ lineItemStatus }) => lineItemStatus !== lineItemStatusEnum.RE_PROCESS
    ),
    'lineItemId'
  );
  const isLineItemsInReprocessingState = some(
    order.lineItems,
    ({ lineItemStatus }) => lineItemStatus === lineItemStatusEnum.RE_PROCESS
  );
  const [activeNoteItem, setActiveNoteItem] = useState(null);
  const [toolTipMsg, setToolTipMsg] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const hasAccess = useRoles();
  const displaySendToShipmentButton = includes(
    [orderEnum.ORDER_VERIFIED, orderEnum.HOLD],
    order.status
  );

  const partnerAccountHandler = useGetPartnerAccountName();

  const getPartnerAccountName = (partnerAccountId) => {
    return partnerAccountId && partnerAccountHandler[partnerAccountId]
      ? capitalize(partnerAccountHandler[partnerAccountId].partnerAccountName)
      : 'N/A';
  };

  const displayOrderCost = includes(
    [
      orderEnum.ORDER_VERIFIED,
      orderEnum.ADDED_TO_SHIPMENT,
      orderEnum.ORDER_VERIFICATION,
      orderEnum.ORDER_RE_VERIFICATION,
    ],
    order.status
  );
  const patientPrescriptions = useSelector(({ patient }) => patient[mpi]?.prescriptions ?? []);
  const [selectedPrescriptionId, setSelectedPrescriptionId] = useState(null);
  const [openPrescriptionForm, setOpenPrescriptionForm] = useState(false);
  const [showVerifyDialog, setShowVerifyDialog] = useState(false);
  const isRxEntryOrder = order?.orderType === 'RX_ENTRY';
  const activeLineItems = filter(
    order?.lineItems,
    ({ lineItemStatus }) => lineItemStatus !== lineItemStatusEnum.CANCELED
  );

  const disableVerifyOrderButton =
    some(map(activeLineItems, 'taggedForReview')) || order.isProcessedExternal;

  const showNeedsReviewButtonOnProductFulfillment =
    isRxEntryOrder &&
    hasAccess.markOrderForExternalProcessing &&
    !order.isProcessedExternal &&
    !every(order.lineItems, 'taggedForReview') &&
    order.status === orderEnum.OPEN;

  const showNeedsReviewButton =
    includes(['OPEN', 'ORDER_VERIFICATION', 'ORDER_RE_VERIFICATION'], order.status) &&
    some(order.lineItems, { taggedForReview: false }) &&
    !order.isProcessedExternal;

  const isLineItemsInBulkShipmentQueue = some(order.lineItems, { inBatchShipmentQueue: true });

  const showVerifyOrderBtn =
    includes([orderEnum.ORDER_VERIFICATION, orderEnum.ORDER_RE_VERIFICATION], order.status) &&
    hasAccess.verifyOrder;

  const showTransferOrderButton = includes(
    ['OPEN', 'ORDER_VERIFICATION', 'ORDER_RE_VERIFICATION'],
    order.status
  );

  useEffect(() => {
    if (order.isProcessedExternal) {
      setToolTipMsg('Order processing is external');
    } else if (disableVerifyOrderButton) {
      setToolTipMsg('Line Items needs to be reviewed');
    }
  }, [order.isProcessedExternal, disableVerifyOrderButton]);

  const onClose = () => setOpenPrescriptionForm(false);

  const handlePrescriptionForm = (prescriptionId) => {
    setSelectedPrescriptionId(prescriptionId);
    setOpenPrescriptionForm(!openPrescriptionForm);
  };

  const openVerifyDialog = () => setShowVerifyDialog(true);
  const closeVerifyDialog = () => setShowVerifyDialog(false);

  const handleVerifyOrder = () => {
    setIsLoading(true);
    dispatch(verifyOrder({ mpi, orderId: order.orderId })).then((isVerified) => {
      setIsLoading(false);
      if (isVerified) {
        closeVerifyDialog();
        enqueueSnackbar(`Order ${order.orderId} Verified`, { variant: 'success' });
      }
    });
  };

  const { setLabelItemIds } = usePrintLabelContext();
  const enablePrintLabelButton = () => {
    setLabelItemIds([...orderLineItemIds]);
  };

  const displayEditOrderAddressButton =
    isRxEntryOrder &&
    hasAccess.updateOrderAddress &&
    order.status !== orderEnum.SHIPPED &&
    !order.isProcessedExternal;

  const shouldShowTransferOrderButton =
    showTransferOrderButton && hasAccess.transferOrder && !order.isProcessedExternal;

  const displayReverifyOrderBtn =
    isRxEntryOrder && !order.isProcessedExternal && order.status === orderEnum.ADDED_TO_SHIPMENT;

  const currentTenantId = useSelector(({ auth }) => auth.currentTenantId);

  const fulfillingPharmacies = useSelector(({ pharmacy }) => pharmacy[currentTenantId]);
  const fillingFacility = find(
    fulfillingPharmacies,
    ({ fulfillingPharmacyId }) => order.fulfillingPharmacyId === fulfillingPharmacyId
  );

  return (
    <>
      {openPrescriptionForm && (
        <PatientPrescriptionDialog
          contextId={selectedPrescriptionId}
          closeDialog={onClose}
          mpi={mpi}
          open={openPrescriptionForm}
        />
      )}

      <Dialog open={showVerifyDialog}>
        <DialogTitle>Verify Order</DialogTitle>
        <DialogContent sx={{ minWidth: 500 }}>
          <>
            <p>I acknowledge that order {order.orderId} has been verified.</p>
            {map(activeLineItems, (lineItem) => (
              <Grid
                container
                key={lineItem.lineItemId}
                alignItems='center'
                justifyContent='space-between'
                spacing={1}
                sx={{ mb: 1 }}
              >
                <Grid item>
                  <Typography fontWeight='600'>
                    {
                      find(patientPrescriptions, { prescriptionUUID: lineItem?.prescriptionId })
                        ?.drug
                    }
                  </Typography>
                </Grid>

                <Grid item>
                  <Button
                    size='small'
                    sx={{ mr: 1 }}
                    onClick={() => handlePrescriptionForm(lineItem?.prescriptionId)}
                    variant='outlined'
                  >
                    View
                  </Button>
                  {isRxEntryOrder && hasAccess.markOrderForExternalProcessing && (
                    <ReviewOrderVerificationButton mpi={mpi} orderId={order.orderId} />
                  )}
                </Grid>
              </Grid>
            ))}
            {isRxEntryOrder && <p>* Order placed from RX ENTRY</p>}
          </>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeVerifyDialog} color='secondary' size='medium' variant='outlined'>
            Cancel
          </Button>
          <LoadingButton variant='contained' onClick={handleVerifyOrder} loading={isLoading}>
            Confirm
          </LoadingButton>
        </DialogActions>
      </Dialog>

      <Grid container direction='column' sx={{ mb: 1 }}>
        <Accordion defaultExpanded disableGutters>
          <AccordionSummary
            sx={{ bgcolor: lighten(theme.palette.text.primary, 0.9) }}
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`${order.orderId}-panel-content`}
            id={`${order.orderId}-panel-header`}
          >
            <Grid container justifyContent='space-between' alignItems='center'>
              <Stack direction='row' alignItems='center' spacing={1}>
                <Typography
                  variant='subtitle2'
                  fontWeight='bold'
                >{`Order ${order.orderId} - ${order?.partner?.partnerName}`}</Typography>
                <Chip
                  sx={{
                    background: theme.palette.secondary.light,
                    color: theme.palette.primary.contrastText,
                    maxHeight: '80%',
                  }}
                  label={
                    <Typography variant='body2' fontWeight='bold'>
                      {orderStatus[order.status]}
                    </Typography>
                  }
                />

                <Chip
                  sx={{
                    background:
                      order.fillType === 'REFILL'
                        ? theme.palette.info.dark
                        : theme.palette.success.dark,
                    color: theme.palette.primary.contrastText,
                    borderRadius: '5px',
                    height: '25px',
                    width: order.fillType === 'REFILL' ? '55px' : '65px',
                  }}
                  label={
                    <Typography sx={{ fontSize: '1.1em' }}>
                      {orderFillType[order.fillType]}
                    </Typography>
                  }
                />
              </Stack>

              <Stack direction='row' alignItems='center' spacing={4}>
                {fillingFacility && (
                  <Typography variant='subtitle2'>
                    Filling At: <strong>{fillingFacility.name}</strong>
                  </Typography>
                )}

                {displayOrderCost && order.orderCost && (
                  <Typography variant='subtitle2' sx={{ mr: 2, fontWeight: 600 }}>
                    Order Total: ${order.orderCost}
                  </Typography>
                )}
              </Stack>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <Grid sx={{ my: 1 }} container direction='column'>
              <Grid item>
                <TableContainer>
                  <Grid container justifyContent='flex-end' sx={{ pb: 2 }} columnGap={1}>
                    {order.status !== orderEnum.SHIPPED && (
                      <CancelOrderButton
                        disabled={isLineItemsInReprocessingState}
                        orderId={order.orderId}
                        mpi={mpi}
                      />
                    )}

                    {displayReverifyOrderBtn && (
                      <Tooltip
                        title={
                          isLineItemsInBulkShipmentQueue
                            ? 'Order line items in batch shipment cannot be reverified'
                            : ''
                        }
                        placement='top'
                        arrow
                      >
                        <span>
                          <ReverifyOrderButton
                            mpi={mpi}
                            orderId={order.orderId}
                            disabled={isLineItemsInBulkShipmentQueue}
                          />
                        </span>
                      </Tooltip>
                    )}

                    {displayEditOrderAddressButton && (
                      <EditOrderAddressButton
                        mpi={mpi}
                        orderId={order.orderId}
                        orderAddress={order.shippingAddress}
                        afterAddressUpdate={enablePrintLabelButton}
                        disabled={isLineItemsInReprocessingState}
                      />
                    )}

                    {showNeedsReviewButton && (
                      <ReviewPrescriptionButton
                        disabled={isLineItemsInReprocessingState}
                        patientId={mpi}
                        orderId={order.orderId}
                        lineItems={order.lineItems}
                        patientPrescriptions={patientPrescriptions}
                      />
                    )}

                    {showNeedsReviewButtonOnProductFulfillment && (
                      <ReviewOrderVerificationButton
                        mpi={mpi}
                        orderId={order.orderId}
                        disabled={isLineItemsInReprocessingState}
                      />
                    )}

                    {showVerifyOrderBtn && (
                      <Tooltip title={toolTipMsg} placement='top' arrow>
                        <span>
                          <LoadingButton
                            variant='contained'
                            onClick={openVerifyDialog}
                            disabled={disableVerifyOrderButton}
                          >
                            Verify Order
                          </LoadingButton>
                        </span>
                      </Tooltip>
                    )}

                    {displaySendToShipmentButton && hasAccess.createOrderShipment && (
                      <SendToShippingButton
                        mpi={mpi}
                        orderId={order.orderId}
                        orderStatus={order.status}
                      />
                    )}

                    {shouldShowTransferOrderButton && (
                      <TransferOrderButton
                        mpi={mpi}
                        orderId={order.orderId}
                        orderFacilityId={order.fulfillingPharmacyId}
                      />
                    )}
                  </Grid>
                  <Table size='small'>
                    <TableHead>
                      <TableRow hover>
                        {headCells.map((headCell) => (
                          <TableCell key={headCell.id} align={headCell.align}>
                            {headCell.label}
                          </TableCell>
                        ))}

                        <TableCell align='center' sx={{ py: 0 }}>
                          {hasAccess.generateBulkLabels &&
                            !order.isProcessedExternal &&
                            !isLineItemsInReprocessingState && (
                              <SelectAllCheckBox allLineItemIds={orderLineItemIds} />
                            )}
                        </TableCell>
                        <TableCell />
                        <TableCell />
                        <TableCell />
                        <TableCell />
                        <TableCell />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {map(
                        orderBy(order.lineItems, [SortKey.RX_NUMBER], [SortOrderEnum.ASC]),
                        (item) => (
                          <TableRow key={item.lineItemId} hover>
                            <TableCell sx={{ paddingY: '12px' }}>
                              {format(new Date(item.needsByDate), 'MM/dd/yyyy')}
                            </TableCell>
                            <TableCell sx={{ paddingY: '12px' }}>{item.itemName}</TableCell>
                            <TableCell>
                              {format(new Date(item.requestedDate), 'MM/dd/yyyy')}
                            </TableCell>
                            <TableCell sx={{ paddingY: '12px' }}>{item.itemType}</TableCell>
                            <TableCell>
                              <Grid container alignItems='center'>
                                <Grid item> {item.rxNumber}</Grid>
                                {item.showOldRxNumber && (
                                  <Grid item>
                                    <Tooltip
                                      title={`Rx Number updated. Previous Rx Number: ${item.oldRxNumber}`}
                                      placement='right'
                                    >
                                      <ErrorOutlineIcon color='error' />
                                    </Tooltip>
                                  </Grid>
                                )}
                              </Grid>
                            </TableCell>
                            <TableCell align='center'>{item.strength}</TableCell>
                            <TableCell>{item.form}</TableCell>
                            <TableCell align='center'>{item.quantity}</TableCell>
                            <TableCell align='center'>
                              {getPartnerAccountName(order.partnerAccountId)}
                            </TableCell>
                            <TableCell align='center'>
                              <Tooltip title={item?.documentId ? '' : 'No Docutrack Number'}>
                                <Typography variant='body2'>{item?.documentId || 'N/A'}</Typography>
                              </Tooltip>
                            </TableCell>
                            <TableCell align='center'>
                              {hasAccess.generateBulkLabels &&
                                !order.isProcessedExternal &&
                                !isLineItemsInReprocessingState && (
                                  <PrintLabelCheckBox lineItemId={item.lineItemId} />
                                )}
                            </TableCell>
                            <TableCell align='right' sx={{ p: 0 }}>
                              {item.hasPinnedNote && (
                                <IconButton
                                  onClick={() => setActiveNoteItem(item.lineItemId)}
                                  aria-label={`View pinned notes ${item.lineItemId}`}
                                >
                                  <StickyNote2Icon color='primary' />
                                </IconButton>
                              )}
                            </TableCell>
                            <TableCell align='right'>
                              <Chip
                                sx={{
                                  backgroundColor:
                                    {
                                      CANCELED: theme.palette.error.light,
                                      SHIPPED: theme.palette.success.light,
                                    }[item.processingState] || theme.palette.warning.light,
                                  color: theme.palette.primary.contrastText,
                                  borderRadius: 1,
                                  height: 25,
                                }}
                                label={
                                  <Typography sx={{ fontSize: '0.8em' }}>
                                    {[
                                      'CANCELED',
                                      'SHIPPED',
                                      'IN_SHIPMENT',
                                      'READY_FOR_SHIPMENT',
                                    ].includes(item.processingState)
                                      ? item.processingState.replace(/_/g, ' ')
                                      : 'IN PROGRESS'}
                                  </Typography>
                                }
                              />
                            </TableCell>

                            <TableCell align='right'>
                              <PatientFillProductButton
                                item={item}
                                key={item.lineItemId}
                                mpi={mpi}
                                orderId={order.orderId}
                                isProcessedExternal={order.isProcessedExternal}
                              />
                            </TableCell>

                            <TableCell>
                              {item.inBatchShipmentQueue &&
                                !includes(['SHIPPED'], item.processingState) &&
                                !order.isProcessedExternal && (
                                  <Tooltip title='In Batch Shipment' arrow placement='top'>
                                    <ShippingIcon color='primary' />
                                  </Tooltip>
                                )}
                            </TableCell>

                            <TableCell align='center' sx={{ maxWidth: 100 }}>
                              <PatientNoteOptions
                                itemId={item.lineItemId}
                                mpi={mpi}
                                rxNumber={item.rxNumber}
                                itemName={item.itemName}
                                orderId={order.orderId}
                                itemStatus={item.lineItemStatus}
                                processingState={item.processingState}
                                activeNoteItem={activeNoteItem}
                                setActiveNoteItem={setActiveNoteItem}
                                addressDirectoryId={item.addressDirectoryId}
                                inBatchShipmentQueue={item.inBatchShipmentQueue}
                              />
                            </TableCell>
                          </TableRow>
                        )
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </>
  );
};

PatientOrdersAccordion.propTypes = {
  order: PropTypes.shape({
    orderId: PropTypes.string.isRequired,
    status: PropTypes.string,
    orderType: PropTypes.string,
    orderCost: PropTypes.string,
    isProcessedExternal: PropTypes.bool.isRequired,
    fulfillingPharmacyId: PropTypes.string.isRequired,
    partner: PropTypes.shape({ partnerName: PropTypes.string.isRequired }),
    fillType: PropTypes.string,
    shippingAddress: PropTypes.shape({
      line1: PropTypes.string.isRequired,
      line2: PropTypes.string,
      city: PropTypes.string.isRequired,
      state: PropTypes.string.isRequired,
      zip: PropTypes.string.isRequired,
    }).isRequired,
    lineItems: PropTypes.arrayOf(
      PropTypes.shape({
        itemName: PropTypes.string,
        needsByDate: PropTypes.string,
        itemType: PropTypes.string,
        lineItemStatus: PropTypes.string.isRequired,
        cost: PropTypes.number,
        lineItemId: PropTypes.string.isRequired,
        prescriptionId: PropTypes.string.isRequired,
      })
    ),
    partnerAccountId: PropTypes.string,
  }).isRequired,
  mpi: PropTypes.string.isRequired,
};

export default PatientOrdersAccordion;
