/* eslint-disable max-lines */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  ListItemText,
  MenuItem,
  Radio,
  Stack,
  Typography,
} from '@mui/material';
import { Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import LoadingButton from '@mui/lab/LoadingButton';
import PropTypes from 'prop-types';
import { find, isArray, isEmpty, map } from 'lodash';

import TextField from 'common/forms/TextField';
import SelectField from 'common/forms/SelectField';
import {
  validatePhoneNumber,
  validateValueWithRegex,
  validateZipCode,
} from 'common/forms/formValidations';
import usStateAbbreviation from 'common/constants/usStateAbbreviation';
import { updatePatientPharmacy, validateAddress } from 'store/thunks/patientThunks';
import { formatZipCode } from 'common/utils';
import RadioGroupField from 'common/forms/RadioGroupField';
import { useState } from 'react';

const EditPharmacyDialog = ({ mpi, patientPharmacyId, open, onClose }) => {
  const dispatch = useDispatch();
  const initialValues = useSelector(({ patient }) =>
    find(patient[mpi].pharmacies, { patientPharmacyId })
  );
  const [pharmacyDataToSave, setPharmacyDataToSave] = useState({});
  const [validatedAddresses, setValidatedAddresses] = useState([]);
  const [isMultipleAddresses, setIsMultipleAddresses] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleCloseModal = (_, reason) => {
    /* istanbul ignore next */
    if (reason !== 'backdropClick') {
      onClose();
      setPharmacyDataToSave({});
      setLoading(false);
    }
  };

  const closeConfirmAddressDialog = () => setIsMultipleAddresses(false);

  const handleFinalConfirmAddress = (selectedAddress) => {
    const address = validatedAddresses[selectedAddress.addressChoice];
    const formattedProviderData = {
      ...pharmacyDataToSave,
      addressLine1: address.line1,
      addressLine2: address.line2,
      city: address.city,
      state: address.state,
      zip: address.zip,
    };

    setPharmacyDataToSave(formattedProviderData);
    setIsMultipleAddresses(false);

    setLoading(true);
    dispatch(
      updatePatientPharmacy({
        mpi,
        pharmacy: {
          ...formattedProviderData,
          name: formattedProviderData.name || '',
          phoneNumber: formattedProviderData.phoneNumber || '',
          fax: formattedProviderData.fax || '',
          npi: formattedProviderData.npi || '',
          addressLine1: formattedProviderData.addressLine1 || '',
          addressLine2: formattedProviderData.addressLine2 || '',
          city: formattedProviderData.city || '',
          state: formattedProviderData.state || '',
          zip: formattedProviderData.zip || '',
        },
      })
    ).then((success) => {
      if (success) {
        handleCloseModal();
      }

      setLoading(false);
      setPharmacyDataToSave({});
    });
  };

  const handleUpdatePatientPharmacy = (formData) => {
    setLoading(true);
    const { addressLine1, addressLine2, city, state, zip } = formData;

    const address = { line1: addressLine1, line2: addressLine2, city, state, zip };
    setPharmacyDataToSave(formData);

    return dispatch(validateAddress({ input: address }))
      .then((result) => {
        if (isArray(result)) {
          setValidatedAddresses(result);
          setLoading(false);
          setIsMultipleAddresses(true);
        }
      })
      .catch(() => setLoading(false));
  };

  return (
    <>
      {isMultipleAddresses && !isEmpty(validatedAddresses) && (
        <Dialog
          open={isMultipleAddresses}
          maxWidth='sm'
          fullWidth
          aria-labelledby='AddPatient-ConfirmAddress-header'
        >
          <Typography variant='h6' sx={{ px: 3, pt: 2 }}>
            Confirm Address
          </Typography>

          <Form
            onSubmit={handleFinalConfirmAddress}
            render={({ values, handleSubmit }) => (
              <form noValidate onSubmit={handleSubmit}>
                <DialogContent>
                  <Typography sx={{ pb: 2 }}>
                    {validatedAddresses.length > 1
                      ? 'Multiple addresses found. Please choose one from the suggestions below.'
                      : 'Please confirm the address below.'}
                  </Typography>
                  <RadioGroupField
                    name='addressChoice'
                    id='AddPatient-AddressChoice-radioField'
                    label=''
                  >
                    <Stack direction='column' alignItems='flex-start' gap={2}>
                      {map(validatedAddresses, (address, index) => (
                        <FormControlLabel
                          key={`validateAddress-${index}`}
                          value={index}
                          control={<Radio sx={{ mr: 1 }} />}
                          sx={{ px: 1 }}
                          label={
                            <ListItemText
                              secondary={
                                <>
                                  <Typography>{address.line1}</Typography>
                                  {address?.line2 && <Typography>{address.line2}</Typography>}
                                  <Typography>
                                    {address.city} , {address.state}, {address.zip}
                                  </Typography>
                                </>
                              }
                            />
                          }
                        />
                      ))}
                    </Stack>
                  </RadioGroupField>
                </DialogContent>
                <DialogActions>
                  <Button variant='outlined' color='secondary' onClick={closeConfirmAddressDialog}>
                    Cancel
                  </Button>
                  <Button
                    variant='contained'
                    disabled={isEmpty(values?.addressChoice)}
                    onClick={handleSubmit}
                    type='submit'
                  >
                    Confirm
                  </Button>
                </DialogActions>
              </form>
            )}
          />
        </Dialog>
      )}

      <Dialog
        open={open}
        onClose={handleCloseModal}
        aria-describedby='PatientView-EditPharmacyDialog-header'
        fullWidth
      >
        <DialogTitle id='PatientView-EditPharmacyDialog-header'>Edit Pharmacy</DialogTitle>

        <Form
          initialValues={{ ...initialValues, ...pharmacyDataToSave }}
          onSubmit={handleUpdatePatientPharmacy}
          render={({ handleSubmit, invalid }) => (
            <form noValidate onSubmit={handleSubmit}>
              <DialogContent>
                <TextField
                  id='PatientView-EditPharmacyDialog-name-input'
                  label='Pharmacy Name'
                  name='name'
                  required
                  autoFocus
                />

                <TextField
                  id='PatientView-EditPharmacyDialog-phoneNumber-input'
                  label='Phone Number'
                  name='phoneNumber'
                  required
                  validations={[validatePhoneNumber()]}
                />

                <TextField
                  id='PatientView-EditPharmacyDialog-fax-input'
                  label='Fax'
                  name='fax'
                  validations={[validatePhoneNumber('Invalid Fax Number')]}
                />

                <TextField
                  id='PatientView-EditPharmacyDialog-npi-input'
                  label='NPI'
                  name='npi'
                  validations={[validateValueWithRegex('Must be 10 numbers', /^\d{10}$/)]}
                />

                <TextField
                  id='PatientView-EditPharmacyDialog-addressLine1-input'
                  label='Address Line 1'
                  name='addressLine1'
                  required
                />

                <TextField
                  id='PatientView-EditPharmacyDialog-addressLine2-input'
                  label='Address Line 2'
                  name='addressLine2'
                />

                <TextField
                  id='PatientView-EditPharmacyDialog-city-input'
                  label='City'
                  name='city'
                  required
                />

                <SelectField
                  id='PatientView-EditPharmacyDialog-state-dropdown'
                  label='State'
                  name='state'
                  required
                >
                  {map(usStateAbbreviation, ({ name, abbreviation }) => (
                    <MenuItem value={abbreviation} key={abbreviation}>
                      {name}
                    </MenuItem>
                  ))}
                </SelectField>

                <TextField
                  id='PatientView-EditPharmacyDialog-zip-input'
                  label='Zip'
                  name='zip'
                  required
                  maxLength={10}
                  format={formatZipCode}
                  validations={[validateZipCode()]}
                />
              </DialogContent>

              <DialogActions>
                <Button variant='outlined' color='secondary' onClick={handleCloseModal}>
                  Cancel
                </Button>
                <LoadingButton
                  loading={loading}
                  variant='contained'
                  disabled={invalid}
                  type='submit'
                >
                  Save
                </LoadingButton>
              </DialogActions>
            </form>
          )}
        />
      </Dialog>
    </>
  );
};

EditPharmacyDialog.propTypes = {
  mpi: PropTypes.string.isRequired,
  patientPharmacyId: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default EditPharmacyDialog;
