import { useDispatch, useSelector } from 'react-redux';
import { Button, CircularProgress, Grid, MenuItem, Popover, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { Form } from 'react-final-form';

import { OrderFillType, SortKey } from 'enums/common.enum';
import DateField from 'common/forms/DateField';
import TextField from 'common/forms/TextField';
import SelectField from 'common/forms/SelectField';
import { validateMinLength } from 'common/forms/formValidations';
import { filterPrescriptionsNeedsReview } from 'store/thunks/workQueueThunks';
import { find, isEmpty, map } from 'lodash';
import { getPartnerPractices } from 'store/thunks/partnerThunks';
import FacilityFilterField from './FacilityFilterField';

const PrescriptionReviewFilterButton = () => {
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedPartnerId, setSelectedPartnerId] = useState(null);
  const [practices, setPractices] = useState(null);
  const [practiceLoading, setPracticeLoading] = useState(false);

  const filter = useSelector(({ workQueue: { prescriptionReview } }) => ({
    ...prescriptionReview?.filter,
    requestedDate:
      prescriptionReview?.filter?.requestedDate &&
      new Date(prescriptionReview.filter.requestedDate),
    reviewStatus: prescriptionReview?.filter?.reviewStatus
      ? prescriptionReview?.filter?.reviewStatus
      : 'ALL',
  }));

  const handleOpen = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const popoverId = anchorEl ? 'WorkQueue-PrescriptionReviewFilterButton-Popover' : undefined;
  const partners = useSelector(({ auth, partner }) => partner[auth.currentTenantId]);

  const partner = useMemo(
    () => (selectedPartnerId ? find(partners, { partnerUuid: selectedPartnerId }) : null),
    [selectedPartnerId, partners]
  );

  useEffect(() => {
    if (!selectedPartnerId && filter?.partnerId) {
      setSelectedPartnerId(filter.partnerId);
    }
  }, [selectedPartnerId, filter?.partnerId]);

  useEffect(() => {
    if (!selectedPartnerId) return;

    if (partner?.partnerPractices) {
      setPractices(partner.partnerPractices);
    } else {
      setPracticeLoading(true);
      dispatch(getPartnerPractices({ partnerId: selectedPartnerId })).finally(() =>
        setPracticeLoading(false)
      );
    }
  }, [selectedPartnerId, partner, dispatch]);

  const handleApplyFilter = (formData) => {
    setAnchorEl(null);

    dispatch(
      filterPrescriptionsNeedsReview({
        filter: {
          ...formData,
          requestedDate: formData.requestedDate && formData.requestedDate.toISOString(),
          reviewStatus: formData.reviewStatus === 'ALL' ? undefined : formData.reviewStatus,
          partnerId: formData.partnerId,
          practiceId: formData.practiceId,
        },
      })
    );
  };

  return (
    <>
      <Button
        sx={{ height: 33 }}
        variant='outlined'
        color='secondary'
        onClick={handleOpen}
        aria-describedby={popoverId}
      >
        Filter
      </Button>

      <Popover
        id={popoverId}
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Grid container sx={{ p: 2, maxWidth: 300 }} spacing={2} direction='column'>
          <Grid item>
            <Typography component='h2'>Filter</Typography>
          </Grid>

          <Grid item>
            <Form
              initialValues={filter}
              onSubmit={handleApplyFilter}
              render={({ handleSubmit, invalid, form, values }) => (
                <form onSubmit={handleSubmit} noValidate>
                  <FacilityFilterField />
                  <TextField
                    label='Drug Name'
                    name={SortKey.DRUG_NAME}
                    id='WorkQueue-PrescriptionReviewFilterButton-drugName-input'
                    validations={[validateMinLength('Minimum of 3 characters', 3)]}
                  />

                  <DateField
                    label='Requested Date'
                    name={SortKey.REQUESTED_DATE}
                    id='WorkQueue-PrescriptionReviewFilterButton-requestedDate-dateField'
                    maxDate={new Date()}
                  />

                  <TextField
                    label='Order Id'
                    name={SortKey.ORDER_ID}
                    id='WorkQueue-PrescriptionReviewFilterButton-orderId-input'
                    validations={[validateMinLength('Minimum of 3 characters', 3)]}
                  />

                  <SelectField
                    label='Fill Type'
                    name={SortKey.FILL_TYPE}
                    id='WorkQueue-PrescriptionReviewFilterButton-fillType-dropdown'
                  >
                    <MenuItem value=''>All</MenuItem>
                    <MenuItem value={OrderFillType.FIRST_FILL}>First Fill</MenuItem>
                    <MenuItem value={OrderFillType.REFILL}>reFill</MenuItem>
                  </SelectField>

                  <SelectField
                    label='Status'
                    name='reviewStatus'
                    id='WorkQueue-PrescriptionReviewFilterButton-reviewStatus-dropdown'
                    defaultValue='PRESCRIPTION_DIRECTION_REVIEW'
                  >
                    <MenuItem value='ALL'>All</MenuItem>
                    <MenuItem value='PRESCRIPTION_DIRECTION_REVIEW'>Direction Review</MenuItem>
                    <MenuItem value='PRESCRIPTION_DUPLICATE_REVIEW'>Duplicate Rx Review</MenuItem>
                    <MenuItem value='DRUG_PROFILE_REVIEW'>Drug Profile Review</MenuItem>
                    <MenuItem value='PRESCRIPTION_REVIEW'>Prescription review</MenuItem>
                  </SelectField>

                  <SelectField
                    label='Partner'
                    name='partnerId'
                    id='WorkQueue-BulkLabelsFilterButton-partnerId-dropdown'
                    onChange={(event) => {
                      form.change('practiceId', undefined);

                      if (!isEmpty(event.target.value)) {
                        setSelectedPartnerId(event.target.value);
                      }
                    }}
                  >
                    <MenuItem value=''>All</MenuItem>
                    {map(partners, ({ partnerName, partnerUuid }) => (
                      <MenuItem value={partnerUuid} key={partnerUuid}>
                        {partnerName}
                      </MenuItem>
                    ))}
                  </SelectField>

                  <Grid container justifyContent='space-between' alignItems='center'>
                    <Grid item sx={{ width: practiceLoading ? '85%' : '100%' }}>
                      <SelectField
                        label='Practice'
                        name='practiceId'
                        id='WorkQueue-BulkLabelsFilterButton-practiceId-dropdown'
                        disabled={practiceLoading || !values.partnerId || !practices?.length}
                      >
                        <MenuItem value=''>All</MenuItem>
                        {map(practices, (practice) => (
                          <MenuItem value={practice?.practiceId} key={practice?.practiceId}>
                            {practice?.practiceName}
                          </MenuItem>
                        ))}
                      </SelectField>
                    </Grid>
                    {practiceLoading && (
                      <Grid item>
                        <CircularProgress size={20} />
                      </Grid>
                    )}
                  </Grid>

                  <Grid container justifyContent='flex-end'>
                    <Button variant='contained' type='submit' disabled={invalid}>
                      Apply
                    </Button>
                  </Grid>
                </form>
              )}
            />
          </Grid>
        </Grid>
      </Popover>
    </>
  );
};

export default PrescriptionReviewFilterButton;
