/* eslint-disable max-lines */
import { format, parseISO } from 'date-fns';
import { find, includes, isDate, pick, toNumber } from 'lodash';
import PropTypes from 'prop-types';
import React, { forwardRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Slide,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import CloseIcon from '@mui/icons-material/Close';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { lighten, useTheme } from '@mui/material/styles';

import { closeEScriptRequests, importEScriptRequests } from 'store/thunks/workQueueThunks';

import RxInTakeRequestView from './RxInTakeRequestView';
import RxInTakeRequestEditModal from './RxInTakeRequestEditModal';
import RxIntakePayloadViewDialog from './RxIntakePayloadViewDialog';

const Transition = forwardRef((props, ref) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Slide direction='up' ref={ref} {...props} timeout={300} />
));

const RxInTakeRequestsOptions = ({ scriptId, scriptStatus }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showRawJsonModal, setRawJsonModal] = useState(false);
  const [showRequestView, setShowRequestView] = useState(false);
  const [showImportRequestModal, setShowImportRequestModal] = useState(false);
  const [showCloseRequestModal, setShowCloseRequestModal] = useState(false);
  const [showEditRequestModal, setShowEditRequestModal] = useState(false);

  const { pages, currentPage } = useSelector(({ workQueue }) => workQueue.rxIntakeRequests);
  const scriptDetails = find(pages[currentPage], { scriptId });
  const { enrichedPrescriptionPayload, originalPrescriptionPayload, originalPayload } =
    scriptDetails.requestPayload;
  const enableCancelOption = includes(['OPEN', 'HOLD'], scriptStatus);
  const enableImportOption = includes(['OPEN', 'IMPORT_FAILED'], scriptStatus);
  const enableEditOption = includes(['OPEN', 'HOLD', 'CLOSED'], scriptStatus);

  const menuOpen = !!anchorEl;
  const handleMenuOpen = (event) => setAnchorEl(event.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);
  const handleJsonView = () => setRawJsonModal((prev) => !prev);

  const openRequestDetailsView = () => setShowRequestView(true);
  const openCloseRequestModal = () => setShowCloseRequestModal(true);
  const openImportRequestModal = () => setShowImportRequestModal(true);
  const openEditRequestModal = () => setShowEditRequestModal(true);

  const normalizeDate = (dateInput) => {
    if (isDate(dateInput)) {
      return format(dateInput, 'yyyy-MM-dd');
    }

    const parsedDate = parseISO(dateInput);
    return isDate(parsedDate) ? format(parsedDate, 'yyyy-MM-dd') : null;
  };

  const closeViewRxIntakeRequestModal = (_, reason) => {
    /* istanbul ignore next */
    if (reason !== 'backdropClick') {
      setShowRequestView(false);
    }
  };

  const closeEditRxIntakeRequestModal = (_, reason) => {
    /* istanbul ignore next */
    if (reason !== 'backdropClick') {
      setShowEditRequestModal(false);
    }
  };

  const closeCancelRxIntakeRequestModal = (_, reason) => {
    /* istanbul ignore next */
    if (reason !== 'backdropClick') {
      setShowCloseRequestModal(false);
    }
  };

  const closeImportRxIntakeRequestModal = (_, reason) => {
    /* istanbul ignore next */
    if (reason !== 'backdropClick') {
      setShowImportRequestModal(false);
    }
  };

  const handleImportEnrichedRequest = (data) => {
    setLoading(true);
    const { partnerUuid, practiceId, partnerAccountId, patient, prescription } = data;
    const { prescribedBy, transferDetails } = prescription;

    const input = {
      practiceId,
      eScriptId: scriptId,
      partnerId: partnerUuid,
      patientInput: {
        ...pick(patient, ['firstName', 'lastName', 'gender', 'phoneNumber']),
        email: patient.emailAddress,
        address: patient.patientAddress,
        dob: patient?.dob && normalizeDate(patient.dob),
      },
      shippingInput: patient.patientAddress,
      prescriptionInput: {
        ...pick(prescription, ['directions', 'notes']),
        strength: prescription.strength,
        quantity: toNumber(prescription.quantity),
        drugName: prescription.description,
        refills: toNumber(prescription.totalRefills),
        dispenseAsWritten: !!prescription?.dispenseAsWritten,
        dateWritten:
          transferDetails?.writtenDate &&
          format(new Date(transferDetails.writtenDate), "yyyy-MM-dd'T'HH:mm:ss'Z'"),
        ...(prescription?.needsByDate && {
          needsByDate: normalizeDate(data.prescription.needsByDate),
        }),
        ...(prescription?.expirationDate && {
          expirationDate: normalizeDate(data.prescription.expirationDate),
        }),
      },
      providerInput: prescribedBy,
      ...(partnerAccountId && { partnerAccountId }),
    };

    dispatch(importEScriptRequests([input]))
      .then((result) => {
        if (result) {
          closeViewRxIntakeRequestModal();
          closeImportRxIntakeRequestModal();
          enqueueSnackbar('Order Successfully Imported', { variant: 'success' });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const closeEnrichedRequests = () => {
    setLoading(true);

    dispatch(closeEScriptRequests([scriptId]))
      .then((result) => {
        if (result) {
          closeViewRxIntakeRequestModal();
          closeCancelRxIntakeRequestModal();
          enqueueSnackbar('Order Successfully Closed', { variant: 'success' });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      {/* View Enriched Request */}
      <Dialog fullScreen open={showRequestView} TransitionComponent={Transition}>
        <Grid container sx={{ px: 4, pt: 1 }} justifyContent='flex-end'>
          <IconButton
            onClick={closeViewRxIntakeRequestModal}
            id={`Close RxInTakeRequestView - ${scriptId}`}
            aria-label='Close RxInTakeRequestView Button'
          >
            <CloseIcon fontSize='medium' />
          </IconButton>
        </Grid>

        <Grid container sx={{ px: 5 }}>
          <Grid container sx={{ p: 0.5 }} justifyContent='space-between' alignItems='center'>
            <Grid item>
              <Typography
                variant='h2'
                sx={{ color: lighten(theme.palette.text.primary, 0.3), ml: 3 }}
              >
                Pharmacy Order
              </Typography>
            </Grid>

            <Grid item>
              <Grid container justifyContent='flex-end' alignItems='center' columnGap={1}>
                {enableCancelOption && (
                  <Button variant='outlined' color='error' onClick={openCloseRequestModal}>
                    Close
                  </Button>
                )}

                <Button variant='outlined' color='secondary' onClick={handleJsonView}>
                  View Original Data
                </Button>

                {enableEditOption && (
                  <Button variant='outlined' color='secondary' onClick={openEditRequestModal}>
                    Edit Details
                  </Button>
                )}

                {enableImportOption && (
                  <Button variant='contained' color='primary' onClick={openImportRequestModal}>
                    Import
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Divider sx={{ mx: 6 }} />
        <DialogContent sx={{ p: 0 }}>
          <RxInTakeRequestView scriptId={scriptId} />
        </DialogContent>
      </Dialog>

      {/* Close Request */}
      <Dialog maxWidth='sm' open={showCloseRequestModal} TransitionComponent={Transition}>
        <DialogTitle variant='h5'>Close Order</DialogTitle>
        <DialogContent sx={{ minWidth: 450 }}>
          <Typography variant='subtitle1' sx={{ mb: 2 }}>
            Please confirm that the order should not be imported.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' color='secondary' onClick={closeCancelRxIntakeRequestModal}>
            Cancel
          </Button>
          <LoadingButton loading={loading} variant='contained' onClick={closeEnrichedRequests}>
            Confirm
          </LoadingButton>
        </DialogActions>
      </Dialog>

      {/* Import Request */}
      <Dialog maxWidth='sm' open={showImportRequestModal} TransitionComponent={Transition}>
        <DialogTitle variant='h5'>Import Order</DialogTitle>
        <DialogContent sx={{ minWidth: 450 }}>
          <Typography variant='subtitle1' sx={{ mb: 2 }}>
            Are you sure want to import the order ?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' color='secondary' onClick={closeImportRxIntakeRequestModal}>
            Cancel
          </Button>
          <LoadingButton
            loading={loading}
            variant='contained'
            onClick={() => handleImportEnrichedRequest(enrichedPrescriptionPayload)}
          >
            Confirm
          </LoadingButton>
        </DialogActions>
      </Dialog>

      {/* View Original Request Payload */}
      <RxIntakePayloadViewDialog
        open={showRawJsonModal}
        jsonData={originalPayload}
        originalPrescriptionPayload={originalPrescriptionPayload}
        closeModel={handleJsonView}
      />

      {/* Edit Request */}
      {showEditRequestModal && (
        <RxInTakeRequestEditModal
          scriptId={scriptId}
          loading={loading}
          showModal={showEditRequestModal}
          onClose={closeEditRxIntakeRequestModal}
          handleImportEnrichedRequest={handleImportEnrichedRequest}
        />
      )}

      <IconButton
        id={`WorkQueue-RxInTakeRequests-${scriptId}options-button`}
        aria-label={`RxInTakeRequests options ${scriptId}`}
        aria-controls={menuOpen ? `WorkQueue-RxInTakeRequests-${scriptId}options-menu` : undefined}
        aria-haspopup='true'
        aria-expanded={menuOpen ? 'true' : undefined}
        onClick={handleMenuOpen}
      >
        <MoreVertIcon />
      </IconButton>

      <Menu
        id={`WorkQueue-RxInTakeRequests-${scriptId}options-menu`}
        aria-labelledby={`WorkQueue-RxInTakeRequests-${scriptId}options-button`}
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem onClick={openRequestDetailsView}>View</MenuItem>
        {enableCancelOption && <MenuItem onClick={openCloseRequestModal}>Close</MenuItem>}
        {enableImportOption && <MenuItem onClick={openImportRequestModal}>Import</MenuItem>}
      </Menu>
    </>
  );
};

RxInTakeRequestsOptions.propTypes = {
  scriptId: PropTypes.string.isRequired,
  scriptStatus: PropTypes.string.isRequired,
};

export default RxInTakeRequestsOptions;
