import { Button, Chip, Stack } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { compact, find, isEmpty, isNil, values } from 'lodash';

import { updateBatchShipmentFilter } from 'store/thunks/batchShipmentThunk';
import usStateAbbreviation from 'common/constants/usStateAbbreviation';

const BatchShipmentFilterStatusbar = () => {
  const dispatch = useDispatch();
  const { filter } = useSelector(({ batchShipment }) => batchShipment);

  const removeFilter = (filterName) => () => {
    dispatch(
      updateBatchShipmentFilter({
        filter: { ...filter, [filterName]: undefined },
      })
    );
  };

  const removeAllFilter = () => dispatch(updateBatchShipmentFilter({ filter: {} }));

  return (
    <Stack direction='row' spacing={1}>
      {filter.orderId && (
        <Chip
          label={`Order Id = ${filter.orderId}`}
          variant='contained'
          onDelete={removeFilter('orderId')}
        />
      )}

      {filter.line1 && (
        <Chip
          label={`Address Line 1 = ${filter.line1}`}
          variant='contained'
          onDelete={removeFilter('line1')}
        />
      )}

      {filter.line2 && (
        <Chip
          label={`Address Line 2 = ${filter.line2}`}
          variant='contained'
          onDelete={removeFilter('line2')}
        />
      )}

      {filter.city && (
        <Chip label={`City = ${filter.city}`} variant='contained' onDelete={removeFilter('city')} />
      )}

      {filter.state && (
        <Chip
          label={`State = ${find(usStateAbbreviation, { abbreviation: filter.state })?.name}`}
          variant='contained'
          onDelete={removeFilter('state')}
        />
      )}

      {filter.zip && (
        <Chip label={`Zip = ${filter.zip}`} variant='contained' onDelete={removeFilter('zip')} />
      )}

      {(!isEmpty(compact(values(filter))) || !isNil(filter.isPrinted)) && (
        <Button onClick={removeAllFilter}>Remove All</Button>
      )}
    </Stack>
  );
};

export default BatchShipmentFilterStatusbar;
