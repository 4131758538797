import { debounce } from 'lodash';
import {
  createShipmentForLineItemsApi,
  listBatchShipmentsApi,
  listShipmentLineItemsApi,
  listShippingOptionsApi,
  updateAddressOrderLineItemStatusApi,
} from 'api/order';
import { batchShipmentListActions } from 'store/slices/batchShipmentSlice';
import { shippingOptionsActions } from 'store/slices/shippingOptionSlice';
import { handleError } from './errorHandlerThunks';
import { getPatientActionItems } from './patientThunks';

export const listBatchShipmentByPage =
  ({ page }) =>
  (dispatch, getState) => {
    const { sortOrder, filter } = getState().batchShipment;
    dispatch(batchShipmentListActions.updateCurrentPage({ page }));

    return listBatchShipmentsApi({ page, sortOrder, filter })
      .then(({ count, orders }) => {
        dispatch(batchShipmentListActions.loadBatchShipmentToPage({ count, orders, page }));
      })
      .catch((error) => dispatch(handleError({ error })));
  };

export const listShipmentLineItems =
  ({ addressDirectoryId, currentPage }) =>
  (dispatch) => {
    return listShipmentLineItemsApi({ addressDirectoryId })
      .then((response) =>
        dispatch(
          batchShipmentListActions.updateLineItemsToShipment({
            page: currentPage,
            shipmentLineItems: response.shipmentLineItems,
            count: response.count,
            addressDirectoryId,
          })
        )
      )
      .catch((error) => dispatch(handleError({ error })));
  };

export const updateAddressOrderLineItemStatus =
  ({ lineItemId, addressDirectoryId, status, mpi }) =>
  (dispatch) => {
    return updateAddressOrderLineItemStatusApi({ addressDirectoryId, lineItemId, status })
      .then((response) => {
        if (response) {
          dispatch(listBatchShipmentByPage({ page: 1 }));

          if (mpi && status === 'IN_BATCH_SHIPPING') {
            dispatch(getPatientActionItems({ mpi }));
          }
        }

        return response;
      })
      .catch((error) => dispatch(handleError({ error })));
  };

export const createShipmentForLineItems =
  ({ lineItemIds, addressDirectoryId, nameOnAddress, shippingOption }) =>
  (dispatch) => {
    return createShipmentForLineItemsApi({
      addressDirectoryId,
      lineItemIds,
      nameOnAddress,
      shippingOption,
    })
      .then((response) => {
        if (response) {
          dispatch(listBatchShipmentByPage({ page: 1 }));
        }

        return response;
      })
      .catch((error) => dispatch(handleError({ error })));
  };

export const debouncedBatchShipments = debounce(({ dispatch }) => {
  return dispatch(listBatchShipmentByPage({ page: 1 }));
}, 1000);

export const listShippingOptions = () => (dispatch) =>
  listShippingOptionsApi()
    .then((shippingOptions) => {
      return dispatch(shippingOptionsActions.updateShippingOptions({ shippingOptions }));
    })
    .catch((error) => dispatch(handleError({ error })));

export const updateBatchShipmentSortBy =
  ({ sortBy }) =>
  (dispatch) => {
    dispatch(batchShipmentListActions.updateBatchShipmentsSortBy({ sortOrder: sortBy }));
    debouncedBatchShipments({ dispatch });
  };

export const updateBatchShipmentFilter =
  ({ filter }) =>
  (dispatch) => {
    dispatch(batchShipmentListActions.updateBatchShipmentsFilter({ filter }));
    debouncedBatchShipments({ dispatch });
  };
