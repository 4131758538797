import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Grid, MenuItem, Popover, Typography } from '@mui/material';
import { useState } from 'react';
import { Form } from 'react-final-form';
import { map, startCase, toLower } from 'lodash';

import DateField from 'common/forms/DateField';
import TextField from 'common/forms/TextField';
import SelectField from 'common/forms/SelectField';
import orderStatus from 'common/constants/orderStatus';
import { ExternalOrdersFieldKeys } from 'enums/order.enum';
import { validateMinLength } from 'common/forms/formValidations';
import { updateProcessedExternalOrdersFilter } from 'store/thunks/workQueueThunks';

const ProcessedExternalOrdersFilterButton = () => {
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);

  const filter = useSelector(({ workQueue: { processedExternalOrders } }) => ({
    ...processedExternalOrders?.filter,
    status: processedExternalOrders?.filter?.status || 'ALL',
    createdAt:
      processedExternalOrders?.filter?.createdAt &&
      new Date(processedExternalOrders.filter.createdAt),
  }));

  const handleOpen = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const popoverId = anchorEl ? 'ProcessedExternalOrdersFilter-Popover' : undefined;

  const handleApplyFilter = (formData) => {
    setAnchorEl(null);

    dispatch(
      updateProcessedExternalOrdersFilter({
        filter: {
          ...formData,
          createdAt: formData.createdAt && format(new Date(formData.createdAt), 'yyyy-MM-dd'),
          status: formData.status === 'ALL' ? undefined : formData.status,
        },
      })
    );
  };

  return (
    <>
      <Button
        sx={{ height: 33 }}
        variant='outlined'
        color='secondary'
        onClick={handleOpen}
        aria-describedby={popoverId}
      >
        Filter
      </Button>

      <Popover
        id={popoverId}
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Grid container sx={{ p: 2, maxWidth: 300 }} spacing={2} direction='column'>
          <Grid item>
            <Typography component='h2'>Filter</Typography>
          </Grid>

          <Grid item>
            <Form
              initialValues={filter}
              onSubmit={handleApplyFilter}
              render={({ handleSubmit, invalid }) => (
                <form onSubmit={handleSubmit} noValidate>
                  <TextField
                    label='Order Id'
                    name={ExternalOrdersFieldKeys.ORDER_ID}
                    id='WorkQueue-ProcessedExternalOrdersFilter-orderId-input'
                    validations={[validateMinLength('Minimum of 3 characters', 3)]}
                  />

                  <TextField
                    label='Patient Name'
                    name={ExternalOrdersFieldKeys.PATIENT_NAME}
                    id='WorkQueue-ProcessedExternalOrdersFilter-patientName-input'
                    validations={[validateMinLength('Minimum of 3 characters', 3)]}
                  />

                  <TextField
                    label='Partner Name'
                    name={ExternalOrdersFieldKeys.PARTNER_NAME}
                    id='WorkQueue-ProcessedExternalOrdersFilter-partnerName-input'
                    validations={[validateMinLength('Minimum of 3 characters', 3)]}
                  />

                  <SelectField
                    label='Status'
                    name={ExternalOrdersFieldKeys.STATUS}
                    id='WorkQueue-ProcessedExternalOrdersFilter-status-dropdown'
                  >
                    <MenuItem value='ALL'>All</MenuItem>
                    {map(Object.keys(orderStatus), (status, index) => (
                      <MenuItem key={index} value={status}>
                        {startCase(toLower(status))}
                      </MenuItem>
                    ))}
                  </SelectField>

                  <DateField
                    label='Created At'
                    name={ExternalOrdersFieldKeys.CREATED_AT}
                    id='WorkQueue-ProcessedExternalOrdersFilter-createAt-dateField'
                    maxDate={new Date()}
                  />

                  <Grid container justifyContent='flex-end'>
                    <Button variant='contained' type='submit' disabled={invalid}>
                      Apply
                    </Button>
                  </Grid>
                </form>
              )}
            />
          </Grid>
        </Grid>
      </Popover>
    </>
  );
};

export default ProcessedExternalOrdersFilterButton;
