import {
  Box,
  Grid,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { isEmpty, isNil, map, toLower } from 'lodash';
import { visuallyHidden } from '@mui/utils';

import LoadingModule from 'common/components/LoadingModule';
import NothingFound from 'common/components/NothingFound';
import {
  listOrdersForProductFulfillmentByPage,
  sortListOrdersForProductFulfillmentWorkQueue,
} from 'store/thunks/workQueueThunks';
import { useGetPartnerAccountName } from 'common/utils';

import ProductFulfillmentRow from './ProductFulfillmentRow';

const headCells = [
  {
    id: 'orderId',
    label: 'Order Id',
    sortable: true,
  },
  {
    id: 'patientName',
    label: 'Patient Name',
    sortable: true,
  },
  {
    id: 'partnerName',
    label: 'Partner Name',
    sortable: false,
  },
  {
    id: 'partnerAccount',
    label: 'Partner Account',
    sortable: true,
  },
  {
    id: 'qs1Id',
    label: 'QS1 Id',
    sortable: false,
  },
  {
    id: 'needsByDate',
    label: 'Needs By Date',
    sortable: true,
  },
];

const ProductFulfillmentTable = () => {
  const dispatch = useDispatch();

  const { sortBy, pages, currentPage } = useSelector(
    ({ workQueue }) => workQueue.productFulfillment
  );
  const productFulfillment = pages[currentPage];

  const partnerAccountHandler = useGetPartnerAccountName();

  useEffect(() => {
    dispatch(listOrdersForProductFulfillmentByPage({ page: 1 }));
  }, [dispatch]);

  const totalPages = useSelector(({ workQueue }) =>
    Math.ceil(workQueue.productFulfillment.count / 25)
  );

  const handleSort = (event) => {
    const sortKey = event.currentTarget?.dataset?.headProperty;

    dispatch(
      sortListOrdersForProductFulfillmentWorkQueue({
        sortBy: {
          sortKey,
          sortOrder: sortBy.sortKey === sortKey && sortBy.sortOrder === 'ASC' ? 'DESC' : 'ASC',
        },
      })
    );
  };

  const handlePageChange = (_, page) => {
    if (currentPage === page) {
      // page didn't change, don't do anything.

      return;
    }

    dispatch(listOrdersForProductFulfillmentByPage({ page }));

    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <Grid container direction='column'>
      <Grid item>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell />
                {headCells.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    sortDirection={
                      sortBy.sortKey === headCell.id ? toLower(sortBy.sortOrder) : false
                    }
                  >
                    {!headCell.sortable && headCell.label}
                    {headCell.sortable && (
                      <TableSortLabel
                        active={sortBy.sortKey === headCell.id}
                        direction={
                          sortBy.sortKey === headCell.id ? toLower(sortBy.sortOrder) : 'asc'
                        }
                        onClick={handleSort}
                        data-head-property={headCell.id}
                      >
                        {headCell.label}
                        {sortBy.sortKey === headCell.id ? (
                          <Box component='span' sx={visuallyHidden}>
                            {toLower(sortBy.sortOrder) === 'desc'
                              ? 'sorted descending'
                              : 'sorted ascending'}
                          </Box>
                        ) : null}
                      </TableSortLabel>
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {map(productFulfillment, (order) => (
                <ProductFulfillmentRow
                  key={order.orderId}
                  order={order}
                  partnerAccountHandler={partnerAccountHandler}
                />
              ))}
            </TableBody>

            {!isNil(productFulfillment) && totalPages > 1 && (
              <TableFooter>
                <TableRow>
                  <TableCell colSpan={9} align='right' sx={{ borderBottom: 'none' }}>
                    <Pagination
                      sx={{ justifyContent: 'flex-end', alignItems: 'flex-end', display: 'flex' }}
                      count={totalPages}
                      page={currentPage}
                      size='small'
                      onChange={handlePageChange}
                    />
                  </TableCell>
                </TableRow>
              </TableFooter>
            )}
          </Table>
        </TableContainer>
      </Grid>

      {isNil(productFulfillment) && (
        <Grid item>
          <LoadingModule />
        </Grid>
      )}

      {!isNil(productFulfillment) && isEmpty(productFulfillment) && (
        <Grid item>
          <NothingFound />
        </Grid>
      )}
    </Grid>
  );
};

export default ProductFulfillmentTable;
