import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PropTypes from 'prop-types';
import useSmsResetWarningDialog from 'modules/PatientView/components/ShippingTab/useSmsResetWarningDialog';
import { LoadingButton } from '@mui/lab';
import { updateAddressOrderLineItemStatus } from 'store/thunks/batchShipmentThunk';

const ShipmentItemOption = ({ lineItemId, addressDirectoryId }) => {
  const { InfoDialog } = useSmsResetWarningDialog();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const menuOpen = !!anchorEl;

  const handleMenuOpen = (event) => setAnchorEl(event.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);

  const handleOpenModal = () => {
    setShowModal(true);
    handleMenuClose();
  };
  const handleCloseModal = (_, reason) => {
    /* istanbul ignore next */
    if (reason !== 'backdropClick') {
      setShowModal(false);
    }
  };

  const handleConfirm = () => {
    setLoading(true);
    dispatch(updateAddressOrderLineItemStatus({ addressDirectoryId, lineItemId, status: 'OPEN' }))
      .then((response) => {
        if (response) {
          enqueueSnackbar('Item unbatched from shipment', { variant: 'warning' });
          handleMenuClose();
          setShowModal(false);
          setLoading(false);
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <IconButton
        id={`BatchShipment-lineItem-${lineItemId}-options-button`}
        aria-label={`Shipment Line Item Option ${lineItemId}`}
        onClick={handleMenuOpen}
      >
        <MoreVertIcon />
      </IconButton>

      <Menu
        id={`BatchShipment-item-${lineItemId}-options-menu`}
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={handleMenuClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem onClick={handleOpenModal}>unbatch Item</MenuItem>
      </Menu>

      <InfoDialog />

      <Dialog
        open={showModal}
        aria-describedby='BatchShipment-RemoveItemFromSHipment-Header'
        fullWidth
      >
        <DialogTitle id='BatchShipment-RemoveItemFromSHipment-Header' sx={{ pb: 0 }}>
          Confirm Removal of Item
        </DialogTitle>

        <DialogContent>
          <Typography variant='subtitle1' sx={{ m: 2 }}>
            Please confirm the removal of this item from the batch shipment.
          </Typography>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleCloseModal} variant='outlined' color='secondary' sx={{ mr: 1 }}>
            Cancel
          </Button>
          <LoadingButton
            variant='contained'
            color='error'
            loading={loading}
            onClick={handleConfirm}
          >
            Confirm
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ShipmentItemOption;

ShipmentItemOption.propTypes = {
  lineItemId: PropTypes.string.isRequired,
  addressDirectoryId: PropTypes.string.isRequired,
};
