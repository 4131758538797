import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Chip, Stack } from '@mui/material';
import { compact, isEmpty, values } from 'lodash';

import { SortKey } from 'enums/common.enum';
import { ExternalOrdersFieldKeys } from 'enums/order.enum';
import { updateProcessedExternalOrdersFilter } from 'store/thunks/workQueueThunks';

const ProcessedExternalOrdersFilterStatusBar = () => {
  const dispatch = useDispatch();
  const filter = useSelector(({ workQueue }) => workQueue.processedExternalOrders.filter);

  const removeFilter = (filterName) => () => {
    dispatch(
      updateProcessedExternalOrdersFilter({
        filter: {
          ...filter,
          [filterName]: undefined,
        },
      })
    );
  };

  const removeAllFilter = () => dispatch(updateProcessedExternalOrdersFilter({ filter: {} }));

  return (
    <Stack direction='row' spacing={1}>
      {filter?.orderId && (
        <Chip
          label={`Order Id = ${filter.orderId}`}
          variant='contained'
          onDelete={removeFilter(ExternalOrdersFieldKeys.ORDER_ID)}
        />
      )}

      {filter?.patientName && (
        <Chip
          label={`Patient Name = ${filter.patientName}`}
          variant='contained'
          onDelete={removeFilter(ExternalOrdersFieldKeys.PATIENT_NAME)}
        />
      )}

      {filter?.partnerName && (
        <Chip
          label={`Partner Name = ${filter.partnerName}`}
          variant='contained'
          onDelete={removeFilter(ExternalOrdersFieldKeys.PARTNER_NAME)}
        />
      )}

      {filter?.status && (
        <Chip
          label={`Order Status = ${filter.status}`}
          variant='contained'
          onDelete={removeFilter(ExternalOrdersFieldKeys.STATUS)}
        />
      )}

      {filter?.createdAt && (
        <Chip
          label={`Created At = ${format(new Date(filter.createdAt), 'MM/dd/yyyy')}`}
          variant='contained'
          onDelete={removeFilter(SortKey.CREATED_AT)}
        />
      )}

      {!isEmpty(compact(values(filter))) && <Button onClick={removeAllFilter}>Remove All</Button>}
    </Stack>
  );
};

export default ProcessedExternalOrdersFilterStatusBar;
