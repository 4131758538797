import { createSlice } from '@reduxjs/toolkit';
import { assign, filter, find, includes, map, merge, orderBy, reject, remove } from 'lodash';
import { ContextType } from 'common/constants/notes';
import { PatientStatus } from 'enums/patient.enum';

const initialState = {};

/* eslint no-param-reassign: ["error", { "ignorePropertyModificationsFor": ["state"] }] */
const patientSlice = createSlice({
  name: 'patient',
  initialState,
  reducers: {
    resetPatientState(state) {
      Object.assign(state, initialState);
    },
    loadPatientData(state, action) {
      const { patient } = action.payload;

      state[patient.mpi] = assign(state[patient.mpi], patient);
    },
    loadComments(state, action) {
      const { mpi, comments, hasMoreComments } = action.payload;

      state[mpi] = assign(state[mpi], { comments, hasMoreComments });
    },
    deleteComment(state, action) {
      const { mpi, patientCommentId } = action.payload;

      state[mpi].comments = reject(state[mpi].comments, { commentId: patientCommentId });
    },
    loadGuarantors(state, action) {
      const { mpi, guarantors } = action.payload;

      state[mpi] = assign(state[mpi], { guarantors });
    },
    loadMedications(state, action) {
      const { mpi, medications } = action.payload;

      state[mpi] = assign(state[mpi], { medications });
    },
    loadPrescriptions(state, action) {
      const { mpi, prescriptions } = action.payload;

      state[mpi] = assign(state[mpi], { prescriptions });
    },
    loadMedicalHistory(state, action) {
      const { mpi, medicalHistory } = action.payload;

      state[mpi] = assign(state[mpi], { medicalHistory });
    },
    loadAuthorizations(state, action) {
      const { mpi, authorization } = action.payload;

      state[mpi] = assign(state[mpi], { authorization });
    },
    loadInsurances(state, action) {
      const { mpi, insurances } = action.payload;

      state[mpi] = assign(state[mpi], { insurances });
    },
    loadProviders(state, action) {
      const { mpi, providers } = action.payload;

      state[mpi] = assign(state[mpi], { providers });
    },
    loadPharmacies(state, action) {
      const { mpi, pharmacies } = action.payload;

      state[mpi] = assign(state[mpi], { pharmacies });
    },
    updatePartner(state, action) {
      const { updatedPatientPartner, mpi } = action.payload;

      const filteredPartners = filter(state[mpi].partnerIds, (partner) => {
        return partner.partnerId !== updatedPatientPartner.partnerId;
      });
      state[mpi] = assign(state[mpi], { partnerIds: [...filteredPartners, updatedPatientPartner] });
    },
    loadRequests(state, action) {
      const { mpi, requests } = action.payload;

      state[mpi] = assign(state[mpi], { requests });
    },
    updateTransferRequestStatus(state, action) {
      const { mpi, requestId, requestItem } = action.payload;
      const { taggedForReview, requestLineItemId, status, reviewReason, qs1RxNumber } = requestItem;

      state[mpi].requests[requestId].transferRequest[requestLineItemId] = {
        ...state[mpi].requests[requestId].transferRequest[requestLineItemId],
        status,
        taggedForReview,
        reviewReason,
        qs1RxNumber,
      };
    },
    loadAddresses(state, action) {
      const { mpi, addresses } = action.payload;
      const formattedAddress = map(addresses, (address) => ({
        ...address,
        nameOnAddress: address.nameOnAddress || state[mpi]?.patientName || '',
      }));

      state[mpi] = assign(state[mpi], {
        addresses: orderBy(formattedAddress, 'createdTimestamp', 'desc'),
      });
    },
    updateInvoicesCurrentPage(state, action) {
      const { mpi, page } = action.payload;

      state[mpi].invoices = merge(state[mpi].invoices, { currentPage: page });
    },
    loadInvoices(state, action) {
      const { mpi, page, invoices, count } = action.payload;

      state[mpi].invoices = merge(state[mpi].invoices, { count, pages: { [page]: invoices } });
    },
    loadOrders(state, action) {
      const { mpi, orders } = action.payload;

      state[mpi] = assign(state[mpi], { orders });
    },
    addLineItemsToOrder(state, action) {
      const { mpi, requestItem } = action.payload;
      const {
        item,
        requestLineItemId,
        needsByDate,
        requestedDate,
        qs1RxNumber,
        status: lineItemStatus,
      } = requestItem;
      const orders = state[mpi].orders || [];
      const existingOrder = find(orders, { orderId: requestItem.orderId });
      const lineItem = {
        needsByDate,
        requestedDate,
        lineItemId: requestLineItemId,
        itemName: item.patientMedication.name,
        itemType: 'PRESCRIPTION',
        rxNumber: qs1RxNumber,
        strength: item.patientMedication.strength,
        form: item.patientMedication.form,
        quantity: item.patientMedication.form,
        lineItemStatus,
      };

      if (existingOrder) {
        existingOrder.lineItems.push(lineItem);
      } else {
        state[mpi].orders = [
          ...orders,
          { orderId: requestItem.orderId, status: 'OPEN', lineItems: [lineItem] },
        ];
      }
    },
    updatePatientNotificationPreference(state, action) {
      const { email, phone, mpi } = action.payload;

      state[mpi] = assign(state[mpi], { notificationPreferences: { email, phone } });
    },
    loadLineItemNotes(state, action) {
      const { mpi, notes, vaultId, vaultType, lineItemId, hasPinnedNote } = action.payload;

      if (vaultType === ContextType.LINE_ITEM) {
        const orderLineItems = find(state[mpi].orders, { orderId: vaultId })?.lineItems;
        assign(find(orderLineItems, { lineItemId }), { notes, hasPinnedNote });
      } else {
        const requestLineItems = state[mpi]?.requests[vaultId].transferRequest;
        assign(requestLineItems[lineItemId], { notes, hasPinnedNote });
      }
    },
    loadShipments(state, action) {
      const { mpi, shipments, shipmentFilter } = action.payload;

      state[mpi] = assign(state[mpi], { shipmentHistory: { shipments, shipmentFilter } });
    },
    updateOrder(state, action) {
      const { mpi, orderId } = action.payload;
      const order = find(state[mpi].orders, { orderId });
      const {
        status = order.status,
        orderCost = order.orderCost,
        shippingAddress = order.shippingAddress,
      } = action.payload;

      order.status = status;
      order.orderCost = orderCost;
      order.shippingAddress = shippingAddress;
    },
    removeOrder(state, action) {
      const { mpi, orderId } = action.payload;

      remove(state[mpi]?.orders, { orderId });
    },
    loadPatientUsers(state, action) {
      const { mpi, patientUsers } = action.payload;

      state[mpi] = assign(state[mpi], { users: patientUsers });
    },
    loadPatientFailedOrder(state, action) {
      const { mpi, failedOrders } = action.payload;

      state[mpi] = assign(state[mpi], { failedOrders });
    },
    updateOrderLineItem(state, action) {
      const { lineItemId, cost, status, orderId, hasPinnedNote, mpi, processingState } =
        action.payload;
      const orderItems = find(state[mpi].orders, { orderId })?.lineItems;

      map(orderItems, (item) => {
        if (item.lineItemId === lineItemId) {
          item.cost = cost || item.cost;
          item.lineItemStatus = status || item.lineItemStatus;
          item.hasPinnedNote = hasPinnedNote || item.hasPinnedNote;
          item.processingState = processingState || item.processingState;
        }
      });
    },
    updatePrescriptionState(state, action) {
      const { prescriptionId: prescriptionUUID, mpi } = action.payload;
      const prescription = find(state[mpi].prescriptions, { prescriptionUUID });

      const { isActive = prescription.isActive, autofill = prescription.autofill } = action.payload;

      prescription.isActive = isActive;
      prescription.autofill = autofill;
    },
    loadPatientQs1Profile(state, action) {
      const { mpi, qs1Profiles } = action.payload;
      state[mpi] = assign(state[mpi], { qs1Profiles });
    },
    loadSystemIds(state, action) {
      const { mpi, systemIds } = action.payload;
      const patient = state[mpi];
      patient.status = PatientStatus.QUALIFIED;

      state[mpi] = assign(state[mpi], {
        systemIds: {
          qs1PatientId: systemIds.qs1Id,
          invoiceServiceCustomerId: systemIds.invoiceId,
          crmId: systemIds.crmId,
          paymentServiceCustomerId: systemIds.paymentServiceId,
        },
      });
    },
    loadPrescriptionNotes(state, action) {
      const { mpi, notes, contextId } = action.payload;
      const prescription = find(state[mpi].prescriptions, { prescriptionUUID: contextId });
      assign(prescription, { notes });
    },
    updateOrderLineItemTaggedReview(state, action) {
      const { lineItemIds, taggedForReview, orderId, patientId } = action.payload;
      const orderItems = find(state[patientId].orders, { orderId })?.lineItems;

      map(orderItems, (item) => {
        if (includes(lineItemIds, item.lineItemId)) {
          item.taggedForReview = taggedForReview;
        }
      });
    },
  },
});

export const patientActions = patientSlice.actions;
export default patientSlice.reducer;
