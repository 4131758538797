import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import {
  Box,
  Grid,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import { includes, isEmpty, isNil, map, toLower } from 'lodash';
import { visuallyHidden } from '@mui/utils';
import { format } from 'date-fns';

import LoadingModule from 'common/components/LoadingModule';
import NothingFound from 'common/components/NothingFound';
import { loadDrugProfiles, updateDrugProfileSortBy } from 'store/thunks/drugThunks';
import { DrugProfileFieldKeys, DrugProfileStatus } from 'enums/drugManagement.enum';
import { SortOrder } from 'enums/common.enum';

import DrugOptions from './DrugOptions';

const headCells = [
  {
    id: DrugProfileFieldKeys.DRUG_NAME,
    label: 'Drug Name',
  },
  {
    id: DrugProfileFieldKeys.DESCRIPTION,
    label: 'Description',
  },
  {
    id: DrugProfileFieldKeys.STRENGTH,
    label: 'Strength',
  },
  {
    id: DrugProfileFieldKeys.UNIT,
    label: 'Unit',
  },
  {
    id: DrugProfileFieldKeys.NDC,
    label: 'NDC',
  },
  {
    id: DrugProfileFieldKeys.MANUFACTURER,
    label: 'Manufacturer',
  },
  {
    id: DrugProfileFieldKeys.CREATED_AT,
    label: 'Created At',
  },
  {
    id: DrugProfileFieldKeys.UPDATED_AT,
    label: 'Updated At',
  },
  {
    id: DrugProfileFieldKeys.STATUS,
    label: 'Status',
  },
];

const sortableFields = [
  DrugProfileFieldKeys.DRUG_NAME,
  DrugProfileFieldKeys.CREATED_AT,
  DrugProfileFieldKeys.UPDATED_AT,
];

const DrugsTable = () => {
  const dispatch = useDispatch();

  const { sortBy, pages, currentPage, count } = useSelector(
    ({ drugs: { drugProfiles } }) => drugProfiles
  );
  const drugProfileList = pages[currentPage];
  const totalPages = Math.ceil(count / 25);

  const handleSort = (event) => {
    const sortKey = event.currentTarget?.dataset?.headProperty;

    dispatch(
      updateDrugProfileSortBy({
        sortBy: {
          sortKey,
          sortOrder:
            sortBy.sortKey === sortKey && sortBy.sortOrder === SortOrder.ASCENDING
              ? SortOrder.DESCENDING
              : SortOrder.ASCENDING,
        },
      })
    );
  };

  const handlePageChange = (_, page) => {
    if (currentPage === page) {
      return;
    }

    dispatch(loadDrugProfiles({ page }));

    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    dispatch(loadDrugProfiles({ page: 1 }));
  }, [dispatch]);

  return (
    <Grid component={Paper} container direction='column' sx={{ py: 1, px: 2 }}>
      <Grid item>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {headCells.map((headCell) => (
                  <TableCell key={headCell.id} align='left'>
                    {!includes(sortableFields, headCell.id) ? (
                      headCell.label
                    ) : (
                      <TableSortLabel
                        active={sortBy?.sortKey === headCell.id}
                        direction={
                          sortBy?.sortKey === headCell.id ? toLower(sortBy?.sortOrder) : 'asc'
                        }
                        onClick={handleSort}
                        data-head-property={headCell.id}
                      >
                        {headCell.label}
                        {sortBy.sortKey === headCell.id ? (
                          <Box component='span' sx={visuallyHidden}>
                            {toLower(sortBy.sortOrder) === 'desc'
                              ? 'sorted descending'
                              : 'sorted ascending'}
                          </Box>
                        ) : null}
                      </TableSortLabel>
                    )}
                  </TableCell>
                ))}
                <TableCell />
              </TableRow>
            </TableHead>

            <TableBody>
              {map(
                drugProfileList,
                ({
                  drugName,
                  strength,
                  unit,
                  ndc,
                  manufacturer,
                  createdAt,
                  updatedAt,
                  isActive,
                  drugProfileId,
                  associatedPrescriptionDescriptions,
                }) => (
                  <TableRow key={drugProfileId} hover>
                    <TableCell align='left'>{drugName}</TableCell>
                    <TableCell align='left'>{associatedPrescriptionDescriptions}</TableCell>
                    <TableCell align='left'>{strength} </TableCell>
                    <TableCell align='left'>{unit || 'N/A'} </TableCell>
                    <TableCell align='left'>{ndc} </TableCell>
                    <TableCell align='left'>{manufacturer || 'N/A'} </TableCell>
                    <TableCell align='left'>{format(new Date(createdAt), 'MM/dd/yyyy')}</TableCell>
                    <TableCell align='left'>{format(new Date(updatedAt), 'MM/dd/yyyy')}</TableCell>
                    <TableCell align='left'>
                      {isActive ? DrugProfileStatus.ACTIVE : DrugProfileStatus.INACTIVE}
                    </TableCell>
                    <TableCell align='left'>
                      <DrugOptions drugProfileId={drugProfileId} />
                    </TableCell>
                  </TableRow>
                )
              )}
            </TableBody>

            {!isNil(drugProfileList) && totalPages > 1 && (
              <TableFooter>
                <TableRow>
                  <TableCell colSpan={11} align='right' sx={{ borderBottom: 'none' }}>
                    <Pagination
                      sx={{ justifyContent: 'flex-end', alignItems: 'flex-end', display: 'flex' }}
                      count={totalPages}
                      page={currentPage}
                      size='small'
                      onChange={handlePageChange}
                    />
                  </TableCell>
                </TableRow>
              </TableFooter>
            )}
          </Table>
        </TableContainer>
      </Grid>

      {isNil(drugProfileList) && (
        <Grid item>
          <LoadingModule />
        </Grid>
      )}

      {!isNil(drugProfileList) && isEmpty(drugProfileList) && (
        <Grid item>
          <NothingFound />
        </Grid>
      )}
    </Grid>
  );
};

export default DrugsTable;
