import { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { markPrescriptionReview } from 'store/thunks/patientThunks';
import { enqueueSnackbar } from 'notistack';
import useRoles from 'common/hooks/useRoles';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@mui/material';
import { find, isEmpty, map, size, xor } from 'lodash';

const ReviewPrescriptionButton = ({
  disabled,
  patientId,
  orderId,
  patientPrescriptions,
  lineItems,
}) => {
  const hasAccess = useRoles();
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedPrescriptions, setSelectedPrescriptions] = useState([]);
  const [selectedLineItems, setSelectedLineItems] = useState([]);

  const openDialog = () => setShowModal(true);

  const closeDialog = () => {
    setLoading(false);
    setSelectedPrescriptions([]);
    setSelectedLineItems([]);
    setShowModal(false);
  };

  const handleCheckboxChange = (prescriptionId, lineItemId) => {
    setSelectedPrescriptions((prevSelected) => xor(prevSelected, [prescriptionId]));
    setSelectedLineItems((prev) => xor(prev, [lineItemId]));
  };

  const handlePrescriptionReview = () => {
    setLoading(true);
    dispatch(
      markPrescriptionReview({
        patientId,
        prescriptionUUIDs: selectedPrescriptions,
        lineItemIds: selectedLineItems,
        orderId,
      })
    )
      .then((result) => {
        if (result) {
          const count = size(selectedPrescriptions);
          const message = `Prescription${count > 1 ? 's' : ''} of the order tagged for review`;
          enqueueSnackbar(message, { variant: 'warning' });
        }
      })
      .finally(() => {
        setLoading(false);
        closeDialog();
      });
  };

  return (
    <>
      <Button
        disabled={disabled || !hasAccess.needsReview}
        variant='outlined'
        color='warning'
        size='small'
        onClick={openDialog}
      >
        Needs Review
      </Button>

      <Dialog open={showModal} aria-describedby='ActionItemTab-ReviewPrescriptionButton-header'>
        <DialogTitle id='ActionItemTab-ReviewPrescriptionButton-header'>
          Confirm send prescriptions to review
        </DialogTitle>
        <DialogContent sx={{ minWidth: 500 }}>
          <>
            <Grid container alignItems='center' justifyContent='space-between' sx={{ mb: 2 }}>
              <Typography>Are you sure you want to mark the prescriptions to review?</Typography>
            </Grid>
            {map(
              lineItems,
              (lineItem) =>
                !lineItem.taggedForReview && (
                  <Grid
                    container
                    key={lineItem.lineItemId}
                    alignItems='center'
                    justifyContent='space-between'
                    spacing={1}
                    sx={{ mb: 1 }}
                  >
                    <Grid item>
                      <Typography fontWeight='600'>
                        {
                          find(patientPrescriptions, { prescriptionUUID: lineItem?.prescriptionId })
                            ?.drug
                        }
                      </Typography>
                    </Grid>

                    <Grid item>
                      <Checkbox
                        id={lineItem.prescriptionId}
                        size='small'
                        checked={selectedPrescriptions.includes(lineItem.prescriptionId)}
                        onChange={() =>
                          handleCheckboxChange(lineItem.prescriptionId, lineItem.lineItemId)
                        }
                        inputProps={{
                          'aria-label': `mark ${lineItem.prescriptionId} to be review`,
                        }}
                        sx={{ py: 0 }}
                      />
                    </Grid>
                  </Grid>
                )
            )}
          </>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} color='secondary' size='medium' variant='outlined'>
            Cancel
          </Button>
          <LoadingButton
            loading={loading}
            variant='contained'
            onClick={handlePrescriptionReview}
            disabled={isEmpty(selectedPrescriptions)}
          >
            Confirm
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

ReviewPrescriptionButton.propTypes = {
  disabled: PropTypes.bool,
  patientId: PropTypes.string.isRequired,
  orderId: PropTypes.string.isRequired,
  patientPrescriptions: PropTypes.arrayOf(
    PropTypes.shape({
      prescriptionUUID: PropTypes.string,
      drug: PropTypes.string,
    })
  ),
  lineItems: PropTypes.arrayOf(
    PropTypes.shape({
      lineItemId: PropTypes.string,
      prescriptionId: PropTypes.string,
    })
  ),
};

ReviewPrescriptionButton.defaultProps = {
  disabled: false,
  patientPrescriptions: [],
  lineItems: [],
};

export default ReviewPrescriptionButton;
