/* eslint-disable max-lines */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  ListItemText,
  MenuItem,
  Radio,
  Stack,
  Typography,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { capitalize, find, isArray, isEmpty, map, omit } from 'lodash';
import { useState } from 'react';
import { Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';

import gender from 'common/constants/gender';
import DateField from 'common/forms/DateField';
import SelectField from 'common/forms/SelectField';
import TextField from 'common/forms/TextField';
import languages from 'common/constants/languages';
import {
  validateEmail,
  validatePhoneAllowedInState,
  validatePhoneNumber,
  validateValueWithRegex,
  validateZipCode,
} from 'common/forms/formValidations';
import { addPatient, validateAddress } from 'store/thunks/patientThunks';
import usStateAbbreviation from 'common/constants/usStateAbbreviation';
import AutoCompleteField from 'common/forms/AutoCompleteField';
import leadSource from 'common/constants/leadSource';
import { formatZipCode } from 'common/utils';
import CheckboxField from 'common/forms/CheckboxField';
import RadioGroupField from 'common/forms/RadioGroupField';

const NewPatientButton = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const partners = useSelector(({ auth, partner }) => partner[auth.currentTenantId]);
  const [validatedAddresses, setValidatedAddresses] = useState([]);
  const [isMultipleAddresses, setIsMultipleAddresses] = useState(false);
  const [patientDataToSave, setPatientDataToSave] = useState({});
  const [isPatientLoading, setIsPatientLoading] = useState(false);

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = (_, reason) => {
    /* istanbul ignore next */
    if (reason !== 'backdropClick') {
      setPatientDataToSave({});
      setIsPatientLoading(false);
      setShowModal(false);
    }
  };

  const closeConfirmAddressDialog = () => {
    setIsMultipleAddresses(false);
  };

  const checkSmsAllowedInState = (state) =>
    find(usStateAbbreviation, { abbreviation: state })?.isSMSAllowed;

  const handleFinalConfirmAddress = (selectedAddress) => {
    const address = validatedAddresses[selectedAddress.addressChoice];

    const formattedPatientData = {
      ...patientDataToSave,
      addressLine1: address.line1,
      addressLine2: address.line2,
      city: address.city,
      state: address.state,
      zip: address.zip,
    };

    setPatientDataToSave(formattedPatientData);
    setIsMultipleAddresses(false);

    const patientData = {
      ...omit(formattedPatientData, [
        'primaryLanguage',
        'originalPartner',
        'partnerExternalId',
        'emailPreference',
        'phonePreference',
        'patientData',
      ]),
      languages: [{ language: formattedPatientData.primaryLanguage, isPrimary: true }],
      partners: {
        partnerUUID: formattedPatientData.originalPartner,
        isOriginalPartner: true,
        partnerExternalId: formattedPatientData.partnerExternalId,
      },
      dob: format(new Date(formattedPatientData.dob), 'yyyy-MM-dd'),
      preferencesState: {
        email: formattedPatientData.emailPreference,
        phone:
          formattedPatientData.phonePreference &&
          checkSmsAllowedInState(formattedPatientData.state),
      },
    };

    setIsPatientLoading(true);

    return dispatch(addPatient({ patientData })).then((mpi) => mpi && navigate(`/patients/${mpi}`));
  };

  const handleAddPatient = (formData) => {
    setPatientDataToSave(formData);
    setIsPatientLoading(true);

    const { addressLine1, addressLine2, city, state, zip } = formData;

    dispatch(
      validateAddress({ input: { line1: addressLine1, line2: addressLine2, city, state, zip } })
    )
      .then((result) => {
        if (isArray(result)) {
          setValidatedAddresses(result);
          setIsPatientLoading(false);

          setIsMultipleAddresses(true);
        }
      })
      .catch(() => setIsPatientLoading(false));
  };

  return (
    <>
      <Button variant='contained' onClick={handleOpenModal}>
        New
      </Button>

      {isMultipleAddresses && !isEmpty(validatedAddresses) && (
        <Dialog
          open={isMultipleAddresses}
          maxWidth='sm'
          fullWidth
          aria-labelledby='AddPatient-ConfirmAddress-header'
        >
          <Typography variant='h6' sx={{ px: 3, pt: 2 }}>
            Confirm Address
          </Typography>

          <Form
            onSubmit={handleFinalConfirmAddress}
            render={({ values, handleSubmit }) => (
              <form noValidate onSubmit={handleSubmit}>
                <DialogContent>
                  <Typography sx={{ pb: 2 }}>
                    {validatedAddresses.length > 1
                      ? 'Multiple addresses found. Please choose one from the suggestions below.'
                      : 'Please confirm the address below.'}
                  </Typography>
                  <RadioGroupField
                    name='addressChoice'
                    id='AddPatient-AddressChoice-radioField'
                    label=''
                  >
                    <Stack direction='column' alignItems='flex-start' gap={2}>
                      {map(validatedAddresses, (address, index) => (
                        <FormControlLabel
                          key={`validateAddress-${index}`}
                          value={index}
                          control={<Radio sx={{ mr: 1 }} />}
                          sx={{ px: 1 }}
                          label={
                            <ListItemText
                              secondary={
                                <>
                                  <Typography>{address.line1}</Typography>
                                  {address?.line2 && <Typography>{address.line2}</Typography>}
                                  <Typography>
                                    {address.city} , {address.state}, {address.zip}
                                  </Typography>
                                </>
                              }
                            />
                          }
                        />
                      ))}
                    </Stack>
                  </RadioGroupField>
                </DialogContent>
                <DialogActions>
                  <Button variant='outlined' color='secondary' onClick={closeConfirmAddressDialog}>
                    Cancel
                  </Button>
                  <Button
                    variant='contained'
                    disabled={isEmpty(values?.addressChoice)}
                    onClick={handleSubmit}
                    type='submit'
                  >
                    Confirm
                  </Button>
                </DialogActions>
              </form>
            )}
          />
        </Dialog>
      )}

      <Dialog
        open={showModal}
        onClose={handleCloseModal}
        aria-describedby='PatientsList-NewPatientButton-header'
        fullWidth
      >
        <DialogTitle id='PatientsList-NewPatientButton-header'>Add Patient</DialogTitle>

        <Form
          onSubmit={handleAddPatient}
          initialValues={patientDataToSave}
          render={({ handleSubmit, invalid, submitting }) => (
            <form noValidate onSubmit={handleSubmit}>
              <DialogContent>
                <TextField
                  id='PatientsList-NewPatientButton-firstName-input'
                  label='First Name'
                  name='firstName'
                  required
                  autoFocus
                />

                <TextField
                  id='PatientsList-NewPatientButton-lastName-input'
                  label='Last Name'
                  name='lastName'
                  required
                />

                <TextField
                  id='PatientsList-NewPatientButton-middleName-input'
                  label='Middle Name'
                  name='middleName'
                />

                <DateField
                  name='dob'
                  id='PatientsList-NewPatientButton-dob-input'
                  label='Date of Birth'
                  maxDate={new Date()}
                  required
                />

                <TextField
                  id='PatientView-EditPatientButton-ssn-input'
                  label='SSN (Last 4)'
                  name='last4ssn'
                  validations={[validateValueWithRegex('Must be 4 numbers', /^\d{4}$/)]}
                />

                <SelectField
                  name='gender'
                  id='PatientsList-NewPatientButton-gender-dropdown'
                  label='Gender'
                  required
                >
                  {map(gender, (name, value) => (
                    <MenuItem value={value} key={value}>
                      {name}
                    </MenuItem>
                  ))}
                </SelectField>

                <SelectField
                  name='primaryLanguage'
                  id='PatientsList-NewPatientButton-primaryLanguage-dropdown'
                  label='Primary Language Spoken'
                  required
                >
                  {map(languages, (language) => (
                    <MenuItem key={language} value={language}>
                      {capitalize(language)}
                    </MenuItem>
                  ))}
                </SelectField>

                <TextField
                  id='PatientsList-NewPatientButton-address1-input'
                  label='Address Line 1'
                  name='addressLine1'
                  required
                />

                <TextField
                  id='PatientsList-NewPatientButton-address2-input'
                  label='Address Line 2'
                  name='addressLine2'
                />

                <TextField
                  id='PatientsList-NewPatientButton-city-input'
                  label='City'
                  name='city'
                  required
                />

                <SelectField
                  id='PatientsList-NewPatientButton-state-dropdown'
                  label='State'
                  name='state'
                  required
                >
                  {map(usStateAbbreviation, ({ name, abbreviation }) => (
                    <MenuItem value={abbreviation} key={abbreviation}>
                      {name}
                    </MenuItem>
                  ))}
                </SelectField>

                <TextField
                  id='PatientsList-NewPatientButton-zip-input'
                  label='Zip'
                  name='zip'
                  type='zip'
                  required
                  validations={[validateZipCode()]}
                  format={formatZipCode}
                  maxLength={10}
                />

                <TextField
                  id='PatientsList-NewPatientButton-email-input'
                  label='Email'
                  name='email'
                  required
                  validations={[validateEmail()]}
                />

                <TextField
                  id='PatientsList-NewPatientButton-mobilePhoneNumber-input'
                  label='Mobile Phone Number'
                  name='mobilePhone'
                  required
                  validations={[validatePhoneNumber()]}
                />

                <AutoCompleteField
                  id='PatientsList-NewPatientButton-leadSource-autocomplete'
                  label='Lead Source'
                  name='leadSource'
                  options={leadSource}
                >
                  {map(leadSource, (source, key) => (
                    <MenuItem value={source} key={key}>
                      {source}
                    </MenuItem>
                  ))}
                </AutoCompleteField>

                <SelectField
                  label='Original Partner'
                  name='originalPartner'
                  id='PatientsList-NewPatientButton-originalPartner-dropdown'
                  required
                >
                  {map(partners, ({ partnerName, partnerUuid }) => (
                    <MenuItem value={partnerUuid} key={partnerUuid}>
                      {partnerName}
                    </MenuItem>
                  ))}
                </SelectField>

                <TextField
                  id='PatientsList-NewPatientButton-partnerExternalId-input'
                  label='Partner External Id'
                  name='partnerExternalId'
                />

                <CheckboxField
                  label='Email Notification Preference'
                  name='emailPreference'
                  id='PatientsList-NewPatientButton-emailPreference-checkbox'
                />

                <CheckboxField
                  label='Phone Notification Preference'
                  name='phonePreference'
                  id='PatientsList-NewPatientButton-phonePreference-checkbox'
                  validations={[validatePhoneAllowedInState(undefined, 'state')]}
                />
              </DialogContent>

              <DialogActions>
                <Button variant='outlined' color='secondary' onClick={handleCloseModal}>
                  Cancel
                </Button>
                <LoadingButton
                  loading={submitting || isPatientLoading}
                  variant='contained'
                  disabled={invalid}
                  type='submit'
                >
                  Save
                </LoadingButton>
              </DialogActions>
            </form>
          )}
        />
      </Dialog>
    </>
  );
};

export default NewPatientButton;
