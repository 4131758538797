import { Box, Collapse, IconButton, TableCell, TableRow } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { useTheme } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import { capitalize } from 'lodash';

import orderStatus from 'common/constants/orderStatus';
import OrderVerificationLineItemsRow from './OrderVerificationLineItemsRow';

const OrderVerificationRow = ({ order, partnerAccountHandler }) => {
  const theme = useTheme();
  const [showDetails, setShowDetails] = useState();

  const toggleDetails = () => setShowDetails((show) => !show);

  const getPartnerAccountName = (partnerAccountId) => {
    return partnerAccountId && partnerAccountHandler[partnerAccountId]
      ? capitalize(partnerAccountHandler[partnerAccountId].partnerAccountName)
      : 'N/A';
  };

  const { mpi, patientName, orderId, requestNeedsByDate, createdAt } = order;
  const { lastUpdated, orderStatus: status, waitingOnFill, totalInOrder } = order;
  const { partnerName, partnerAccountId } = order;

  return (
    <>
      <TableRow hover>
        <TableCell sx={{ py: 1 }}>
          <IconButton
            aria-label={`Toggle Details ${order.orderId}`}
            size='small'
            onClick={toggleDetails}
          >
            {showDetails ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align='center'>{orderId}</TableCell>
        <TableCell align='center'>
          {format(utcToZonedTime(new Date(requestNeedsByDate), 'utc'), 'MM/dd/yyyy')}
        </TableCell>
        <TableCell component='th' scope='row' align='center'>
          <Link to={`/patients/${mpi}`} style={{ color: theme.palette.text.primary }}>
            {patientName}
          </Link>
        </TableCell>
        <TableCell align='center'>{capitalize(partnerName)}</TableCell>
        <TableCell align='center'>{getPartnerAccountName(partnerAccountId)}</TableCell>
        <TableCell align='center'>{orderStatus[status]}</TableCell>
        <TableCell align='center'>{format(new Date(createdAt), 'MM/dd/yyyy hh:mm a')}</TableCell>
        <TableCell align='center'>{format(new Date(lastUpdated), 'MM/dd/yyyy hh:mm a')}</TableCell>
        <TableCell align='center'>{waitingOnFill}</TableCell>
        <TableCell align='center'>{totalInOrder}</TableCell>
      </TableRow>

      <TableRow>
        <TableCell colSpan={8} sx={{ p: 0, border: 'none' }}>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Collapse in={showDetails} timeout='auto' sx={{ width: '100%' }}>
              {order?.lineItems && (
                <OrderVerificationLineItemsRow
                  lineItemRow={order.orderId}
                  lineItems={order.lineItems}
                />
              )}
            </Collapse>
          </Box>
        </TableCell>
      </TableRow>
    </>
  );
};

OrderVerificationRow.propTypes = {
  order: PropTypes.shape({
    mpi: PropTypes.string.isRequired,
    patientName: PropTypes.string.isRequired,
    orderId: PropTypes.string.isRequired,
    requestNeedsByDate: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
    lastUpdated: PropTypes.string.isRequired,
    orderStatus: PropTypes.string.isRequired,
    waitingOnFill: PropTypes.number.isRequired,
    totalInOrder: PropTypes.number.isRequired,
    lineItems: PropTypes.arrayOf(
      PropTypes.shape({
        lineItemId: PropTypes.string,
        itemName: PropTypes.string,
        rxNumber: PropTypes.string,
        strength: PropTypes.string,
        form: PropTypes.string,
        quantity: PropTypes.string,
        fillType: PropTypes.string,
        needsByDate: PropTypes.string,
      })
    ).isRequired,
    partnerName: PropTypes.string.isRequired,
    partnerAccountId: PropTypes.string,
  }).isRequired,
  partnerAccountHandler: PropTypes.objectOf(
    PropTypes.shape({
      partnerAccountName: PropTypes.string.isRequired,
      partnerAccountId: PropTypes.string,
    })
  ).isRequired,
};

export default OrderVerificationRow;
