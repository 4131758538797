import { createSlice } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const initialState = {
  theme: 'perfectRxTheme',
  ipAddress: undefined,
  geolocation: undefined,
};

/* eslint no-param-reassign: ["error", { "ignorePropertyModificationsFor": ["state"] }] */
const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    /* istanbul ignore next */
    updateGeolocation(state, action) {
      const { ipAddress, geolocation } = action.payload;

      state.ipAddress = ipAddress;
      state.geolocation = geolocation;
    },
    loadPublicKey(state, action) {
      const { publicKey } = action.payload;

      state.publicKey = publicKey;
    },
  },
});

const localPersistConfig = {
  key: 'app',
  storage,
};

export const appActions = appSlice.actions;
export default persistReducer(localPersistConfig, appSlice.reducer);
