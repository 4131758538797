import { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  MenuItem,
  Tooltip,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-final-form';
import { filter, find, first, isEmpty, map } from 'lodash';
import { enqueueSnackbar } from 'notistack';
import ShippingIcon from '@mui/icons-material/LocalShipping';
import InventoryIcon from '@mui/icons-material/Inventory';

import { createOrderShipment } from 'store/thunks/patientThunks';
import SelectField from 'common/forms/SelectField';
import useRoles from 'common/hooks/useRoles';
import { listShippingOptions } from 'store/thunks/batchShipmentThunk';

const SendToShippingButton = ({ mpi, orderId, orderStatus }) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const hasAccess = useRoles();
  const orders = useSelector(({ patient }) => patient[mpi]?.orders);
  const order = find(orders, (orderObj) => orderObj.orderId === orderId);
  const { lineItems } = order;
  const lineItemIds = map(lineItems, 'lineItemId');
  const lineItemsInBatchShipment = filter(lineItems, { inBatchShipmentQueue: true });
  const isDisabled = lineItemIds.length === lineItemsInBatchShipment.length;
  const lineItemsNotInBatchShipment = filter(lineItems, { inBatchShipmentQueue: false });
  const lineItemsNotInBatchShipmentIds = map(lineItemsNotInBatchShipment, 'lineItemId');
  const listObject = [
    {
      listData: {
        heading: 'In Batch Shipment',
        value: !isEmpty(lineItemsInBatchShipment)
          ? lineItemsInBatchShipment
          : [{ default: 'Nothing found...' }],
      },
    },
    { listData: { heading: 'In Order Shipment', value: lineItemsNotInBatchShipment } },
  ];
  const shippingOptions = useSelector(({ systemConfigs }) => systemConfigs.shippingOptions);

  const defaultShippingOption = first(lineItems)?.shippingOption;

  const handleCloseModal = () => setShowModal(false);
  const handleOpenModal = () => setShowModal(true);

  const handleSendToShipment = ({ shipmentType }) => {
    setIsLoading(true);

    dispatch(
      createOrderShipment({
        mpi,
        orderId,
        shipmentType,
        lineItemIds: lineItemsNotInBatchShipmentIds,
      })
    ).then((success) => {
      if (success) {
        enqueueSnackbar('Successfully send to shipment', { variant: 'success' });
      }

      setIsLoading(false);
      handleCloseModal();
    });
  };

  useEffect(() => {
    dispatch(listShippingOptions());
  }, [dispatch]);

  return (
    <>
      <Dialog
        open={showModal}
        onClose={handleCloseModal}
        aria-describedby='SendToShipping-Dialog-header'
        fullWidth
      >
        <DialogTitle id='SendToShipping-Dialog-header'>Send to Shipping</DialogTitle>

        <Form
          onSubmit={handleSendToShipment}
          initialValues={{ shipmentType: defaultShippingOption }}
          render={({ handleSubmit }) => (
            <form noValidate onSubmit={handleSubmit}>
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    {isDisabled ? (
                      <Typography fontWeight='bold'>
                        All line items are in the batch shipment queue. Complete the batch shipment
                        or remove items from the batch queue to ship separately
                      </Typography>
                    ) : (
                      <>
                        <List
                          sx={{
                            width: '100%',
                            position: 'relative',
                            overflow: 'auto',
                            maxHeight: 300,
                            '& ul': { padding: 0 },
                          }}
                          subheader={<li />}
                        >
                          {map(listObject, (listItem) => (
                            <li key={`section-${listItem.listData.heading}`}>
                              <ul>
                                <ListSubheader sx={{ fontWeight: 'bold' }}>
                                  <Typography sx={{ fontWeight: 'bold' }}>
                                    {listItem.listData.heading}
                                  </Typography>
                                </ListSubheader>

                                {map(listItem.listData.value, (lineItem) => (
                                  <li key={`section-${lineItem.lineItemId}`}>
                                    <ul>
                                      <ListItem key={`item-${lineItem.rxNumber}`}>
                                        <ListItemIcon>
                                          {lineItem.inBatchShipmentQueue && (
                                            <ShippingIcon color='primary' />
                                          )}

                                          {!lineItem.inBatchShipmentQueue && !lineItem.default && (
                                            <InventoryIcon color='primary' />
                                          )}
                                        </ListItemIcon>

                                        <ListItemText
                                          primary={lineItem?.itemName ?? lineItem.default}
                                          secondary={lineItem?.rxNumber}
                                        />
                                      </ListItem>
                                    </ul>
                                  </li>
                                ))}
                              </ul>
                            </li>
                          ))}
                        </List>
                        <Typography sx={{ fontWeight: 'bold' }}>
                          The items under Order Shipment will be sent in this shipment. Click
                          Confirm to proceed
                        </Typography>
                      </>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <SelectField
                      id='SendToShipping-shippingType-dropdown'
                      label='Select Shipping Method'
                      name='shipmentType'
                      disabled={isDisabled}
                    >
                      {map(shippingOptions, ({ shippingOption, optionCode }, key) => (
                        <MenuItem value={optionCode} key={key}>
                          {shippingOption}
                        </MenuItem>
                      ))}
                    </SelectField>
                  </Grid>
                </Grid>
              </DialogContent>

              <DialogActions>
                <Button variant='outlined' color='secondary' onClick={handleCloseModal}>
                  Cancel
                </Button>
                <LoadingButton
                  loading={isLoading}
                  variant='contained'
                  type='submit'
                  disabled={isDisabled}
                >
                  Send
                </LoadingButton>
              </DialogActions>
            </form>
          )}
        />
      </Dialog>

      {hasAccess.createOrderShipment && (
        <Grid justifyContent='flex-end'>
          <Tooltip
            title={orderStatus === 'HOLD' ? 'Order payment in hold' : ''}
            placement='top'
            arrow
          >
            <span>
              <LoadingButton
                variant='contained'
                onClick={handleOpenModal}
                sx={{ mr: 2 }}
                disabled={orderStatus === 'HOLD'}
              >
                Send to Shipping
              </LoadingButton>
            </span>
          </Tooltip>
        </Grid>
      )}
    </>
  );
};

SendToShippingButton.propTypes = {
  orderId: PropTypes.string.isRequired,
  mpi: PropTypes.string.isRequired,
  orderStatus: PropTypes.string.isRequired,
};

export default SendToShippingButton;
