/* eslint-disable import/prefer-default-export */
export const getEventDescription = (requestData, eventData) => {
  const firstName = requestData.firstName || '';
  const lastName = requestData.lastName || '';
  const actionItemType = requestData.actionItemType || '';
  const orderId = eventData?.orderId || requestData.orderId || '';
  const requestId = requestData.requestId || '';
  const orderShipmentId = requestData.orderShipmentId || '';
  const rxNumber = requestData.rxNumber || '';
  const referenceId = requestData.referenceId || '';
  const fromPharmacyNpi = requestData.fromPharmacyNpi || '';
  const toPharmacyNpi = requestData.toPharmacyNpi || '';

  const actionItemTypeHandler = {
    patientPrescreening: `Patient ${lastName},${firstName} prescreening has completed`,
    LABEL_PRINTED: `${orderId} - Order's labels printed`,
    PRODUCT_FULFILLED: `${lastName}, ${firstName}'s order ${orderId} with Rx number ${
      rxNumber || 'NA'
    } has fulfilled`,
    PRODUCT_FULFILLMENT_UNDONE: `${lastName}, ${firstName}'s order ${orderId} with Rx number ${
      rxNumber || 'NA'
    } fulfillment undone`,
    CREATED_NEW_ORDER: `New order ${orderId} created for patient ${lastName}, ${firstName}`,
    NEW_REQUEST_CREATED: `New Request ${requestId} created for patient ${lastName}, ${firstName}`,
    SHIPSTATION_ORDER_CREATED: `Shipstation order ${orderShipmentId} has been created for the order ${orderId}`,
    ORDER_CANCELED: `Order ${orderId} has been canceled`,
    LINE_ITEM_CANCELED: 'Order line item canceled',
    SHIPSTATION_ORDER_SHIPPED: `Shipstation order ${orderShipmentId} has been shipped successfully`,
    PRESCRIPTION_DIRECTION_REVIEW: `Prescription ${rxNumber} has marked for prescription direction review`,
    PRESCRIPTION_DIRECTION_REVIEW_PASS: `Prescription direction review passed for prescription ${rxNumber}`,
    ORDER_MARKED_FOR_REVIEW: `Order ${orderId} marked for external processing`,
    PRESCRIPTION_DUPLICATE_REVIEW: `Prescription ${rxNumber} has marked for prescription duplicate review`,
    PRESCRIPTION_DUPLICATE_REVIEW_PASS: `Prescription duplicate review passed for prescription ${rxNumber}`,
    DRUG_PROFILE_REVIEW: `Prescription ${rxNumber} is marked for drug profile review as a matching drug profile was not found`,
    LINE_ITEM_DRUG_PROFILE_RE_PROCESS: `Order ${orderId} line item was marked for automation of drug profile review`,
    ORDER_CREATED_IN_DYNAMO: `Prescription input validation has passed and the same is saved in Dynamo DB with reference Id: ${referenceId}`,
    PRESCRIPTION_REVIEW: `Prescription ${rxNumber} is marked for Prescription review`,
    ORDER_VERIFICATION: `Order ${orderId} is ready for verification`,
    ORDER_VERIFIED: `Order ${orderId} is verified by Pharmacist`,
    ORDER_RECEIVED_PARTNER_WEBHOOK_SUCCESS:
      'Order received event webhook was successfully sent to the partner',
    ORDER_SHIPPED_PARTNER_WEBHOOK_SUCCESS:
      'Order shipped event webhook was successfully sent to the partner',
    ORDER_CANCELED_PARTNER_WEBHOOK_SUCCESS:
      'Order canceled event webhook was successfully sent to the partner',
    E_SCRIPT_ORDER_CREATION_FAILED: 'Transmit EScript order creation in POM failed',
    PATIENT_PARTNER_ERROR:
      'Patient and partner are not associated or original partner association for the patient and partner was not enabled',
    PRACTICE_NOT_FOUND:
      'Practice provided in the input was invalid and it failed to create EScript order in POM',
    ORDER_BACK_TO_VERIFICATION: `Order ${orderId} moved back to order reverification`,
    SHIPSTATION_ORDER_DELETED: `Shipstation order ${orderShipmentId} has been deleted for the order ${orderId}`,
    FULFILLING_PHARMACY_TRANSFER: `Request transferred from Pharmacy ${fromPharmacyNpi} to Pharmacy ${toPharmacyNpi}`,
    ORDER_SENT_TO_PROCESS_EXTERNAL: `Order ${orderId} marked for external processing`,
    LINE_ITEM_DISASSOCIATED_FROM_AN_ORDER: `Line item disassociated from order ${
      eventData?.orderId || 'NA'
    }`,
    ADDED_LINE_ITEM_TO_ORDER: `Added line item to order ${orderId}`,
  };

  return actionItemTypeHandler[actionItemType];
};
