import useTabContext, { TabProvider } from 'common/hooks/useTabContext';
import { Box, Grid, Paper, Tab, Tabs } from '@mui/material';
import PropTypes from 'prop-types';
import PartnerManagement from 'modules/PartnerManagement';
import PracticeManagement from 'modules/PracticeManagement';
import useRoles from 'common/hooks/useRoles';
import { map } from 'lodash';
import partnerAndPracticeEnum from '../../../enums/partnerAndPracticeManagement.enum';

const TabPanel = ({ children, value }) => {
  const { tab } = useTabContext();
  const hidden = value !== tab;
  return (
    <div
      role='tabpanel'
      hidden={hidden}
      id={`partner-practice-tabpanel-${value}`}
      aria-labelledby={`partner-practice-tab-${value}`}
    >
      <Box sx={{ px: 1 }}>{children}</Box>
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  value: PropTypes.string.isRequired,
};

const a11yProps = (index) => ({
  id: `partner-practice-tab-${index}`,
  'aria-controls': `partner-practice-tabpanel-${index}`,
});

const tabsDetails = [
  {
    tabName: 'Partner Management',
    Component: PartnerManagement,
    tabValue: partnerAndPracticeEnum.PARTNER_MANAGEMENT,
    accessRoleName: 'viewPartnerManagement',
  },
  {
    tabName: 'Practice Management',
    Component: PracticeManagement,
    tabValue: partnerAndPracticeEnum.PRACRTICE_MANAGEMENT,
    accessRoleName: 'viewPracticeManagement',
  },
];

const TabList = () => {
  const { tab, setTab } = useTabContext();
  const hasAccess = useRoles();

  const partnerAndPracticeTabs = tabsDetails;

  return (
    <Box sx={{ borderBottom: 2, borderColor: 'divider' }}>
      <Tabs
        value={tab}
        onChange={setTab}
        aria-label='Partner And Practice Details Tabs'
        scrollButtons='auto'
        sx={{ maxWidth: '85vw' }}
        variant='scrollable'
      >
        {map(
          partnerAndPracticeTabs,
          ({ tabName, tabValue, accessRoleName }, key) =>
            hasAccess[accessRoleName] && (
              <Tab label={tabName} {...a11yProps(key)} key={key} value={tabValue} />
            )
        )}
      </Tabs>
    </Box>
  );
};

const PartnerAndPracticeTab = ({ initialTab }) => {
  return (
    <TabProvider initialValue={initialTab}>
      <Grid container component={Paper} sx={{ px: 2 }} direction='column'>
        <TabList />
        {map(tabsDetails, ({ Component, tabValue }) => (
          <TabPanel value={tabValue} key={tabValue}>
            <Component />
          </TabPanel>
        ))}
      </Grid>
    </TabProvider>
  );
};

PartnerAndPracticeTab.propTypes = {
  initialTab: PropTypes.string.isRequired,
};
export default PartnerAndPracticeTab;
