import React from 'react';
import { Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import WorkQueues from 'enums/workQueue.enum';

import ProcessedExternalOrdersTable from './ProcessedExternalOrdersTable';
import ProcessedExternalOrdersFilterButton from './ProcessedExternalOrdersFilterButton';
import ProcessedExternalOrdersFilterStatusBar from './ProcessedExternalOrdersFilterStatusBar';

const ProcessedExternalOrders = () => {
  const preset = useSelector(({ workQueue }) => workQueue.preset);

  if (preset !== WorkQueues.PROCESSED_EXTERNAL_ORDERS) return null;

  return (
    <Grid container sx={{ py: 2 }}>
      <Grid item sx={{ minHeight: 40, flexGrow: 1 }} alignItems='center'>
        <ProcessedExternalOrdersFilterStatusBar />
      </Grid>
      <Grid item>
        <ProcessedExternalOrdersFilterButton />
      </Grid>
      <ProcessedExternalOrdersTable />
    </Grid>
  );
};

export default ProcessedExternalOrders;
