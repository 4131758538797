import { isNull, reduce } from 'lodash';
import graphqlClient from '../graphql';
import { createPracticeGql, listPracticesGql } from './gql';

export const listPracticeApi = ({ page, sortBy, filter }) => {
  const defaultItemsPerPage = Number(process.env.REACT_APP_DEFAULT_ITEMS_PER_PAGE);
  const defaultPageOffset = Number(process.env.REACT_APP_DEFAULT_PAGE_OFFSET);

  return graphqlClient
    .request(listPracticesGql, {
      limit: defaultItemsPerPage,
      offset: (page - 1) * defaultItemsPerPage || defaultPageOffset,
      sortBy,
      filter: reduce(
        filter,
        (result, value, key) => ({
          ...result,
          [key]: isNull(value) ? undefined : value,
        }),
        {}
      ),
    })
    .then(({ listPractices }) => listPractices);
};

export const createPracticeApi = ({ practiceInput, partnerUUID }) => {
  return graphqlClient
    .request(createPracticeGql, {
      practiceInput,
      partnerUUID,
    })
    .then(({ createPractice }) => createPractice);
};
