/* eslint-disable max-lines */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  ListItemText,
  MenuItem,
  Radio,
  Stack,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import LoadingButton from '@mui/lab/LoadingButton';
import PropTypes from 'prop-types';
import { isArray, isEmpty, map } from 'lodash';

import TextField from 'common/forms/TextField';
import SelectField from 'common/forms/SelectField';
import {
  validateEmail,
  validatePhoneNumber,
  validateValueWithRegex,
  validateZipCode,
} from 'common/forms/formValidations';
import { addPatientProvider, validateAddress } from 'store/thunks/patientThunks';
import usStateAbbreviation from 'common/constants/usStateAbbreviation';
import { formatZipCode } from 'common/utils';
import RadioGroupField from 'common/forms/RadioGroupField';

const NewProviderButton = ({ mpi }) => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const patientName = useSelector(({ patient }) => patient[mpi]?.firstName);
  const [providerDataToSave, setProviderDataToSave] = useState({});
  const [validatedAddresses, setValidatedAddresses] = useState([]);
  const [isMultipleAddresses, setIsMultipleAddresses] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = (_, reason) => {
    /* istanbul ignore next */
    if (reason !== 'backdropClick') {
      setProviderDataToSave({});
      setLoading(false);
      setShowModal(false);
    }
  };

  const closeConfirmAddressDialog = () => setIsMultipleAddresses(false);

  const handleFinalConfirmAddress = (selectedAddress) => {
    const address = validatedAddresses[selectedAddress.addressChoice];

    const formattedProviderData = {
      ...providerDataToSave,
      addressLine1: address.line1,
      addressLine2: address.line2,
      city: address.city,
      state: address.state,
      zip: address.zip,
    };

    setProviderDataToSave(formattedProviderData);
    setIsMultipleAddresses(false);
    setLoading(true);

    return dispatch(addPatientProvider({ provider: { mpi, ...formattedProviderData } })).then(
      (success) => {
        if (success) {
          handleCloseModal();
        }

        setLoading(false);
        setProviderDataToSave({});
      }
    );
  };

  const handleAddProvider = (formData) => {
    setLoading(true);
    const { addressLine1, addressLine2, city, state, zip } = formData;

    const address = { line1: addressLine1, line2: addressLine2, city, state, zip };
    setProviderDataToSave(formData);

    return dispatch(validateAddress({ input: address }))
      .then((result) => {
        if (isArray(result)) {
          setValidatedAddresses(result);
          setLoading(false);
          setIsMultipleAddresses(true);
        }
      })
      .catch(() => setLoading(false));
  };

  return (
    <>
      <Button variant='contained' disabled={!patientName} onClick={handleOpenModal}>
        New
      </Button>

      {isMultipleAddresses && !isEmpty(validatedAddresses) && (
        <Dialog
          open={isMultipleAddresses}
          maxWidth='sm'
          fullWidth
          aria-labelledby='AddPatient-ConfirmAddress-header'
        >
          <Typography variant='h6' sx={{ px: 3, pt: 2 }}>
            Confirm Address
          </Typography>

          <Form
            onSubmit={handleFinalConfirmAddress}
            render={({ values, handleSubmit }) => (
              <form noValidate onSubmit={handleSubmit}>
                <DialogContent>
                  <Typography sx={{ pb: 2 }}>
                    {validatedAddresses.length > 1
                      ? 'Multiple addresses found. Please choose one from the suggestions below.'
                      : 'Please confirm the address below.'}
                  </Typography>
                  <RadioGroupField
                    name='addressChoice'
                    id='AddPatient-AddressChoice-radioField'
                    label=''
                  >
                    <Stack direction='column' alignItems='flex-start' gap={2}>
                      {map(validatedAddresses, (address, index) => (
                        <FormControlLabel
                          key={`validateAddress-${index}`}
                          value={index}
                          control={<Radio sx={{ mr: 1 }} />}
                          sx={{ px: 1 }}
                          label={
                            <ListItemText
                              secondary={
                                <>
                                  <Typography>{address.line1}</Typography>
                                  {address?.line2 && <Typography>{address.line2}</Typography>}
                                  <Typography>
                                    {address.city} , {address.state}, {address.zip}
                                  </Typography>
                                </>
                              }
                            />
                          }
                        />
                      ))}
                    </Stack>
                  </RadioGroupField>
                </DialogContent>
                <DialogActions>
                  <Button variant='outlined' color='secondary' onClick={closeConfirmAddressDialog}>
                    Cancel
                  </Button>
                  <Button
                    variant='contained'
                    disabled={isEmpty(values?.addressChoice)}
                    onClick={handleSubmit}
                    type='submit'
                  >
                    Confirm
                  </Button>
                </DialogActions>
              </form>
            )}
          />
        </Dialog>
      )}

      <Dialog
        open={showModal}
        onClose={handleCloseModal}
        aria-describedby='PatientView-NewProviderButton-header'
        fullWidth
      >
        <DialogTitle id='PatientView-NewProviderButton-header'>Add Provider</DialogTitle>

        <Form
          onSubmit={handleAddProvider}
          initialValues={providerDataToSave}
          render={({ handleSubmit, invalid }) => (
            <form noValidate onSubmit={handleSubmit}>
              <DialogContent>
                <TextField
                  id='PatientView-NewProviderButton-firstName-input'
                  label='First Name'
                  name='firstName'
                  required
                  autoFocus
                />

                <TextField
                  id='PatientView-NewProviderButton-lastName-input'
                  label='Last Name'
                  name='lastName'
                  required
                />

                <TextField
                  id='PatientView-NewProviderButton-phoneNumber-input'
                  label='Phone Number'
                  name='phone'
                  required
                  validations={[validatePhoneNumber()]}
                />

                <TextField
                  id='PatientView-NewProviderButton-email-input'
                  label='Email'
                  name='email'
                  validations={[validateEmail()]}
                />

                <TextField
                  id='PatientView-NewProviderButton-npi-input'
                  label='NPI'
                  name='npi'
                  validations={[validateValueWithRegex('Must be 10 numbers', /^\d{10}$/)]}
                />

                <TextField
                  id='PatientView-NewProviderButton-fax-input'
                  label='Fax'
                  name='fax'
                  validations={[validatePhoneNumber('Invalid Fax Number')]}
                />

                <TextField
                  id='PatientView-NewProviderButton-addressLine1-input'
                  label='Address Line 1'
                  name='addressLine1'
                  required
                />

                <TextField
                  id='PatientView-NewProviderButton-addressLine2-input'
                  label='Address Line 2'
                  name='addressLine2'
                />

                <TextField
                  id='PatientView-NewProviderButton-city-input'
                  label='City'
                  name='city'
                  required
                />

                <SelectField
                  id='PatientView-NewProviderButton-state-dropdown'
                  label='State'
                  name='state'
                  required
                >
                  {map(usStateAbbreviation, ({ name, abbreviation }) => (
                    <MenuItem value={abbreviation} key={abbreviation}>
                      {name}
                    </MenuItem>
                  ))}
                </SelectField>

                <TextField
                  id='PatientView-NewProviderButton-zip-input'
                  label='Zip'
                  name='zip'
                  type='zip'
                  maxLength={10}
                  format={formatZipCode}
                  validations={[validateZipCode()]}
                  required
                />
              </DialogContent>

              <DialogActions>
                <Button variant='outlined' color='secondary' onClick={handleCloseModal}>
                  Cancel
                </Button>
                <LoadingButton
                  loading={loading}
                  variant='contained'
                  disabled={invalid}
                  type='submit'
                >
                  Save
                </LoadingButton>
              </DialogActions>
            </form>
          )}
        />
      </Dialog>
    </>
  );
};

NewProviderButton.propTypes = {
  mpi: PropTypes.string.isRequired,
};

export default NewProviderButton;
