import { useSelector } from 'react-redux';
import { Grid, List, ListItem, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { map, orderBy } from 'lodash';
import PropTypes from 'prop-types';
import { format } from 'date-fns';

const SurgeryTable = ({ mpi }) => {
  const theme = useTheme();
  const surgeries = useSelector(({ patient }) => patient[mpi]?.medicalHistory?.surgeries);

  if (!surgeries) {
    return (
      <Typography align='center' sx={{ fontWeight: '500' }}>
        Loading...
      </Typography>
    );
  }

  if (!surgeries.length) {
    return (
      <Typography align='center' sx={{ fontWeight: '500' }}>
        No Surgeries
      </Typography>
    );
  }

  return (
    <Grid container>
      <Grid
        container
        sx={{ borderBottom: `1px solid ${theme.palette.grey[400]}`, px: 2, pb: 1 }}
        gap={1}
      >
        <Grid item xs={12} sm={2}>
          <Typography sx={{ fontWeight: '500' }}>Date of Surgery</Typography>
        </Grid>
        <Grid item xs={12} sm={2}>
          <Typography sx={{ fontWeight: '500' }}>Surgery Name</Typography>
        </Grid>
        <Grid item xs={12} sm={7}>
          <Typography sx={{ fontWeight: '500' }}>Description</Typography>
        </Grid>
      </Grid>
      <List sx={{ width: '100%', py: 0 }}>
        {map(orderBy(surgeries, ['dateOfSurgery'], ['desc']), (surgery) => (
          <Grid
            component={ListItem}
            container
            sx={{
              borderBottom: `1px solid ${theme.palette.grey[200]}`,
              px: 2,
              py: 1,
              '&:hover': { bgcolor: theme.palette.grey[100] },
            }}
            key={surgery.patientSurgeryId}
            gap={1}
          >
            <Grid item xs={12} sm={2}>
              <Typography>{format(new Date(surgery.dateOfSurgery), 'MM/dd/yyyy')}</Typography>
            </Grid>
            <Grid item xs={12} sm={2}>
              <Typography sx={{ wordBreak: 'break-word' }}>{surgery.surgeryName}</Typography>
            </Grid>
            <Grid item xs={12} sm={7}>
              <Typography>{surgery.description}</Typography>
            </Grid>
          </Grid>
        ))}
      </List>
    </Grid>
  );
};

SurgeryTable.propTypes = {
  mpi: PropTypes.string.isRequired,
};

export default SurgeryTable;
