import {
  Chip,
  Dialog,
  Grid,
  IconButton,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { forwardRef, useEffect, useState } from 'react';
import { isEmpty, map } from 'lodash';
import LoadingModule from 'common/components/LoadingModule';
import { useTheme } from '@mui/material/styles';
import { loadBatchShippedItems } from 'store/thunks/ordersListThunk';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import orderFillType from 'common/constants/orderFillType';

const Transition = forwardRef((props, ref) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Slide direction='up' ref={ref} {...props} timeout={300} />
));

const headCells = [
  {
    id: 'orderId',
    name: 'Order Id',
  },
  {
    id: 'patientName',
    name: 'Patient Name',
  },
  {
    id: 'rxNumber',
    name: 'Rx Number',
  },
  {
    id: 'drugName',
    name: 'Drug Name',
  },
  {
    id: 'trackingNumber',
    name: 'Tracking Number',
  },
  {
    id: 'fillType',
    name: 'Fill Type',
  },
  {
    id: 'createdAt',
    name: 'Created At',
  },
];

const BatchShipmentDialog = ({ open, onClose, batchShipmentId }) => {
  const [batchShippedItems, setBatchShippedItems] = useState([]);
  const theme = useTheme();

  const closeDialog = () => {
    onClose();
    setBatchShippedItems([]);
  };

  useEffect(() => {
    if (batchShipmentId) {
      loadBatchShippedItems({ limit: 0, offset: 0, batchShipmentId }).then((shipments) => {
        setBatchShippedItems(shipments);
      });
    }
  }, [batchShipmentId]);

  return (
    <Dialog
      open={open}
      aria-describedby={`${batchShipmentId}-ViewBatchShipment-Header`}
      fullScreen
      TransitionComponent={Transition}
    >
      <Grid
        container
        sx={{ px: 2, p: 1, bgcolor: 'primary.light' }}
        justifyContent='space-between'
        alignItems='center'
      >
        <Typography component='h2' variant='h6' color='white'>
          {`Batch Shipment - ${batchShipmentId}`}
        </Typography>

        <IconButton onClick={closeDialog} aria-label='Close Dialog'>
          <CloseIcon style={{ color: 'white' }} />
        </IconButton>
      </Grid>
      <Grid container direction='column'>
        <Grid item>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {headCells.map((headCell) => (
                    <TableCell key={headCell.id} align='left'>
                      {headCell.name}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {map(batchShippedItems, (shippedItem) => (
                  <TableRow key={shippedItem.lineItemId} hover>
                    <TableCell align='left'>{shippedItem.orderId}</TableCell>
                    <TableCell align='left'>
                      <Link
                        to={`/patients/${shippedItem.patientId}`}
                        style={{ color: theme.palette.text.primary }}
                      >
                        {`${shippedItem.patientLastName},${shippedItem.patientFirstName}`}
                      </Link>
                    </TableCell>
                    <TableCell align='left'>{shippedItem.rxNumber}</TableCell>
                    <TableCell align='left'>{shippedItem.drugName}</TableCell>
                    <TableCell align='left'>{shippedItem?.trackingNumber ?? 'NA'}</TableCell>
                    <TableCell>
                      <Chip
                        sx={{
                          background:
                            shippedItem.fillType === 'REFILL'
                              ? theme.palette.info.dark
                              : theme.palette.success.dark,
                          color: theme.palette.primary.contrastText,
                          borderRadius: '5px',
                          height: '25px',
                          width: '70px',
                        }}
                        label={
                          <Typography sx={{ fontSize: '1.1em' }}>
                            {orderFillType[shippedItem.fillType]}
                          </Typography>
                        }
                      />
                    </TableCell>
                    <TableCell align='left'>
                      {format(new Date(shippedItem.createdAt), 'yyyy-mm-dd')}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        {isEmpty(batchShippedItems) && (
          <Grid item>
            <LoadingModule />
          </Grid>
        )}
      </Grid>
    </Dialog>
  );
};

BatchShipmentDialog.propTypes = {
  batchShipmentId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default BatchShipmentDialog;
