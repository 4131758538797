import { createSlice } from '@reduxjs/toolkit';
import { forEach } from 'lodash';

const initialState = {
  currentPage: 1,
  pages: {},
  count: 0,
  sortOrder: undefined,
  filter: {},
};

/* eslint no-param-reassign: ["error", { "ignorePropertyModificationsFor": ["state"] }] */
const batchShipmentListSlice = createSlice({
  name: 'batchShipmentList',
  initialState,
  reducers: {
    updateCurrentPage(state, action) {
      const { page } = action.payload;

      return { ...state, currentPage: page };
    },
    loadBatchShipmentToPage(state, action) {
      const { page, orders, count } = action.payload;

      return {
        ...state,
        count,
        pages: {
          ...state.pages,
          [page]: orders,
        },
      };
    },
    updateLineItemsToShipment(state, action) {
      const { page, shipmentLineItems, count, addressDirectoryId } = action.payload;

      const shipments = state.pages[page];
      forEach(shipments, (shipment) => {
        if (shipment.addressDirectoryId === addressDirectoryId) {
          shipment.lineItem = { count, shipmentLineItems };
        }
      });
    },
    updateBatchShipmentsSortBy(state, action) {
      const { sortOrder } = action.payload;

      state.pages = {}; // Cached data no longer valid, this is to reset it.
      state.count = 0;
      state.sortOrder = sortOrder;
    },
    updateBatchShipmentsFilter(state, action) {
      const { filter } = action.payload;

      state.pages = {}; // Cached data no longer valid, this is to reset it.
      state.count = 0;
      state.filter = filter;
    },
  },
});

export const batchShipmentListActions = batchShipmentListSlice.actions;

export default batchShipmentListSlice.reducer;
