import { FormControl, Grid, MenuItem, Select, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  listBatchShipmentByPage,
  listShippingOptions,
  updateBatchShipmentSortBy,
} from 'store/thunks/batchShipmentThunk';
import { SortOrder } from 'enums/common.enum';
import BatchShipmentsTable from './components/BatchShipmentsTable';
import BatchShipmentFilterButton from './components/BatchShipmentFilterButton';
import BatchShipmentFilterStatusbar from './components/BatchShipmentFilterStatusbar';

const BatchShipments = () => {
  const dispatch = useDispatch();
  const { sortOrder } = useSelector(({ batchShipment }) => batchShipment);

  useEffect(() => {
    document.title = 'BatchShipments - SmartOS';
  }, []);

  useEffect(() => {
    dispatch(listBatchShipmentByPage({ page: 1 }));
    dispatch(listShippingOptions());
  }, [dispatch]);

  const handleApplySortBy = (event) =>
    dispatch(
      updateBatchShipmentSortBy({
        sortBy: event.target.value === 'DEFAULT' ? undefined : event.target.value,
      })
    );

  return (
    <Grid container sx={{ px: 3, pt: 2, mb: 1 }} direction='column'>
      <Grid item container justifyContent='space-between'>
        <Typography component='h1' variant='h5'>
          Batch Shipments
        </Typography>
      </Grid>

      <Grid item sx={{ mt: 2 }}>
        <BatchShipmentFilterStatusbar />
      </Grid>

      <Grid item container alignItems='center' justifyContent='end'>
        <Typography component='h1' variant='h6'>
          Sort By :
        </Typography>

        <FormControl size='small' sx={{ minWidth: 175, bgcolor: 'white', ml: 1, mr: 1 }}>
          <Select
            label=''
            value={sortOrder || 'DEFAULT'}
            id='DrugFilterButton-status-dropdown'
            onChange={handleApplySortBy}
          >
            <MenuItem value='DEFAULT'>Earliest Needs By Date</MenuItem>
            <MenuItem value={SortOrder.DESCENDING}>Count High to Low</MenuItem>
            <MenuItem value={SortOrder.ASCENDING}>Count Low to High</MenuItem>
          </Select>
        </FormControl>
        <BatchShipmentFilterButton />
      </Grid>

      <Grid item sx={{ minHeight: 40, pb: 2, pt: 2 }} container alignItems='center'>
        <BatchShipmentsTable />
      </Grid>
    </Grid>
  );
};
export default BatchShipments;
