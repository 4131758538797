/* eslint-disable max-lines */
import { useEffect, useState } from 'react';
import InfoIcon from '@mui/icons-material/Info';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  ListItemText,
  MenuItem,
  Radio,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { isArray, isEmpty, map } from 'lodash';
import {
  validateEmailArray,
  validateMinLength,
  validateZipCode,
} from 'common/forms/formValidations';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { getPartners } from 'store/thunks/partnerThunks';
import { createPractice } from 'store/thunks/practiceThunks';
import { Form } from 'react-final-form';
import TextField from 'common/forms/TextField';
import SelectField from 'common/forms/SelectField';
import { formatZipCode } from 'common/utils';
import LoadingButton from '@mui/lab/LoadingButton';
import usStateAbbreviation from 'common/constants/usStateAbbreviation';
import AutoCompleteMultipleSelect from 'common/forms/AutoCompleteMultipleSelect';
import { validateAddress } from 'store/thunks/patientThunks';
import RadioGroupField from 'common/forms/RadioGroupField';

const AddPracticeButton = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [showModal, setShowModel] = useState(false);
  const partners = useSelector(({ auth, partner }) => partner[auth.currentTenantId]);
  const [practiceDataToSave, setPracticeDataToSave] = useState({});
  const [validatedAddresses, setValidatedAddresses] = useState([]);
  const [isMultipleAddresses, setIsMultipleAddresses] = useState(false);
  const [loading, setLoading] = useState(false);

  const closeConfirmAddressDialog = () => setIsMultipleAddresses(false);

  const handleFinalConfirmAddress = (selectedAddress) => {
    const address = validatedAddresses[selectedAddress.addressChoice];
    const formattedPracticeData = {
      ...practiceDataToSave,
      addressLine1: address.line1,
      addressLine2: address.line2,
      city: address.city,
      state: address.state,
      zip: address.zip,
    };

    setPracticeDataToSave(formattedPracticeData);
    setIsMultipleAddresses(false);

    setLoading(true);
    const practicePayload = {
      practiceInput: {
        practiceName: formattedPracticeData.practiceName,
        address: {
          line1: formattedPracticeData.addressLine1,
          line2: formattedPracticeData.addressLine2,
          city: formattedPracticeData.city,
          state: formattedPracticeData.state,
          zip: formattedPracticeData.zip,
        },
        contact: { emails: formattedPracticeData.contactEmails },
      },
      partnerUUID: formattedPracticeData.partner,
    };

    dispatch(createPractice({ practiceData: practicePayload })).then((response) => {
      if (response) {
        enqueueSnackbar('Practice profile created successfully', { variant: 'success' });
        setShowModel(false);
      }

      setLoading(false);
      setPracticeDataToSave({});
    });
  };

  const handleOpenModal = () => setShowModel(true);
  const handleCloseModal = (_, reason) => {
    /* istanbul ignore next */
    if (reason !== 'backdropClick') {
      setPracticeDataToSave({});
      setShowModel(false);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!partners) {
      dispatch(getPartners());
    }
  }, [dispatch, partners]);

  const handleAddPractice = (formData) => {
    setLoading(true);
    setPracticeDataToSave(formData);
    const { addressLine1, addressLine2, city, state, zip } = formData;
    const address = { line1: addressLine1, line2: addressLine2, city, state, zip };

    return dispatch(validateAddress({ input: address }))
      .then((result) => {
        if (isArray(result)) {
          setValidatedAddresses(result);
          setLoading(false);
          setIsMultipleAddresses(true);
        }
      })
      .catch(() => setLoading(false));
  };

  return (
    <>
      <Button
        sx={{ height: 33, mr: 2 }}
        variant='contained'
        color='primary'
        onClick={handleOpenModal}
      >
        Add New
      </Button>

      {isMultipleAddresses && !isEmpty(validatedAddresses) && (
        <Dialog
          open={isMultipleAddresses}
          maxWidth='sm'
          fullWidth
          aria-labelledby='AddPatient-ConfirmAddress-header'
        >
          <Typography variant='h6' sx={{ px: 3, pt: 2 }}>
            Confirm Address
          </Typography>

          <Form
            onSubmit={handleFinalConfirmAddress}
            render={({ values, handleSubmit }) => (
              <form noValidate onSubmit={handleSubmit}>
                <DialogContent>
                  <Typography sx={{ pb: 2 }}>
                    {validatedAddresses.length > 1
                      ? 'Multiple addresses found. Please choose one from the suggestions below.'
                      : 'Please confirm the address below.'}
                  </Typography>
                  <RadioGroupField
                    name='addressChoice'
                    id='AddPatient-AddressChoice-radioField'
                    label=''
                  >
                    <Stack direction='column' alignItems='flex-start' gap={2}>
                      {map(validatedAddresses, (address, index) => (
                        <FormControlLabel
                          key={`validateAddress-${index}`}
                          value={index}
                          control={<Radio sx={{ mr: 1 }} />}
                          sx={{ px: 1 }}
                          label={
                            <ListItemText
                              secondary={
                                <>
                                  <Typography>{address.line1}</Typography>
                                  {address?.line2 && <Typography>{address.line2}</Typography>}
                                  <Typography>
                                    {address.city} , {address.state}, {address.zip}
                                  </Typography>
                                </>
                              }
                            />
                          }
                        />
                      ))}
                    </Stack>
                  </RadioGroupField>
                </DialogContent>
                <DialogActions>
                  <Button variant='outlined' color='secondary' onClick={closeConfirmAddressDialog}>
                    Cancel
                  </Button>
                  <Button
                    variant='contained'
                    disabled={isEmpty(values?.addressChoice)}
                    onClick={handleSubmit}
                    type='submit'
                  >
                    Confirm
                  </Button>
                </DialogActions>
              </form>
            )}
          />
        </Dialog>
      )}

      <Dialog
        open={showModal}
        onClose={handleCloseModal}
        aria-describedby='PracticeManagement-AddPracticeButton-header'
        fullWidth
      >
        <DialogTitle id='PracticeManagement-AddPracticeButton-header'>Add Practice</DialogTitle>

        <Form
          onSubmit={handleAddPractice}
          initialValues={practiceDataToSave}
          render={({ handleSubmit, invalid }) => (
            <form noValidate onSubmit={handleSubmit}>
              <DialogContent>
                <SelectField
                  name='partner'
                  id='PracticeManagement-AddPracticeButton-gender-dropdown'
                  label='Select Partner'
                  required
                >
                  {map(partners, ({ partnerName, partnerUuid }) => (
                    <MenuItem value={partnerUuid} key={partnerUuid}>
                      {partnerName}
                    </MenuItem>
                  ))}
                </SelectField>

                <TextField
                  id='PracticeManagement-AddPracticeButton-practiceName-input'
                  label='Practice Name'
                  name='practiceName'
                  required
                  autoFocus
                  validations={[validateMinLength('Minimum of 3 characters', 3)]}
                />

                <TextField
                  id='PracticeManagement-AddPracticeButton-address1-input'
                  label='Address Line 1'
                  name='addressLine1'
                  required
                />

                <TextField
                  id='PracticeManagement-AddPracticeButton-address2-input'
                  label='Address Line 2'
                  name='addressLine2'
                />

                <TextField
                  id='PracticeManagement-AddPracticeButton-city-input'
                  label='City'
                  name='city'
                  required
                />

                <SelectField
                  id='PracticeManagement-AddPracticeButton-state-dropdown'
                  label='State'
                  name='state'
                  required
                >
                  {map(usStateAbbreviation, ({ name, abbreviation }) => (
                    <MenuItem value={abbreviation} key={abbreviation}>
                      {name}
                    </MenuItem>
                  ))}
                </SelectField>

                <TextField
                  id='PracticeManagement-AddPracticeButton-zip-input'
                  label='Zip'
                  name='zip'
                  type='zip'
                  required
                  validations={[validateZipCode()]}
                  format={formatZipCode}
                  maxLength={10}
                />

                <Grid container direction='row' justifyContent='flex-start' alignItems='center'>
                  <Grid item xl={11}>
                    <AutoCompleteMultipleSelect
                      name='contactEmails'
                      label='Emails'
                      id='Create-Drug-Profile-AssociatePresDescription-input'
                      freeSolo
                      validations={[validateEmailArray()]}
                      options={[]}
                      defaultValue={[]}
                    />
                  </Grid>

                  <Grid item xl={1} sx={{ textAlign: 'center' }}>
                    <Tooltip
                      title='Type and click enter to add multiple Emails'
                      arrow
                      placement='bottom-end'
                    >
                      <InfoIcon />
                    </Tooltip>
                  </Grid>
                </Grid>
              </DialogContent>

              <DialogActions>
                <Button variant='outlined' color='secondary' onClick={handleCloseModal}>
                  Cancel
                </Button>
                <LoadingButton
                  loading={loading}
                  variant='contained'
                  disabled={invalid}
                  type='submit'
                >
                  Save
                </LoadingButton>
              </DialogActions>
            </form>
          )}
        />
      </Dialog>
    </>
  );
};

export default AddPracticeButton;
