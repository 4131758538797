import { gql } from 'graphql-request';

export const listPracticesGql = gql`
  query listPractices(
    $limit: Int
    $offset: Int
    $filter: ListPracticesFilterParams
    $sortBy: ListPracticesSortByParams
  ) {
    listPractices(limit: $limit, offset: $offset, filter: $filter, sortBy: $sortBy) {
      count
      result {
        practiceName
        partnerName
        practiceId
        partnerId
        createdAt
        updatedAt
      }
    }
  }
`;

export const createPracticeGql = gql`
  mutation createPractice($practiceInput: CreatePracticeInput!, $partnerUUID: String!) {
    createPractice(practiceInput: $practiceInput, partnerUUID: $partnerUUID) {
      practiceId
      partnerUUID
      practiceName
      address {
        line1
        line2
        city
        state
        zip
      }
      contact {
        emails
      }
    }
  }
`;
