/* eslint-disable react/jsx-props-no-spreading */
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import {
  Chip,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  TextField,
} from '@mui/material';
import { Field, useForm } from 'react-final-form';
import PropTypes from 'prop-types';

import { composeValidations, validateRequired } from './formValidations';

const AutoCompleteMultipleSelect = ({
  name,
  label,
  id,
  disabled,
  required,
  options,
  freeSolo,
  loading,
  validations,
  defaultValue,
  disableClear,
}) => {
  const form = useForm();
  const [prefilledDescriptions, setPrefilledDescriptions] = useState([]);

  const validate = composeValidations([
    ...validations,
    ...(required ? [validateRequired('Required')] : []),
  ]);

  const onChange = (_, value) => {
    setPrefilledDescriptions(value);
    form.change(name, value);
  };

  const onBlur = () => {
    form.blur(name);
  };

  useEffect(() => {
    if (!isEmpty(defaultValue)) {
      setPrefilledDescriptions(defaultValue);
    }
  }, [defaultValue]);

  return (
    <>
      <InputLabel shrink htmlFor={id} required={required} sx={{ fontSize: '1.2em' }}>
        {label}
      </InputLabel>

      <Field
        name={name}
        id={id}
        validate={validate}
        render={({ meta }) => {
          const showError = !!meta.error && !!meta.touched;
          const displayedHelperText = (!!meta.touched && meta.error) || '';
          return (
            <FormControl error={showError} variant='standard' fullWidth>
              <Autocomplete
                required={required}
                disabled={disabled}
                multiple
                freeSolo={freeSolo}
                id={id}
                disableClearable={disableClear}
                onChange={onChange}
                onBlur={onBlur}
                options={options.map((option) => option)}
                value={prefilledDescriptions}
                renderTags={(value, getTagProps) => {
                  return value.map((option, index) => {
                    const { key, ...tagProps } = getTagProps({ index });
                    return (
                      <Chip
                        variant='outlined'
                        label={option}
                        key={key}
                        {...tagProps}
                        size='small'
                      />
                    );
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name={name}
                    size='small'
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {loading && <CircularProgress color='primary' size={20} />}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />

              <FormHelperText>{displayedHelperText}</FormHelperText>
            </FormControl>
          );
        }}
      />
    </>
  );
};

export default AutoCompleteMultipleSelect;

AutoCompleteMultipleSelect.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  validations: PropTypes.arrayOf(PropTypes.func),
  required: PropTypes.bool,
  freeSolo: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  defaultValue: PropTypes.arrayOf(PropTypes.string),
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  disableClear: PropTypes.bool,
};

AutoCompleteMultipleSelect.defaultProps = {
  loading: false,
  required: false,
  freeSolo: false,
  disabled: false,
  validations: [],
  defaultValue: [],
  disableClear: false,
};
