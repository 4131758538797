export default {
  PRODUCT_FULFILLED: 'PRODUCT_FULFILLED',
  PRODUCT_FULFILLMENT: 'PRODUCT_FULFILLMENT',
  PRESCRIPTION_VERIFICATION: 'PRESCRIPTION_VERIFICATION',
  CONTROLLED_SUBSTANCE_CHECK: 'CONTROLLED_SUBSTANCE_CHECK',
  PRESCRIPTION_CLARIFICATION_CHECK: 'PRESCRIPTION_CLARIFICATION_CHECK',
  CLAIM_PAY_CHECK: 'CLAIM_PAY_CHECK',
  CLAIM_PROFITABLE_CHECK: 'CLAIM_PROFITABLE_CHECK',
  RX_ENTRY: 'RX_ENTRY',
  WILL_NOT_FILL: 'WILL_NOT_FILL',
  RX_REQUEST: 'RX_REQUEST',
  PROVIDER_CONTACTED: 'PROVIDER_CONTACTED',
  RX_RECEIVED: 'RX_RECEIVED',
  RE_PROCESS: 'RE_PROCESS',
  CANCELED: 'CANCELED',
  SHIPPED: 'SHIPPED',
};
