import { LoadingButton } from '@mui/lab';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import TextField from 'common/forms/TextField';
import { useState } from 'react';
import { Form } from 'react-final-form';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { editOrderShipment } from 'store/thunks/patientThunks';
import { useSnackbar } from 'notistack';
import { updateProcessedExternalOrdersFilter } from 'store/thunks/workQueueThunks';

const EditShipmentDialog = ({
  open,
  onClose,
  orderShipmentId,
  mpi,
  spOrderId,
  trackingNumber,
  carrierCode,
  orderId,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const filter = useSelector(({ workQueue }) => workQueue.processedExternalOrders.filter ?? {});

  const shipment = !orderId
    ? {
        spOrderId,
        trackingNumber,
        carrierCode,
      }
    : { orderId };

  const [loading, setLoading] = useState(false);

  const handleCloseModal = (_, reason) => {
    if (reason !== 'backdropClick') {
      onClose();
    }
  };

  const handleEditShipment = (formData) => {
    setLoading(true);

    dispatch(
      editOrderShipment({
        input: { ...formData, ...(orderShipmentId && { orderShipmentId }) },
        mpi,
      })
    )
      .then((updatedShipment) => {
        if (updatedShipment) {
          enqueueSnackbar('Order shipment updated', { variant: 'success' });

          dispatch(updateProcessedExternalOrdersFilter({ filter }));
        }

        handleCloseModal();
      })
      .finally(() => setLoading(false));
  };

  return (
    <Dialog
      open={open}
      onClose={handleCloseModal}
      aria-describedby='Shipments-EditShipment-header'
      fullWidth
    >
      <DialogTitle id='Shipments-EditShipment-header'>
        {orderShipmentId
          ? `Edit Shipment - ${orderShipmentId}`
          : `Edit Shipment of Order - ${orderId}`}
      </DialogTitle>

      <Form
        initialValues={{ ...shipment }}
        onSubmit={handleEditShipment}
        render={({ handleSubmit, invalid }) => (
          <form noValidate onSubmit={handleSubmit}>
            <DialogContent>
              {orderId && (
                <TextField
                  id='Shipments-EditShipment-orderId-input'
                  label='Order Id'
                  name='orderId'
                  required
                />
              )}
              <TextField
                id='Shipments-EditShipment-nameOnAddress-input'
                label='Shipstation Order Id'
                name='spOrderId'
                required
                autoFocus
              />
              <TextField
                id='Shipments-EditShipment-addressLine1-input'
                label='Tracking Number'
                name='trackingNumber'
                required
              />
              <TextField
                id='Shipments-EditShipment-addressLine2-input'
                label='Carrier Code'
                name='carrierCode'
                required
              />
              {orderId && (
                <TextField
                  id='Shipments-EditShipment-order_shipment_id-input'
                  label='Order Shipment Id'
                  name='orderShipmentId'
                  required
                />
              )}
            </DialogContent>

            <DialogActions>
              <Button variant='outlined' color='secondary' onClick={handleCloseModal}>
                Cancel
              </Button>
              <LoadingButton loading={loading} variant='contained' disabled={invalid} type='submit'>
                Save
              </LoadingButton>
            </DialogActions>
          </form>
        )}
      />
    </Dialog>
  );
};

EditShipmentDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  orderShipmentId: PropTypes.string,
  mpi: PropTypes.string.isRequired,
  spOrderId: PropTypes.string,
  trackingNumber: PropTypes.string,
  carrierCode: PropTypes.string,
  orderId: PropTypes.string,
};

EditShipmentDialog.defaultProps = {
  orderShipmentId: '',
  orderId: '',
  spOrderId: '',
  trackingNumber: '',
  carrierCode: '',
};

export default EditShipmentDialog;
