/* eslint-disable import/prefer-default-export */
import { isNull, reduce } from 'lodash';
import { format } from 'date-fns';

import { transformPatientAddress } from 'api/patient/transform';
import graphqlClient from '../graphql';
import {
  transformListBatchShipments,
  transformListOrders,
  transformListOrdersForShipment,
  transformListPomOrders,
  transformOrderShipment,
  transformProcessedExternalOrders,
  transformShipmentLineItems,
} from './transform';
import {
  cancelLineItemGql,
  cancelOrderGql,
  createOrderShipmentGql,
  createShipmentForLineItemsGql,
  getBatchShipmentsGql,
  getOrdersGql,
  listBatchShipmentsGql,
  listOrdersForShipmentGql,
  listOrdersGql,
  listProcessedExternalOrdersGql,
  listShipmentLineItemsGql,
  listShippingOptionsGql,
  manageProductFulfillmentGql,
  orderNeedsReviewGql,
  prescriptionNeedsReviewGql,
  reverifyOrderGql,
  updateAddressOrderLineItemStatusGql,
  updateOrderAddressGql,
  verifyOrderGql,
} from './gql';

export const listOrdersApi = ({ page, sortBy, filter }) => {
  const transformedFilter = {
    ...filter,
    needsByDate: filter.needsByDate && format(new Date(filter.needsByDate), 'yyyy-MM-dd'),
  };

  return graphqlClient
    .request(listOrdersGql, {
      offset: (page - 1) * 25,
      sortBy,
      filter: reduce(
        transformedFilter,
        (result, value, key) => ({
          ...result,
          [key]: isNull(value) ? undefined : value,
        }),
        {}
      ),
    })
    .then(transformListOrders);
};

export const manageProductFulfillmentApi = ({ lineItemId, mpi, orderId, state, failureReason }) => {
  return graphqlClient
    .request(manageProductFulfillmentGql, { lineItemId, mpi, orderId, state, failureReason })
    .then(({ manageProductFulfillmentStatus }) => manageProductFulfillmentStatus);
};

export const verifyOrderApi = ({ orderId, mpi }) => {
  return graphqlClient
    .request(verifyOrderGql, { orderId, mpi })
    .then(({ verifyOrder }) => verifyOrder);
};

export const createOrderShipmentApi = ({ mpi, lineItemIds, shipmentType }) => {
  return graphqlClient
    .request(createOrderShipmentGql, { lineItemIds, mpi, shipmentType })
    .then(transformOrderShipment);
};

export const cancelOrderApi = ({ orderId, cancelReason, cancelNote }) => {
  return graphqlClient
    .request(cancelOrderGql, { orderId, cancelReason, cancelNote })
    .then(({ cancelOrder }) => cancelOrder);
};

export const cancelLineItemApi = ({ orderId, lineItemId, cancelReason, cancelNote }) => {
  return graphqlClient
    .request(cancelLineItemGql, { orderId, lineItemId, cancelReason, cancelNote })
    .then(({ cancelLineItem }) => cancelLineItem);
};

export const listOrdersForShipmentApi = ({ page, sortBy, filter }) => {
  const transformedFilter = {
    ...filter,
    needsByDate: filter.needsByDate && format(new Date(filter.needsByDate), 'yyyy-MM-dd'),
  };

  return graphqlClient
    .request(listOrdersForShipmentGql, {
      offset: (page - 1) * 25,
      sortBy,
      filter: reduce(
        transformedFilter,
        (result, value, key) => ({
          ...result,
          [key]: isNull(value) ? undefined : value,
        }),
        {}
      ),
    })
    .then(transformListOrdersForShipment);
};

export const orderNeedsReviewApi = ({ orderId, mpi }) =>
  graphqlClient
    .request(orderNeedsReviewGql, { orderId, mpi })
    .then(({ markOrderForExternalProcessing }) => markOrderForExternalProcessing);

export const updateOrderAddressApi = ({ orderId, mpi, addressInput }) =>
  graphqlClient
    .request(updateOrderAddressGql, { orderId, addressInput: { mpi, ...addressInput } })
    .then(({ updateOrderAddress }) => {
      // eslint-disable-next-line no-underscore-dangle
      if (updateOrderAddress?.__typename === 'Addresses') {
        return updateOrderAddress;
      }

      return transformPatientAddress(updateOrderAddress);
    });

export const prescriptionNeedsReviewAPI = ({ prescriptionUUIDs, patientId }) =>
  graphqlClient
    .request(prescriptionNeedsReviewGql, { prescriptionUUIDs, patientId })
    .then(({ prescriptionNeedsReview }) => prescriptionNeedsReview);

export const getOrdersApi = ({ page, sortBy, filter }) => {
  const transformedFilter = {
    ...filter,
    createdAt: filter.createdAt && format(new Date(filter.createdAt), 'yyyy-MM-dd'),
    updatedAt: filter.updatedAt && format(new Date(filter.updatedAt), 'yyyy-MM-dd'),
  };

  return graphqlClient
    .request(getOrdersGql, {
      offset: (page - 1) * 25,
      sortBy,
      filter: reduce(
        transformedFilter,
        (result, value, key) => ({ ...result, [key]: isNull(value) ? undefined : value }),
        {}
      ),
    })
    .then(transformListPomOrders);
};

export const reverifyOrderApi = ({ orderId, mpi }) => {
  return graphqlClient
    .request(reverifyOrderGql, { orderId, mpi })
    .then(({ reverifyOrder }) => reverifyOrder);
};

export const listProcessedExternalOrdersApi = ({ page, sortBy, filter }) => {
  const defaultItemsPerPage = Number(process.env.REACT_APP_DEFAULT_ITEMS_PER_PAGE);

  return graphqlClient
    .request(listProcessedExternalOrdersGql, {
      limit: defaultItemsPerPage,
      offset: (page - 1) * defaultItemsPerPage,
      sortBy,
      filter: reduce(
        filter,
        (result, value, key) => ({ ...result, [key]: isNull(value) ? undefined : value }),
        {}
      ),
    })
    .then(transformProcessedExternalOrders);
};

export const listBatchShipmentsApi = ({ page, sortOrder, filter }) => {
  const defaultItemsPerPage = Number(process.env.REACT_APP_DEFAULT_ITEMS_PER_PAGE);

  return graphqlClient
    .request(listBatchShipmentsGql, {
      limit: defaultItemsPerPage,
      offset: (page - 1) * defaultItemsPerPage,
      filter,
      sortOrder,
    })
    .then(transformListBatchShipments);
};

export const listShipmentLineItemsApi = ({ addressDirectoryId }) => {
  return graphqlClient
    .request(listShipmentLineItemsGql, {
      limit: 0,
      offset: 0,
      addressDirectoryId,
    })
    .then(transformShipmentLineItems);
};

export const updateAddressOrderLineItemStatusApi = ({ lineItemId, addressDirectoryId, status }) =>
  graphqlClient.request(updateAddressOrderLineItemStatusGql, {
    lineItemIds: [lineItemId],
    addressDirectoryId,
    status,
  });

export const createShipmentForLineItemsApi = ({
  lineItemIds,
  addressDirectoryId,
  nameOnAddress,
  shippingOption,
}) =>
  graphqlClient.request(createShipmentForLineItemsGql, {
    lineItemIds,
    addressDirectoryId,
    nameOnAddress,
    shippingOption,
  });

export const listShippingOptionsApi = () =>
  graphqlClient
    .request(listShippingOptionsGql)
    .then(({ listShippingOptions }) => listShippingOptions.shippingOptions);

export const getBatchShipmentsApi = ({ limit, offset, batchShipmentId }) =>
  graphqlClient
    .request(getBatchShipmentsGql, { limit, offset, batchShipmentId })
    .then(({ getBatchShipments }) => getBatchShipments);
