import { Button, Menu, MenuItem, Typography } from '@mui/material';
import { useState } from 'react';
import PropTypes from 'prop-types';
import CircleIcon from '@mui/icons-material/Circle';

import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';

import useRoles from 'common/hooks/useRoles';
import { PatientStatus } from 'enums/patient.enum';
import TriggerResetPasswordDialog from './TriggerPasswordResetDialog';
import CompleteProfileDialog from './CompleteProfileDialog';

const ManagePatientUsersButton = ({ mpi, patientStatus }) => {
  const hasAccess = useRoles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [showResetPasswordDialog, setShowResetPasswordDialog] = useState(false);
  const [showCompleteProfileDialog, setShowCompleteProfileDialog] = useState(false);

  const handleOpenManageUserMenu = (event) => setAnchorEl(event.currentTarget);
  const handleCloseManageUserMenu = () => setAnchorEl(null);
  const handleCloseResetPasswordDialog = () => setShowResetPasswordDialog(false);
  const handleOpenResetPasswordDialog = () => {
    setAnchorEl(null);
    setShowResetPasswordDialog(true);
  };
  const handleCloseCompleteProfileDialog = () => {
    setAnchorEl(null);
    setShowCompleteProfileDialog(!showCompleteProfileDialog);
  };

  return (
    <>
      <TriggerResetPasswordDialog
        open={showResetPasswordDialog}
        onClose={handleCloseResetPasswordDialog}
        mpi={mpi}
      />
      <CompleteProfileDialog
        open={showCompleteProfileDialog}
        onClose={handleCloseCompleteProfileDialog}
        mpi={mpi}
      />
      <Button
        variant='contained'
        color='primary'
        onClick={handleOpenManageUserMenu}
        size='small'
        sx={{ minWidth: '10px', marginLeft: '15px' }}
        aria-label='User account management'
      >
        <ManageAccountsIcon color='inherit' fontSize='small' />
      </Button>

      <Menu
        aria-label='Patient user settings options button'
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleCloseManageUserMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {patientStatus === PatientStatus.PROFILE_INCOMPLETE && (
          <MenuItem
            onClick={handleCloseCompleteProfileDialog}
            aria-label='Complete Profile Option'
            disabled={!hasAccess.completeProfile}
          >
            <Typography sx={{ paddingRight: '25px' }}>Complete Profile</Typography>
            <CircleIcon color='error' style={{ fontSize: '10px' }} />
          </MenuItem>
        )}
        <MenuItem
          onClick={handleOpenResetPasswordDialog}
          disabled={!hasAccess.triggerPasswordResetForPEUser}
        >
          Trigger Password Reset
        </MenuItem>
      </Menu>
    </>
  );
};

ManagePatientUsersButton.propTypes = {
  mpi: PropTypes.string.isRequired,
  patientStatus: PropTypes.string,
};

ManagePatientUsersButton.defaultProps = {
  patientStatus: null,
};

export default ManagePatientUsersButton;
