/* eslint-disable max-lines */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  ListItemText,
  MenuItem,
  Radio,
  Stack,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { Form } from 'react-final-form';
import { find, isArray, isEmpty, map, omit } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import LoadingButton from '@mui/lab/LoadingButton';
import PropTypes from 'prop-types';

import SelectField from 'common/forms/SelectField';
import TextField from 'common/forms/TextField';
import usStateAbbreviation from 'common/constants/usStateAbbreviation';
import { formatZipCode } from 'common/utils';
import { validateZipCode } from 'common/forms/formValidations';
import { updatePatientAddress, validateAddress } from 'store/thunks/patientThunks';
import { patientActions } from 'store/slices/patientSlice';
import RadioGroupField from 'common/forms/RadioGroupField';
import useSmsResetWarningDialog from './useSmsResetWarningDialog';

const EditPatientAddressDialog = ({ mpi, patientAddressId, open, onClose }) => {
  const { InfoDialog, showWarning } = useSmsResetWarningDialog();
  const dispatch = useDispatch();
  const patientInfo = useSelector(({ patient }) => patient[mpi]);
  const patientAddress = find(patientInfo?.addresses, { patientAddressId });
  const notificationPreference = patientInfo?.notificationPreferences;
  const [loading, setLoading] = useState(false);
  const [validatedAddresses, setValidatedAddresses] = useState([]);
  const [isMultipleAddresses, setIsMultipleAddresses] = useState(false);
  const [formAddress, setFormAddress] = useState({});

  const handleCloseModal = (_, reason) => {
    if (reason !== 'backdropClick') {
      onClose();
      setFormAddress({});
      setValidatedAddresses([]);
    }
  };

  const closeConfirmAddressDialog = () => setIsMultipleAddresses(false);

  const handleFinalConfirmAddress = (selectedAddress) => {
    const address = validatedAddresses[selectedAddress.addressChoice];
    const formattedAddress = {
      ...formAddress,
      addressLine1: address.line1,
      addressLine2: address.line2,
      city: address.city,
      state: address.state,
      zip: address.zip,
    };

    setFormAddress(formattedAddress);
    setIsMultipleAddresses(false);
    setLoading(true);

    return dispatch(updatePatientAddress({ mpi, address: formattedAddress })).then((success) => {
      if (success) {
        handleCloseModal();
      }

      setLoading(false);
    });
  };

  const dispatchEditPatientAddress = ({ address }) => {
    setFormAddress(address);
    setLoading(true);
    const { addressLine1, addressLine2, city, state, zip } = address;

    dispatch(
      validateAddress({ input: { line1: addressLine1, line2: addressLine2, city, state, zip } })
    )
      .then((result) => {
        if (isArray(result)) {
          setValidatedAddresses(result);
          setLoading(false);
          setIsMultipleAddresses(true);
        }

        return !!address;
      })
      .catch(() => setLoading(false));
  };

  const resetPhonePreference = () => {
    dispatch(
      patientActions.updatePatientNotificationPreference({
        phone: false,
        email: notificationPreference?.email,
        mpi,
      })
    );
  };

  const handleEditPatientAddress = (formData) => {
    const address = {
      ...omit(formData, 'address', 'createdTimestamp'),
      addressLine2: formData.addressLine2 || '',
    };

    const isDisallowedState = !find(usStateAbbreviation, {
      abbreviation: address.state,
    })?.isSMSAllowed;

    showWarning({
      warn: address.isDefault && isDisallowedState && notificationPreference?.phone,
      callback: () => dispatchEditPatientAddress({ address }),
      warnCallback: () => {
        resetPhonePreference();
        dispatchEditPatientAddress({ address });
      },
    });
  };

  return (
    <>
      <InfoDialog />

      {isMultipleAddresses && !isEmpty(validatedAddresses) && (
        <Dialog
          open={isMultipleAddresses}
          maxWidth='sm'
          fullWidth
          aria-labelledby='AddPatient-ConfirmAddress-header'
        >
          <Typography variant='h6' sx={{ px: 3, pt: 2 }}>
            Confirm Address
          </Typography>

          <Form
            onSubmit={handleFinalConfirmAddress}
            render={({ values, handleSubmit }) => (
              <form noValidate onSubmit={handleSubmit}>
                <DialogContent>
                  <Typography sx={{ pb: 2 }}>
                    {validatedAddresses.length > 1
                      ? 'Multiple addresses found. Please choose one from the suggestions below.'
                      : 'Please confirm the address below.'}
                  </Typography>
                  <RadioGroupField
                    name='addressChoice'
                    id='AddPatient-AddressChoice-radioField'
                    label=''
                  >
                    <Stack direction='column' alignItems='flex-start' gap={2}>
                      {map(validatedAddresses, (address, index) => (
                        <FormControlLabel
                          key={`validateAddress-${index}`}
                          value={index}
                          control={<Radio sx={{ mr: 1 }} />}
                          sx={{ px: 1 }}
                          label={
                            <ListItemText
                              secondary={
                                <>
                                  <Typography>{address.line1}</Typography>
                                  {address?.line2 && <Typography>{address.line2}</Typography>}
                                  <Typography>
                                    {address.city} , {address.state}, {address.zip}
                                  </Typography>
                                </>
                              }
                            />
                          }
                        />
                      ))}
                    </Stack>
                  </RadioGroupField>
                </DialogContent>
                <DialogActions>
                  <Button variant='outlined' color='secondary' onClick={closeConfirmAddressDialog}>
                    Cancel
                  </Button>
                  <Button
                    variant='contained'
                    disabled={isEmpty(values?.addressChoice)}
                    onClick={handleSubmit}
                    type='submit'
                  >
                    Confirm
                  </Button>
                </DialogActions>
              </form>
            )}
          />
        </Dialog>
      )}

      <Dialog
        open={open}
        onClose={handleCloseModal}
        aria-describedby='PatientView-EditPatientAddress-header'
        fullWidth
      >
        <DialogTitle id='PatientView-EditAddress-header'>Edit Address</DialogTitle>

        <Form
          initialValues={{ ...patientAddress, ...patientAddress?.address, ...formAddress }}
          onSubmit={handleEditPatientAddress}
          render={({ handleSubmit, invalid }) => (
            <form noValidate onSubmit={handleSubmit}>
              <DialogContent>
                <TextField
                  id='PatientView-EditPatientAddress-nameOnAddress-input'
                  label='Name On Address'
                  name='nameOnAddress'
                  required
                  autoFocus
                />
                <TextField
                  id='PatientView-EditPatientAddress-addressLine1-input'
                  label='Address Line 1'
                  name='addressLine1'
                  required
                />
                <TextField
                  id='PatientView-EditPatientAddress-addressLine2-input'
                  label='Address Line 2'
                  name='addressLine2'
                />
                <TextField
                  id='PatientView-EditPatientAddress-city-input'
                  label='City'
                  name='city'
                  required
                />

                <SelectField
                  id='PatientView-EditPatientAddress-state-dropdown'
                  label='State'
                  name='state'
                  required
                >
                  {map(usStateAbbreviation, ({ name, abbreviation }) => (
                    <MenuItem value={abbreviation} key={abbreviation}>
                      {name}
                    </MenuItem>
                  ))}
                </SelectField>
                <TextField
                  id='PatientView-EditPatientAddress-zip-input'
                  label='Zip'
                  name='zip'
                  type='zip'
                  required
                  maxLength={10}
                  format={formatZipCode}
                  validations={[validateZipCode()]}
                />
              </DialogContent>

              <DialogActions>
                <Button variant='outlined' color='secondary' onClick={handleCloseModal}>
                  Cancel
                </Button>
                <LoadingButton
                  loading={loading}
                  variant='contained'
                  disabled={invalid}
                  type='submit'
                >
                  Save
                </LoadingButton>
              </DialogActions>
            </form>
          )}
        />
      </Dialog>
    </>
  );
};

EditPatientAddressDialog.propTypes = {
  mpi: PropTypes.string.isRequired,
  patientAddressId: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default EditPatientAddressDialog;
