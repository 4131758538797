import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Tooltip,
  Typography,
} from '@mui/material';
import { lighten } from '@mui/material/styles';
import gender from 'common/constants/gender';
import { find } from 'lodash';
import { enqueueSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';

import { refillOrder, validatePrescriptionForRefill } from 'store/thunks/patientThunks';

const LabelValuePair = ({ label, value, alignItems }) => (
  <Grid item xs={12}>
    <Grid container sx={{ minHeight: 36, alignItems }}>
      <Grid item xs={3}>
        <Typography sx={{ color: (theme) => lighten(theme.palette.text.primary, 0.3) }}>
          {label}
        </Typography>
      </Grid>
      <Grid item xs={9}>
        <Typography>{value}</Typography>
      </Grid>
    </Grid>
  </Grid>
);

LabelValuePair.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  alignItems: PropTypes.string,
};

LabelValuePair.defaultProps = {
  alignItems: 'center',
};

const RefillButton = ({ prescriptionUUID, patientId, rxNumber }) => {
  const patientData = useSelector(({ patient }) => patient[patientId]);
  const prescription = find(
    patientData?.prescriptions,
    (prescriptionData) => prescriptionData.rxNumber === rxNumber
  );

  const dispatch = useDispatch();
  const [refillValidationResult, setRefillValidationResult] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [validationErrorMessage, setValidationErrorMessage] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [isCreateRefillLoading, setIsCreateRefillLoading] = useState(false);
  const [refillProcessed, setRefillProcessed] = useState(false);

  const handleCloseModal = () => setShowModal(false);
  const handleOpenModal = () => setShowModal(true);

  const handleRefillOrder = () => {
    setIsCreateRefillLoading(true);
    dispatch(refillOrder({ patientId, rxNumber }))
      .then((response) => {
        if (response) {
          setRefillProcessed(true);
          enqueueSnackbar('Created Order Refill!', { variant: 'success' });
        }
      })
      .finally(() => {
        handleCloseModal();
        setIsLoading(false);
        setIsCreateRefillLoading(false);
      });
  };

  useEffect(() => {
    setIsLoading(true);
    dispatch(validatePrescriptionForRefill({ prescriptionUUID })).then((response) => {
      setRefillValidationResult(response.result);
      setValidationErrorMessage(response.reasons.join('. '));

      setIsLoading(false);
    });
  }, [dispatch, prescriptionUUID]);

  return (
    <>
      <Dialog
        open={showModal}
        onClose={handleCloseModal}
        aria-describedby='Prescriptions-RefillOrderButton-header'
        fullWidth
      >
        <DialogTitle id='Prescriptions-RefillOrderButton-header'>Confirm Refill</DialogTitle>

        <DialogContent>
          <Typography variant='h6' component='h3'>
            Patient
          </Typography>
          <Grid container>
            <LabelValuePair label='First Name' value={patientData?.firstName} />
            <LabelValuePair label='Last Name' value={patientData?.lastName} />
            <LabelValuePair
              label='Date Of Birth'
              value={`${dayjs(patientData?.dob).format('MM/DD/YYYY')} (${dayjs().diff(
                patientData?.dob,
                'year'
              )}yo)`}
            />
            <LabelValuePair label='Gender' value={gender[patientData?.gender]} />
          </Grid>
          <Typography variant='h6' component='h3' sx={{ mt: 3 }}>
            Prescription
          </Typography>
          <Grid container>
            <LabelValuePair label='Drug Name' value={prescription.drug} />
            <LabelValuePair label='Strength' value={prescription.strength} />
            <LabelValuePair label='Quantity' value={prescription.quantityPrescribed} />
            <LabelValuePair label='Remaining Refills' value={prescription.refillsRemaining} />
            <LabelValuePair
              label='Last Fill Date'
              value={
                prescription.lastFillDate
                  ? dayjs(prescription.lastFillDate).format('MM/DD/YYYY')
                  : 'N/A'
              }
            />
            <LabelValuePair
              label='Sig'
              value={prescription?.sig || prescription?.prescriptionOverride?.sig?.sigText}
            />
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button variant='outlined' color='secondary' onClick={handleCloseModal}>
            Cancel
          </Button>

          <LoadingButton
            loading={isCreateRefillLoading}
            variant='contained'
            type='submit'
            onClick={handleRefillOrder}
          >
            Confirm
          </LoadingButton>
        </DialogActions>
      </Dialog>

      <Tooltip title={validationErrorMessage} placement='top' arrow>
        <span>
          <LoadingButton
            loading={isLoading}
            variant='contained'
            type='submit'
            disabled={refillValidationResult === 'FAILURE' || refillProcessed}
            sx={{ mr: 2 }}
            onClick={handleOpenModal}
          >
            Refill
          </LoadingButton>
        </span>
      </Tooltip>
    </>
  );
};

RefillButton.propTypes = {
  prescriptionUUID: PropTypes.string.isRequired,
  patientId: PropTypes.string.isRequired,
  rxNumber: PropTypes.string.isRequired,
};

export default RefillButton;
