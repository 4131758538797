/* eslint-disable max-lines */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { lighten, useTheme } from '@mui/material/styles';
import {
  compact,
  find,
  first,
  forEach,
  includes,
  isEmpty,
  isNil,
  map,
  slice,
  sortBy,
  uniq,
  uniqBy,
} from 'lodash';
import { Link } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { Form } from 'react-final-form';
import { enqueueSnackbar } from 'notistack';

import TextField from 'common/forms/TextField';
import {
  createShipmentForLineItems,
  listBatchShipmentByPage,
  listShipmentLineItems,
} from 'store/thunks/batchShipmentThunk';
import LoadingModule from 'common/components/LoadingModule';
import NothingFound from 'common/components/NothingFound';
import useInfoDialog from 'common/hooks/useInfoDialog';
import SelectField from 'common/forms/SelectField';

import { validateMinLength } from 'common/forms/formValidations';
import ShipmentItemOption from './BatchShipmentItemOption';

const headCells = [
  {
    id: 'drugName',
    label: 'Drug Name',
  },
  {
    id: 'rxNumber',
    label: 'Rx Number',
  },
  {
    id: 'orderId',
    label: 'Order Id',
  },
  {
    id: 'itemStatus',
    label: 'Item Status',
  },
  {
    id: 'shippingOption',
    label: 'Shipping Option',
  },
  {
    id: 'needsByDate',
    label: 'Needs By Date',
  },
  {
    id: 'patientName',
    label: 'Patient Name',
  },
];

const BatchShipmentsTable = () => {
  const dispatch = useDispatch();
  const { InfoDialog } = useInfoDialog();

  const itemsPerBox = Number(process.env.REACT_APP_BATCH_SHIPMENT_DEFAULT_SELECT) || 20;

  const theme = useTheme();
  const { pages, currentPage, count } = useSelector(({ batchShipment }) => batchShipment);
  const { shippingOptions } = useSelector(({ systemConfigs }) => systemConfigs);

  const totalPages = Math.ceil(count / 25);
  const batchShipments = pages[currentPage];

  const [expandedPanelId, setExpandedPanelId] = useState(null);
  const [selectedForShipment, setSelectedForShipment] = useState([]);
  const [remainingItems, setRemainingItems] = useState([]);
  const [addToBoxItems, setAddToBoxItems] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const shipmentData = find(
    batchShipments,
    (shipment) => shipment?.addressDirectoryId === expandedPanelId
  );

  useMemo(() => {
    if (expandedPanelId && isEmpty(shipmentData?.lineItem?.shipmentLineItems)) {
      dispatch(listShipmentLineItems({ addressDirectoryId: expandedPanelId, currentPage }));
    }
  }, [dispatch, expandedPanelId, currentPage, shipmentData]);

  const handleChange = (panel) => (_, isExpanded) => {
    if (panel !== expandedPanelId) {
      setExpandedPanelId(isExpanded ? panel : null);
    }
  };

  useEffect(() => {
    if (!isEmpty(batchShipments) && !expandedPanelId) {
      const firstPanelId = first(batchShipments)?.addressDirectoryId;
      setExpandedPanelId(firstPanelId);
    }
  }, [batchShipments, expandedPanelId]);

  const handlePageChange = (_, page) => {
    if (currentPage === page) {
      return;
    }

    dispatch(listBatchShipmentByPage({ page }));
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    if (!isEmpty(batchShipments)) {
      const shipmentLineItems = shipmentData?.lineItem?.shipmentLineItems || [];

      const readyItems = shipmentLineItems.filter(
        (item) => item.lineItemStatus === 'READY_FOR_SHIPMENT'
      );
      const otherItems = shipmentLineItems.filter(
        (item) => item.lineItemStatus !== 'READY_FOR_SHIPMENT'
      );

      const sortedItems = [...readyItems, ...otherItems];
      setSelectedForShipment(slice(sortedItems, 0, itemsPerBox));
      setRemainingItems(slice(sortedItems, itemsPerBox));
    }
  }, [batchShipments, shipmentData, itemsPerBox]);

  const handleSelectedCheckboxChanged = (event) => {
    const uncheckedLineItemId = event.target.id;

    const lineItem = selectedForShipment.find(
      ({ lineItemId }) => lineItemId === uncheckedLineItemId
    );
    const updatedSelectedItems = selectedForShipment.filter(
      ({ lineItemId }) => lineItemId !== uncheckedLineItemId
    );
    remainingItems.push(lineItem);

    setRemainingItems(uniqBy(remainingItems, 'lineItemId'));
    setSelectedForShipment(uniqBy(updatedSelectedItems, 'lineItemId'));
  };

  const handleRemainingCheckBoxChanged = (event) => {
    const checkedLineItemId = event.target.id;
    const isChecked = event.target.checked;

    setAddToBoxItems((prevItems) => {
      if (isChecked) {
        const lineItem = remainingItems.find(({ lineItemId }) => lineItemId === checkedLineItemId);
        return uniqBy([...prevItems, lineItem], 'lineItemId');
      }

      return prevItems.filter(({ lineItemId }) => lineItemId !== checkedLineItemId);
    });
  };
  const handleSendToBox = () => {
    const updatedRemainingItems = remainingItems.filter(
      (remainingItem) =>
        !addToBoxItems.some(
          (toBoxLineItem) => toBoxLineItem.lineItemId === remainingItem.lineItemId
        )
    );

    forEach(addToBoxItems, (item) => {
      selectedForShipment.push(item);
    });

    setRemainingItems(uniqBy(compact(updatedRemainingItems), 'lineItemId'));
    setSelectedForShipment(uniqBy(selectedForShipment, 'lineItemId'));
    setAddToBoxItems([]);
  };

  const handleRemainingSelectAllChanged = (_, isChecked) =>
    setAddToBoxItems(isChecked ? remainingItems : []);

  const determineRemainingSelectAll = () => {
    return (
      remainingItems.some((item) =>
        addToBoxItems.some((boxItem) => boxItem.lineItemId === item.lineItemId)
      ) &&
      !remainingItems.every((item) =>
        addToBoxItems.some((boxItem) => boxItem.lineItemId === item.lineItemId)
      )
    );
  };

  const handleConfirm = (formData) => {
    setLoading(true);

    const itemsReadyForShipment = selectedForShipment.filter(
      (item) => item.lineItemStatus === 'READY_FOR_SHIPMENT'
    );

    dispatch(
      createShipmentForLineItems({
        addressDirectoryId: expandedPanelId,
        lineItemIds: map(itemsReadyForShipment, 'lineItemId'),
        nameOnAddress: formData.nameOnAddress,
        shippingOption: formData.shippingOption,
      })
    )
      .then((response) => {
        if (response) {
          enqueueSnackbar('Created Batch shipment', { variant: 'success' });
          setShowModal(false);
          setLoading(false);
        }
      })
      .finally(() => setLoading(false));
  };

  const handleCloseModal = (_, reason) => {
    /* istanbul ignore next */
    if (reason !== 'backdropClick') {
      setShowModal(false);
    }
  };

  const handleOpenModal = () => setShowModal(true);

  const getNameOnAddress = () => {
    const itemsReadyForShipment = selectedForShipment.filter(
      (item) => item.lineItemStatus === 'READY_FOR_SHIPMENT'
    );

    const isSamePatientShipment = uniq(map(itemsReadyForShipment, 'patient.mpi')).length === 1;

    return isSamePatientShipment
      ? `${first(itemsReadyForShipment)?.patient.lastName}, ${
          first(itemsReadyForShipment)?.patient.firstName
        }`
      : '';
  };

  const getShippingOption = () => {
    const shipmentDetails = find(
      batchShipments,
      (shipment) => shipment?.addressDirectoryId === expandedPanelId
    );

    const findMostFrequentString = (arr) => {
      const countMap = arr.reduce((acc, str) => {
        acc[str] = (acc[str] || 0) + 1;
        return acc;
      }, {});

      return arr.sort((a, b) => countMap[b] - countMap[a])[0];
    };

    const shippingOptionsVal = map(shipmentDetails?.lineItem?.shipmentLineItems, 'shippingOption');
    const option = findMostFrequentString(compact(shippingOptionsVal));

    return shippingOptions?.find((shippingOptionObj) => shippingOptionObj.optionCode === option)
      ?.optionCode;
  };

  return (
    <>
      <InfoDialog />

      <Dialog open={showModal} aria-describedby='BatchShipment-createShipment-Header' fullWidth>
        <DialogTitle id='BatchShipment-createShipment-Header' sx={{ pb: 0 }}>
          Confirm Batch Shipment
        </DialogTitle>

        <Form
          onSubmit={handleConfirm}
          initialValues={{ nameOnAddress: getNameOnAddress(), shippingOption: getShippingOption() }}
          render={({ handleSubmit, invalid }) => (
            <form noValidate onSubmit={handleSubmit}>
              <DialogContent>
                <Grid>
                  <Typography variant='body2' sx={{ pb: 2 }}>
                    Please note that items that are not ready for shipment will not be included in
                    the shipment.
                  </Typography>
                </Grid>
                <TextField
                  id='ConfirmShipment-nameOnAddress-input'
                  label='Name On Address'
                  name='nameOnAddress'
                  required
                  autoFocus
                  validations={[validateMinLength('Minimum of 3 characters', 3)]}
                />

                <SelectField
                  id='ConfirmShipment-shippingOptions-dropdown'
                  label='Shipping Option'
                  name='shippingOption'
                  required
                >
                  {map(shippingOptions, (shippingOption, key) => (
                    <MenuItem value={shippingOption.optionCode} key={key}>
                      {shippingOption.shippingOption}
                    </MenuItem>
                  ))}
                </SelectField>
              </DialogContent>

              <DialogActions>
                <Button variant='outlined' color='secondary' onClick={handleCloseModal}>
                  Cancel
                </Button>
                <LoadingButton
                  loading={loading}
                  variant='contained'
                  disabled={invalid}
                  type='submit'
                >
                  Confirm
                </LoadingButton>
              </DialogActions>
            </form>
          )}
        />
      </Dialog>

      <Box
        sx={{
          bgcolor: theme.palette.background.paper,
          minHeight: '100vh',
          width: '100%',
        }}
      >
        <Grid sx={{ py: 2, px: 2 }} container direction='column'>
          {map(batchShipments, (batchShipment) => {
            const shipmentLineItem = batchShipment?.lineItem;
            const shipmentLineItems = shipmentLineItem?.shipmentLineItems;

            const isSomeLineItemsReady = !selectedForShipment.some(
              (item) => item.lineItemStatus === 'READY_FOR_SHIPMENT'
            );

            return (
              <Accordion
                sx={{ marginTop: '10px' }}
                key={batchShipment.addressDirectoryId}
                expanded={expandedPanelId === batchShipment.addressDirectoryId}
                onChange={handleChange(batchShipment.addressDirectoryId)}
              >
                <AccordionSummary
                  sx={{ bgcolor: lighten(theme.palette.text.primary, 0.9) }}
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Grid container justifyContent='space-between' alignItems='center'>
                    <Stack direction='row' alignItems='center' spacing={1}>
                      <Typography variant='h6' fontWeight='bold'>
                        {compact([
                          batchShipment.address.addressLine1,
                          batchShipment.address.addressLine2,
                          batchShipment.address.addressCity,
                          batchShipment.address.addressState,
                          batchShipment.address.addressZip,
                        ])
                          .filter(Boolean)
                          .join(', ')}
                      </Typography>
                    </Stack>
                    <Stack sx={{ pr: 2 }}>
                      <Chip
                        sx={{
                          background: theme.palette.secondary.light,
                          color: theme.palette.primary.contrastText,
                          maxHeight: '80%',
                        }}
                        label={
                          <Typography variant='body2'>
                            Items in Shipment: {batchShipment.totalItems}
                          </Typography>
                        }
                      />
                    </Stack>
                  </Grid>
                </AccordionSummary>

                {isNil(shipmentLineItems) && (
                  <AccordionDetails>
                    <Grid sx={{ my: 1 }} container direction='column'>
                      <LoadingModule />
                    </Grid>
                  </AccordionDetails>
                )}

                {!isNil(shipmentLineItems) && isEmpty(shipmentLineItems) && (
                  <AccordionDetails>
                    <Grid sx={{ my: 1 }} container direction='column'>
                      <NothingFound />
                    </Grid>
                  </AccordionDetails>
                )}

                {!isEmpty(shipmentLineItems) && (
                  <AccordionDetails>
                    <Grid
                      sx={{ my: 1, p: 2, border: '1px solid black' }}
                      container
                      direction='column'
                    >
                      <Grid item>
                        <TableContainer>
                          <Chip
                            sx={{
                              background: theme.palette.secondary.light,
                              color: theme.palette.primary.contrastText,
                              maxHeight: '80%',
                              height: '30px',
                              borderRadius: '3px',
                            }}
                            label={
                              <Typography variant='h7'>Box Capacity: {itemsPerBox}</Typography>
                            }
                          />
                          <Table size='small' sx={{ tableLayout: 'fixed' }}>
                            <TableHead>
                              <TableRow>
                                {headCells.map((headCell) => (
                                  <TableCell key={headCell.id} align='center'>
                                    <strong>{headCell.label}</strong>
                                  </TableCell>
                                ))}
                                <TableCell />
                                <TableCell>
                                  <Tooltip
                                    arrow
                                    placement='top'
                                    title={`${
                                      isSomeLineItemsReady ? 'Items not ready for shipment' : ''
                                    }`}
                                  >
                                    <span>
                                      <Button
                                        variant='contained'
                                        disabled={
                                          isEmpty(selectedForShipment) || isSomeLineItemsReady
                                        }
                                        onClick={handleOpenModal}
                                      >
                                        Send to Shipment
                                      </Button>
                                    </span>
                                  </Tooltip>
                                </TableCell>
                              </TableRow>
                            </TableHead>

                            <TableBody>
                              {map(sortBy(selectedForShipment, 'needsByDate'), (item) => {
                                return (
                                  <TableRow
                                    key={`${item.lineItemId}`}
                                    sx={{
                                      backgroundColor: lighten(theme.palette.primary.light, 0.8),
                                    }}
                                  >
                                    <TableCell sx={{ paddingY: '12px' }} align='center'>
                                      {item.drugName}
                                    </TableCell>
                                    <TableCell sx={{ paddingY: '12px' }} align='center'>
                                      {item.rxNumber}
                                    </TableCell>
                                    <TableCell align='center'>{item.orderId}</TableCell>
                                    <TableCell align='center'>
                                      {item.lineItemStatus.replace(/_/g, ' ')}
                                    </TableCell>
                                    <TableCell sx={{ paddingY: '12px' }} align='center'>
                                      {find(
                                        shippingOptions,
                                        (option) => option.optionCode === item.shippingOption
                                      )?.shippingOption || 'N/A'}
                                    </TableCell>
                                    <TableCell sx={{ paddingY: '12px' }} align='center'>
                                      {item.needsByDate}
                                    </TableCell>
                                    <TableCell sx={{ paddingY: '12px' }} align='center'>
                                      <Link
                                        to={`/patients/${item.patient.mpi}`}
                                        style={{ color: theme.palette.text.primary }}
                                      >
                                        {`${item.patient.lastName}, ${item.patient.firstName} `}
                                      </Link>
                                    </TableCell>
                                    <TableCell align='center'>
                                      <Tooltip
                                        arrow
                                        placement='right'
                                        title={`${
                                          item.lineItemStatus !== 'READY_FOR_SHIPMENT'
                                            ? 'Item not ready for shipment'
                                            : ''
                                        }`}
                                      >
                                        <span>
                                          <Checkbox
                                            checked={map(
                                              selectedForShipment,
                                              'lineItemId'
                                            ).includes(item.lineItemId)}
                                            id={item.lineItemId}
                                            onChange={handleSelectedCheckboxChanged}
                                            disabled={
                                              selectedForShipment.length <= 1 ||
                                              item.lineItemStatus !== 'READY_FOR_SHIPMENT'
                                            }
                                          />
                                        </span>
                                      </Tooltip>
                                    </TableCell>
                                    <TableCell align='center'>
                                      <ShipmentItemOption
                                        addressDirectoryId={batchShipment.addressDirectoryId}
                                        lineItemId={item.lineItemId}
                                      />
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                    </Grid>

                    <Grid sx={{ my: 1, mt: 4 }} container direction='column'>
                      <Grid item>
                        <TableContainer>
                          <Table size='small' sx={{ tableLayout: 'fixed' }}>
                            <TableHead>
                              <TableRow>
                                {headCells.map((headCell) => (
                                  <TableCell key={headCell.id} align='center'>
                                    <strong>{headCell.label}</strong>
                                  </TableCell>
                                ))}
                                <TableCell align='center'>
                                  <Checkbox
                                    indeterminate={determineRemainingSelectAll()}
                                    checked={
                                      remainingItems.length > 0 &&
                                      remainingItems.every((item) =>
                                        addToBoxItems.some(
                                          (boxItem) => boxItem.lineItemId === item.lineItemId
                                        )
                                      )
                                    }
                                    onChange={handleRemainingSelectAllChanged}
                                  />
                                </TableCell>
                                <TableCell align='center'>
                                  <Tooltip
                                    arrow
                                    placement='top'
                                    title={`${
                                      selectedForShipment.length === itemsPerBox ||
                                      selectedForShipment.length + addToBoxItems.length >
                                        itemsPerBox
                                        ? 'Maximum items added(selected) to box for shipment'
                                        : ''
                                    }`}
                                  >
                                    <span>
                                      <Button
                                        variant='contained'
                                        sx={{ mr: 2 }}
                                        disabled={
                                          isEmpty(addToBoxItems) ||
                                          selectedForShipment.length === itemsPerBox ||
                                          selectedForShipment.length + addToBoxItems.length >
                                            itemsPerBox
                                        }
                                        onClick={handleSendToBox}
                                      >
                                        Send to Box
                                      </Button>
                                    </span>
                                  </Tooltip>
                                </TableCell>
                              </TableRow>
                            </TableHead>

                            <TableBody>
                              {map(sortBy(remainingItems, 'needsByDate'), (item) => (
                                <TableRow key={`${item.lineItemId}`} hover>
                                  <TableCell sx={{ paddingY: '12px' }} align='center'>
                                    {item.drugName}
                                  </TableCell>
                                  <TableCell sx={{ paddingY: '12px' }} align='center'>
                                    {item.rxNumber}
                                  </TableCell>
                                  <TableCell align='center'>{item.orderId}</TableCell>
                                  <TableCell align='center'>
                                    {item.lineItemStatus.replace(/_/g, ' ')}
                                  </TableCell>
                                  <TableCell align='center'>
                                    {find(
                                      shippingOptions,
                                      (option) => option.optionCode === item.shippingOption
                                    )?.shippingOption || 'N/A'}
                                  </TableCell>
                                  <TableCell sx={{ paddingY: '12px' }} align='center'>
                                    {item.needsByDate}
                                  </TableCell>
                                  <TableCell sx={{ paddingY: '12px' }} align='center'>
                                    <Link
                                      to={`/patients/${item.patient.mpi}`}
                                      style={{ color: theme.palette.text.primary }}
                                    >
                                      {`${item.patient.lastName}, ${item.patient.firstName} `}
                                    </Link>
                                  </TableCell>
                                  <TableCell align='center'>
                                    <Tooltip
                                      arrow
                                      placement='right'
                                      title={`${
                                        item.lineItemStatus !== 'READY_FOR_SHIPMENT'
                                          ? 'Item not ready for shipment'
                                          : ''
                                      }`}
                                    >
                                      <span>
                                        <Checkbox
                                          checked={includes(
                                            map(addToBoxItems, 'lineItemId'),
                                            item.lineItemId
                                          )}
                                          onChange={handleRemainingCheckBoxChanged}
                                          id={item.lineItemId}
                                          disabled={item.lineItemStatus !== 'READY_FOR_SHIPMENT'}
                                        />
                                      </span>
                                    </Tooltip>
                                  </TableCell>
                                  <TableCell align='center'>
                                    <ShipmentItemOption
                                      addressDirectoryId={batchShipment.addressDirectoryId}
                                      lineItemId={item.lineItemId}
                                    />
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                )}
              </Accordion>
            );
          })}

          {!isNil(batchShipments) && totalPages > 1 && (
            <TableFooter
              sx={{ justifyContent: 'flex-end', alignItems: 'flex-end', display: 'flex' }}
            >
              <TableRow>
                <TableCell colSpan={8} align='right' sx={{ borderBottom: 'none' }}>
                  <Pagination
                    sx={{
                      justifyContent: 'flex-end',
                      alignItems: 'flex-end',
                      display: 'flex',
                    }}
                    count={totalPages}
                    page={currentPage}
                    size='small'
                    onChange={handlePageChange}
                  />
                </TableCell>
              </TableRow>
            </TableFooter>
          )}

          {isNil(batchShipments) && (
            <Grid item>
              <LoadingModule />
            </Grid>
          )}

          {!isNil(batchShipments) && isEmpty(batchShipments) && (
            <Grid item>
              <NothingFound />
            </Grid>
          )}
        </Grid>
      </Box>
    </>
  );
};

export default BatchShipmentsTable;
