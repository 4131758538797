/* eslint-disable max-lines */
import {
  Box,
  Chip,
  Grid,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
} from '@mui/material';
import { capitalize, find, isEmpty, isNil, map, toLower } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import visuallyHidden from '@mui/utils/visuallyHidden';
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import ShippingIcon from '@mui/icons-material/LocalShipping';

import {
  getPrescriptionsNeedsReviewByPage,
  sortPrescriptionsNeedsReview,
} from 'store/thunks/workQueueThunks';
import LoadingModule from 'common/components/LoadingModule';
import NothingFound from 'common/components/NothingFound';
import { SortKey, SortOrder } from 'enums/common.enum';
import { prescriptionReviewStatus } from 'common/constants/prescriptionStatus';

import orderFillType from 'common/constants/orderFillType';
import PrescriptionReviewOptions from './PrescriptionReviewOptions';

const headCells = [
  {
    id: SortKey.ORDER_ID,
    label: 'Order Id',
    sortable: true,
  },
  {
    id: SortKey.STATUS,
    label: 'Status',
    sortable: false,
  },
  {
    id: SortKey.DRUG_NAME,
    label: 'Drug Name',
    sortable: true,
  },
  {
    id: SortKey.PATIENT_NAME,
    label: 'Patient Name',
    sortable: true,
  },
  {
    id: SortKey.RX_NUMBER,
    label: 'Rx Number',
    sortable: true,
  },
  {
    id: SortKey.FILL_TYPE,
    label: 'Fill Type',
    sortable: true,
  },
  {
    id: 'shippingOption',
    label: 'Shipping Option',
    sortable: false,
  },
  {
    id: SortKey.REQUESTED_DATE,
    label: 'Requested Date',
    sortable: true,
  },
  {
    id: SortKey.NEEDS_BY_DATE,
    label: 'Needs By Date',
    sortable: true,
  },
];

const PrescriptionReviewTable = () => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const { sortBy, pages, currentPage } = useSelector(
    ({ workQueue }) => workQueue.prescriptionReview
  );

  const shippingOptions = useSelector(({ systemConfigs }) => systemConfigs.shippingOptions || []);

  const totalPages = useSelector(({ workQueue }) =>
    Math.ceil(workQueue.prescriptionReview.count / 25)
  );
  const prescriptions = pages[currentPage];

  const handleSort = (event) => {
    const sortKey = event.currentTarget?.dataset?.headProperty;

    dispatch(
      sortPrescriptionsNeedsReview({
        sortBy: {
          sortKey,
          sortOrder:
            sortBy?.sortKey === sortKey && sortBy?.sortOrder === SortOrder.ASCENDING
              ? SortOrder.DESCENDING
              : SortOrder.ASCENDING,
        },
      })
    );
  };

  const handlePageChange = (_, page) => {
    if (currentPage === page) {
      // page didnt change, don't do anything.
      return;
    }

    dispatch(getPrescriptionsNeedsReviewByPage({ page }));

    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    dispatch(getPrescriptionsNeedsReviewByPage({ page: 1 }));
  }, [dispatch]);

  return (
    <Grid container direction='column'>
      <Grid item>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {headCells.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    sortDirection={
                      sortBy.sortKey === headCell.id ? toLower(sortBy.sortOrder) : false
                    }
                  >
                    {headCell.sortable ? (
                      <TableSortLabel
                        active={sortBy.sortKey === headCell.id}
                        direction={
                          sortBy.sortKey === headCell.id ? toLower(sortBy.sortOrder) : 'asc'
                        }
                        onClick={handleSort}
                        data-head-property={headCell.id}
                      >
                        {headCell.label}
                        {sortBy.sortKey === headCell.id ? (
                          <Box component='span' sx={visuallyHidden}>
                            {toLower(sortBy.sortOrder) === SortOrder.DESCENDING
                              ? 'sorted descending'
                              : 'sorted ascending'}
                          </Box>
                        ) : null}
                      </TableSortLabel>
                    ) : (
                      headCell.label
                    )}
                  </TableCell>
                ))}
                <TableCell />
                <TableCell />
              </TableRow>
            </TableHead>

            <TableBody>
              {map(prescriptions, (prescription) => (
                <TableRow key={prescription.pomPrescriptionId} hover>
                  <TableCell>{prescription.orderId || 'N/A'}</TableCell>
                  <TableCell>
                    {prescriptionReviewStatus[prescription.status] ||
                      capitalize(toLower(prescription.status))}
                  </TableCell>
                  <TableCell>{prescription.drugName}</TableCell>
                  <TableCell component='th' scope='row'>
                    <Link
                      to={`/patients/${prescription.patient.mpi}`}
                      style={{ color: theme.palette.text.primary }}
                    >
                      {prescription.patient.patientName}
                    </Link>
                  </TableCell>
                  <TableCell>{prescription.rxNumber}</TableCell>
                  <TableCell>
                    <Chip
                      sx={{
                        background:
                          prescription.fillType === 'REFILL'
                            ? theme.palette.info.dark
                            : theme.palette.success.dark,
                        color: theme.palette.primary.contrastText,
                        borderRadius: '5px',
                        height: '25px',
                        width: '70px',
                      }}
                      label={
                        <Typography sx={{ fontSize: '1.1em' }}>
                          {orderFillType[prescription.fillType]}
                        </Typography>
                      }
                    />
                  </TableCell>
                  <TableCell>
                    {prescription.shippingOption
                      ? find(
                          shippingOptions,
                          (option) => option.optionCode === prescription.shippingOption
                        )?.shippingOption || prescription.shippingOption
                      : 'NA'}
                  </TableCell>
                  <TableCell>
                    {format(
                      utcToZonedTime(new Date(prescription.requestedDate), 'utc'),
                      'MM/dd/yyyy'
                    )}
                  </TableCell>
                  <TableCell>
                    {format(
                      utcToZonedTime(new Date(prescription.needsByDate), 'utc'),
                      'MM/dd/yyyy'
                    )}
                  </TableCell>
                  <TableCell>
                    <Tooltip
                      title={prescription.inBatchShipping ? 'Item in batch shipping' : ''}
                      arrow
                      placement='top'
                    >
                      {prescription.inBatchShipping ? <ShippingIcon color='primary' /> : <Grid />}
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    <PrescriptionReviewOptions
                      status={prescription.status}
                      prescriptionId={prescription.pomPrescriptionId}
                      patientId={prescription.patient.mpi}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>

            {!isNil(prescriptions) && totalPages > 1 && (
              <TableFooter>
                <TableRow>
                  <TableCell colSpan={12} align='right' sx={{ borderBottom: 'none' }}>
                    <Pagination
                      sx={{ justifyContent: 'flex-end', alignItems: 'flex-end', display: 'flex' }}
                      count={totalPages}
                      page={currentPage}
                      size='small'
                      onChange={handlePageChange}
                    />
                  </TableCell>
                </TableRow>
              </TableFooter>
            )}
          </Table>
        </TableContainer>
      </Grid>

      {isNil(prescriptions) && (
        <Grid item>
          <LoadingModule />
        </Grid>
      )}

      {!isNil(prescriptions) && isEmpty(prescriptions) && (
        <Grid item>
          <NothingFound />
        </Grid>
      )}
    </Grid>
  );
};

export default PrescriptionReviewTable;
