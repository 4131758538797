import { blue, grey, red } from '@mui/material/colors';

const customColors = {
  perfectRxBlue: '#031c57',
  grey: grey[400],
  darkGrey: grey[900],
  lightGrey: grey[100],
  blue: blue[500],
  darkBlue: blue[700],
  error: red[800],
  success: '#095F48',
};

export default {
  palette: {
    primary: {
      main: customColors.perfectRxBlue,
      contrastText: '#FFF',
    },
    secondary: {
      main: customColors.darkGrey,
      contrastText: customColors.lightGrey,
    },
    error: {
      main: customColors.error,
      contrastText: customColors.lightGrey,
    },
    warning: {
      main: customColors.perfectRxBlue,
      contrastText: customColors.darkGrey,
    },
    success: {
      main: customColors.success,
      contrastText: customColors.lightGrey,
    },
    info: {
      main: customColors.blue,
      contrastText: '#FFF',
    },
    background: {
      default: customColors.lightGrey,
    },
    text: {
      primary: customColors.darkGrey,
    },
    customTheme: {
      headerText: customColors.darkGrey,
      navigationBar: customColors.perfectRxBlue,
    },
  },
  shape: {
    borderRadius: 4,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          overflowY: 'overlay',
          scrollbarColor: `${customColors.grey} ${customColors.lightGrey}`,
          scrollbarWidth: 'thin',
          '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
            backgroundColor: customColors.lightGrey,
            width: 14,
            height: 14,
          },
          '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
            borderRadius: 8,
            backgroundColor: customColors.grey,
            minHeight: 24,
            border: `3px solid ${customColors.lightGrey}`,
          },
          '&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus': {
            backgroundColor: customColors.darkGrey,
          },
          '&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active': {
            backgroundColor: customColors.darkGrey,
          },
          '&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover': {
            backgroundColor: customColors.darkGrey,
          },
          '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
            backgroundColor: customColors.lightGrey,
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          fontWeight: 600,
          '&:hover': {
            textDecoration: 'underline',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          minHeight: '1.6rem',
          marginTop: 0,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: customColors.darkGrey,
          '&:focus, &.Mui-focused': {
            color: customColors.darkGrey,
          },
          '&:focus&:invalid&.Mui-focused, &.Mui-error': {
            color: customColors.error,
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: customColors.darkGrey,
          '&:focus, &.Mui-focused': {
            color: customColors.darkGrey,
          },
          '&:focus&:invalid&.Mui-focused, &.Mui-error': {
            color: customColors.error,
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          border: `1px solid ${customColors.grey}`,
          caretColor: customColors.darkGrey,
          '&:focus, &:focus&:invalid, &.Mui-focused': {
            borderColor: customColors.perfectRxBlue,
          },
          '&.Mui-error, &.Mui-error:invalid, &.Mui-error:focus&:invalid': {
            borderColor: customColors.error,
          },
          '& input[type=number]': {
            MozAppearance: 'textfield',
          },
          '& input[type=number]::-webkit-outer-spin-button': {
            WebkitAppearance: 'none',
            margin: 0,
          },
          '& input[type=number]::-webkit-inner-spin-button': {
            WebkitAppearance: 'none',
            margin: 0,
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          '& > form': {
            display: 'contents',
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#FFF',
          color: customColors.darkGrey,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          border: 'none',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          '.MuiOutlinedInput-notchedOutline:hover': {
            border: 'none',
          },
          '&:focus, &:focus&:invalid, &.Mui-focused': {
            borderWidth: 1,
            '.MuiOutlinedInput-notchedOutline': {
              borderColor: customColors.perfectRxBlue,
            },
          },
          '&:focus, &:focus&:invalid, &.Mui-focused&.Mui-error': {
            borderWidth: 1,
            '.MuiOutlinedInput-notchedOutline': {
              borderColor: customColors.error,
            },
          },
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: '16px 24px',
        },
      },
    },
  },
};
