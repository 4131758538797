import { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Form } from 'react-final-form';
import { keys, map } from 'lodash';
import useInfoDialog from 'common/hooks/useInfoDialog';
import { cancelOrderLineItem } from 'store/thunks/patientThunks';
import { enqueueSnackbar } from 'notistack';
import SelectField from 'common/forms/SelectField';
import cancelOrderReasons from 'common/constants/cancelOrderReasons';
import MarkdownField from 'common/forms/MarkdownField';
import { validateMaxLength } from 'common/forms/formValidations';

const CancelLineItemDialog = ({ orderId, mpi, rxNumber, lineItemId, open, onClose }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { InfoDialog, showInfoDialog } = useInfoDialog();
  const dispatch = useDispatch();

  const handleCancelPrescription = ({ cancelReason, note }) => {
    showInfoDialog({
      title: 'Confirm Cancel Prescription',
      message: `Are you sure you want to cancel this prescription, ${rxNumber}?`,
      callback: () => {
        setIsLoading(true);
        dispatch(cancelOrderLineItem({ mpi, lineItemId, orderId, cancelReason, note }))
          .then(({ isOrderCanceled }) => {
            enqueueSnackbar(`Prescription ${rxNumber} Canceled`, {
              variant: 'success',
            });
            onClose(false);
            setIsLoading(false);

            if (isOrderCanceled) {
              enqueueSnackbar(`Order ${orderId} Canceled`, { variant: 'warning' });
            }
          })
          .catch(() => {
            enqueueSnackbar(`Failed to cancel prescription ${rxNumber}`, {
              variant: 'error',
            });
            setIsLoading(false);
          });
      },
      cancelable: true,
      isWarning: true,
      confirmButtonText: 'Yes',
    });
  };

  return (
    <>
      <InfoDialog />

      <Dialog
        open={open}
        onClose={onClose}
        aria-describedby='CancelLineItem-Dialog-header'
        fullWidth
      >
        <DialogTitle id='CancelLineItem-header'>{`Cancel Prescription - ${rxNumber}`}</DialogTitle>

        <Form
          onSubmit={handleCancelPrescription}
          render={({ handleSubmit, invalid }) => (
            <form noValidate onSubmit={handleSubmit}>
              <DialogContent>
                <SelectField
                  id='CancelLineItem-cancelReason-dropdown'
                  label='Reason for canceling prescription'
                  name='cancelReason'
                  required
                >
                  {map(keys(cancelOrderReasons), (key) => (
                    <MenuItem value={key} key={key}>
                      {cancelOrderReasons[key]}
                    </MenuItem>
                  ))}
                </SelectField>

                <MarkdownField
                  name='note'
                  id='CancelLineItem-cancelNote-input'
                  validations={[
                    validateMaxLength('The note is too long. Please make it shorter', 65435),
                  ]}
                  label='Cancel Note'
                  required
                />
              </DialogContent>

              <DialogActions>
                <Button variant='outlined' color='secondary' onClick={onClose}>
                  Cancel
                </Button>
                <LoadingButton
                  loading={isLoading}
                  variant='contained'
                  type='submit'
                  disabled={invalid}
                >
                  Submit
                </LoadingButton>
              </DialogActions>
            </form>
          )}
        />
      </Dialog>
    </>
  );
};

CancelLineItemDialog.propTypes = {
  orderId: PropTypes.string.isRequired,
  mpi: PropTypes.string.isRequired,
  lineItemId: PropTypes.string.isRequired,
  rxNumber: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default CancelLineItemDialog;
