import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Grid, Paper, Tab, Tabs } from '@mui/material';
import PropTypes from 'prop-types';
import { filter, includes, map } from 'lodash';

import useRoles from 'common/hooks/useRoles';
import useTabContext, { TabProvider } from 'common/hooks/useTabContext';
import { workQueueActions } from 'store/slices/workQueueSlice';
import WorkQueues from 'enums/workQueue.enum';

import PatientQualification from './PatientQualification';
import InClaimEligibility from './InClaimEligibility';
import ReviewInClaimEligibility from './ReviewInClaimEligibility';
import OrderLineItems from './OrderLineItems';
import BulkLabels from './BulkLabels';
import ProductFulfillment from './ProductFulfillment';
import OrderVerification from './OrderVerification';
import ReadyToShip from './ReadyToShip';
import ReviewAdjudication from './ReviewAdjudication';
import IncompleteProfile from './IncompleteProfiles';
import PrescriptionReview from './PrescriptionReview';
import EVisitRequests from './eVisitRequests';
import RxInTakeRequests from './RxInTakeRequests';
import ProcessedExternalOrders from './ProcessedExternalOrders';

const TabPanel = ({ children, value }) => {
  const { tab } = useTabContext();
  const hidden = value !== tab;
  return (
    <div
      role='tabpanel'
      hidden={hidden}
      id={`work-queue-tabpanel-${value}`}
      aria-labelledby={`work-queue-tab-${value}`}
    >
      <Box sx={{ px: 1 }}>{children}</Box>
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  value: PropTypes.string.isRequired,
};

const a11yProps = (index) => ({
  id: `work-queue-tab-${index}`,
  'aria-controls': `work-queue-tabpanel-${index}`,
});

const tabsDetails = [
  {
    tabName: 'Patient Qualification',
    Component: PatientQualification,
    tabValue: WorkQueues.PATIENT_QUALIFICATION,
    accessRoleName: 'viewPatientQualificationQueue',
  },
  {
    tabName: 'In Claim Eligibility',
    Component: InClaimEligibility,
    tabValue: WorkQueues.IN_CLAIM_ELIGIBILITY,
    accessRoleName: 'viewInClaimEligibility',
  },
  {
    tabName: 'Review Claim Eligibility',
    Component: ReviewInClaimEligibility,
    tabValue: WorkQueues.REVIEW_IN_CLAIM_ELIGIBILITY,
    accessRoleName: 'reviewInClaimEligibility',
  },
  {
    tabName: 'Rx InTake',
    Component: RxInTakeRequests,
    tabValue: WorkQueues.RX_INTAKE_REQUESTS,
    accessRoleName: 'rxIntakeRequests',
  },
  {
    tabName: 'Order Management',
    Component: OrderLineItems,
    tabValue: WorkQueues.ORDER_LINE_ITEMS,
    accessRoleName: 'listOrderLineItems',
  },
  {
    tabName: 'Bulk',
    Component: BulkLabels,
    tabValue: WorkQueues.BULK_LABELS,
    accessRoleName: 'listBulkLabels',
  },
  {
    tabName: 'Product Fulfillment',
    Component: ProductFulfillment,
    tabValue: WorkQueues.PRODUCT_FULFILLMENT,
    accessRoleName: 'listOrdersForProductFulfillment',
  },
  {
    tabName: 'Order Verification',
    Component: OrderVerification,
    tabValue: WorkQueues.ORDER_VERIFICATION,
    accessRoleName: 'listOrders',
  },
  {
    tabName: 'Ready to Ship',
    Component: ReadyToShip,
    tabValue: WorkQueues.READY_TO_SHIP,
    accessRoleName: 'listReadyToShipOrders',
  },
  {
    tabName: 'Review - Adjudication',
    Component: ReviewAdjudication,
    tabValue: WorkQueues.REVIEW_ADJUDICATION,
    accessRoleName: 'reviewAdjudication',
  },
  {
    tabName: 'Incomplete Profiles',
    Component: IncompleteProfile,
    tabValue: WorkQueues.INCOMPLETE_PROFILES,
    accessRoleName: 'profileIncomplete',
  },
  {
    tabName: 'Prescription Review',
    Component: PrescriptionReview,
    tabValue: WorkQueues.PRESCRIPTION_REVIEW,
    accessRoleName: 'prescriptionReview',
  },
  {
    tabName: 'Awaiting Prescriptions',
    Component: EVisitRequests,
    tabValue: WorkQueues.E_VISIT_REQUESTS,
    accessRoleName: 'eVisitRequests',
  },
  {
    tabName: 'Processed External Orders',
    Component: ProcessedExternalOrders,
    tabValue: WorkQueues.PROCESSED_EXTERNAL_ORDERS,
    accessRoleName: 'processedExternalOrders',
  },
];

const TabList = () => {
  const { tab, setTab } = useTabContext();
  const dispatch = useDispatch();
  const hasAccess = useRoles();
  const showLimitedTabs = useSelector(({ workQueue }) => workQueue.showLimitedTabs);

  const workQueueTabs = showLimitedTabs
    ? filter(
        tabsDetails,
        ({ tabValue }) =>
          !includes(
            [
              WorkQueues.REVIEW_ADJUDICATION,
              WorkQueues.PATIENT_QUALIFICATION,
              WorkQueues.IN_CLAIM_ELIGIBILITY,
              WorkQueues.REVIEW_IN_CLAIM_ELIGIBILITY,
              WorkQueues.READY_TO_SHIP,
            ],
            tabValue
          )
      )
    : tabsDetails;

  useEffect(() => {
    dispatch(workQueueActions.loadSelectedWorkQueues(tab));
  }, [tab, dispatch]);

  return (
    <Box sx={{ borderBottom: 2, borderColor: 'divider' }}>
      <Tabs
        value={tab}
        onChange={setTab}
        aria-label='Patient Details Tabs'
        scrollButtons='auto'
        sx={{ maxWidth: '85vw' }}
        variant='scrollable'
      >
        {map(
          workQueueTabs,
          ({ tabName, tabValue, accessRoleName }, key) =>
            hasAccess[accessRoleName] && (
              <Tab label={tabName} {...a11yProps(key)} key={key} value={tabValue} />
            )
        )}
      </Tabs>
    </Box>
  );
};

const WorkQueueTab = ({ initialTab }) => {
  return (
    <TabProvider initialValue={initialTab}>
      <Grid container component={Paper} sx={{ px: 2 }} direction='column'>
        <TabList />
        {map(tabsDetails, ({ Component, tabValue }) => (
          <TabPanel value={tabValue} key={tabValue}>
            <Component />
          </TabPanel>
        ))}
      </Grid>
    </TabProvider>
  );
};

WorkQueueTab.propTypes = {
  initialTab: PropTypes.string.isRequired,
};

export default WorkQueueTab;
