/* istanbul ignore file */
import { configureStore } from '@reduxjs/toolkit';
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE, persistStore } from 'redux-persist';
import { createLogger } from 'redux-logger';
import * as Sentry from '@sentry/react';

import appReducer from 'store/slices/appSlice';
import errorHandlerReducer from 'store/slices/errorHandlerSlice';
import authReducer from 'store/slices/authSlice';
import patientReducer from 'store/slices/patientSlice';
import patientsListReducer from 'store/slices/patientsListSlice';
import partnerReducer from 'store/slices/partnerSlice';
import workQueueReducer from 'store/slices/workQueueSlice';
import criticalEventsReducer from 'store/slices/eventSlice';
import transactionsReducer from 'store/slices/transactionSlice';
import drugReducer from 'store/slices/drugSlice';
import orderStatusReducer from 'store/slices/orderStatusSlice';
import sigReducer from 'store/slices/sigSlice';
import ordersListReducer from 'store/slices/ordersListSlice';
import referrerReducer from 'store/slices/referrerSlice';
import pharmacyReducer from 'store/slices/pharmacySlice';
import practiceReducer from 'store/slices/practiceSlice';
import batchShipmentReducer from 'store/slices/batchShipmentSlice';
import shippingOptionsReducer from 'store/slices/shippingOptionSlice';

const isLocal = process.env.NODE_ENV === 'development';

const logger =
  isLocal && !process.env.REACT_APP_SENTRY_ENABLED
    ? createLogger({
        diff: true,
        duration: true,
        collapsed: true,
        predicate: (_, action) => action.type !== 'auth/loadUserData',
      })
    : null;

const middlewares = [];

if (logger) {
  middlewares.push(logger);
}

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  actionTransformer: ({ type }) => ({ type }), // only sends the action type, payload sent separately to avoid accidental leak
  stateTransformer: () => null, // intentionally not sending any state, to avoid leak
  configureScopeWithState: (scope, state) => {
    scope.setTag('app.locale', state.app.locale);
    scope.setTag('ipAddress', state.app.ipAddress);
    scope.setTag('geolocation', state.app.geolocation);
  },
});

const customConfigureStore = (preloadedState = {}) => {
  const store = configureStore({
    reducer: {
      app: appReducer,
      errorHandler: errorHandlerReducer,
      auth: authReducer,
      patient: patientReducer,
      patientsList: patientsListReducer,
      partner: partnerReducer,
      workQueue: workQueueReducer,
      criticalEvents: criticalEventsReducer,
      transactions: transactionsReducer,
      drugs: drugReducer,
      orderStatus: orderStatusReducer,
      sigs: sigReducer,
      ordersList: ordersListReducer,
      referrer: referrerReducer,
      pharmacy: pharmacyReducer,
      practice: practiceReducer,
      batchShipment: batchShipmentReducer,
      systemConfigs: shippingOptionsReducer,
    },
    devTools: true,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }).concat(middlewares),
    preloadedState,
    enhancers: [sentryReduxEnhancer],
  });

  const persistor = persistStore(store);

  return { store, persistor };
};

export default customConfigureStore;

export const configuredStore = customConfigureStore();
