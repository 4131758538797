import { Button, Chip, Stack } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import { compact, isEmpty, values } from 'lodash';

import { filterEVisitRequests } from 'store/thunks/workQueueThunks';
import FacilityFilterStatusChip from './FacilityFilterStatusChip';

const EVisitRequestsFilterStatusBar = () => {
  const dispatch = useDispatch();
  const filter = useSelector(({ workQueue }) => workQueue.eVisitRequests?.filter);

  const removeFilter = (filterName) => () =>
    dispatch(filterEVisitRequests({ filter: { ...filter, [filterName]: undefined } }));

  const removeAllFilter = () => dispatch(filterEVisitRequests({ filter: {} }));

  return (
    <Stack direction='row' spacing={1}>
      <FacilityFilterStatusChip
        removeFilter={removeFilter}
        selectedFacilities={filter?.fulfillingPharmacyIds || []}
      />

      {filter.patientName && (
        <Chip
          label={`Patient = ${filter.patientName}`}
          variant='contained'
          onDelete={removeFilter('patientName')}
        />
      )}

      {filter.bundleLabel && (
        <Chip
          label={`Drug Name = ${filter.bundleLabel}`}
          variant='contained'
          onDelete={removeFilter('bundleLabel')}
        />
      )}

      {filter.provider && (
        <Chip
          label={`Provider = ${filter.provider}`}
          variant='contained'
          onDelete={removeFilter('provider')}
        />
      )}

      {filter.requestedDate && (
        <Chip
          label={`Requested Date = ${format(new Date(filter.requestedDate), 'MM/dd/yyyy')}`}
          variant='contained'
          onDelete={removeFilter('requestedDate')}
        />
      )}

      {filter.status && (
        <Chip
          label={`Status = ${filter.status}`}
          variant='contained'
          onDelete={removeFilter('status')}
        />
      )}

      {!isEmpty(compact(values(filter))) && <Button onClick={removeAllFilter}>Remove All</Button>}
    </Stack>
  );
};

export default EVisitRequestsFilterStatusBar;
