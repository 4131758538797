import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import partnerAndPracticeManagement from 'enums/partnerAndPracticeManagement.enum';
import PartnerAndPracticeTab from './components/PartnerAndPracticeTab';

const PartnerAndPracticeManagement = () => {
  const [initialTab, setInitialTab] = useState(partnerAndPracticeManagement.PARTNER_MANAGEMENT);

  useEffect(() => {
    setInitialTab(partnerAndPracticeManagement.PARTNER_MANAGEMENT);
  }, [initialTab]);

  return (
    <Grid container sx={{ px: 3, pb: 2 }} direction='column'>
      <Grid item sx={{ flexgrow: 1 }}>
        {initialTab && <PartnerAndPracticeTab initialTab={initialTab} />}
      </Grid>
    </Grid>
  );
};
export default PartnerAndPracticeManagement;
