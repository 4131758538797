import { isEmpty } from 'lodash';
import { Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import WorkQueues from 'enums/workQueue.enum';
import { getPartners } from 'store/thunks/partnerThunks';
import { workQueueActions } from 'store/slices/workQueueSlice';
import roles from 'common/constants/roles';
import useRoles from 'common/hooks/useRoles';
import { listShippingOptions } from 'store/thunks/batchShipmentThunk';

import WorkQueueTab from './components/WorkQueueTab';

const WorkQueue = () => {
  const dispatch = useDispatch();
  const { hasAuthorizedRoles } = useRoles();
  const preset = useSelector(({ workQueue }) => workQueue.preset);
  const shippingOptions = useSelector(({ systemConfigs }) => systemConfigs?.shippingOptions);
  const partners = useSelector(({ auth, partner }) => partner[auth.currentTenantId]);
  const [initialTab, setInitialTab] = useState(preset);
  const isEmptyPartners = isEmpty(partners);
  const isEmptyShippingOptions = isEmpty(shippingOptions);

  useEffect(() => {
    document.title = 'Work Queue - SmartOS';
  }, []);

  useEffect(() => {
    if (isEmptyPartners) {
      dispatch(getPartners());
    }

    if (isEmptyShippingOptions) {
      dispatch(listShippingOptions());
    }
  }, [dispatch, isEmptyPartners, isEmptyShippingOptions]);

  useEffect(() => {
    if (!preset) {
      // Don't need to add another if statement just a default preset screen based on role. Like when you login to Facebook it defaults to the feed not your profile page.
      const getInitialPreset = () => {
        if (hasAuthorizedRoles([roles.admin])) return WorkQueues.RX_INTAKE_REQUESTS;
        if (hasAuthorizedRoles([roles.ccc])) return WorkQueues.BULK_LABELS;
        if (hasAuthorizedRoles([roles.pharmacist])) return WorkQueues.RX_INTAKE_REQUESTS;
        if (hasAuthorizedRoles([roles.billingUser])) return WorkQueues.ORDER_LINE_ITEMS;
        if (hasAuthorizedRoles([roles.pharmTech])) return WorkQueues.RX_INTAKE_REQUESTS;
        if (hasAuthorizedRoles([roles.pharmacyProdTech])) return WorkQueues.BULK_LABELS;
        if (hasAuthorizedRoles([roles.shippingUser])) return WorkQueues.READY_TO_SHIP;
        throw Error('No available queue for this role');
      };
      const initialPreset = getInitialPreset();
      setInitialTab(initialPreset);
      dispatch(workQueueActions.loadSelectedWorkQueues(initialPreset));
    }
  }, [dispatch, hasAuthorizedRoles, preset]);

  return (
    <Grid container sx={{ px: 3, pb: 2 }} direction='column'>
      <Grid item container alignItems='center' spacing={2}>
        <Grid item>
          <Typography component='h1' variant='h5'>
            Work Queues
          </Typography>
        </Grid>
      </Grid>

      <Grid item sx={{ flexgrow: 1, mt: 2 }}>
        {initialTab && <WorkQueueTab initialTab={initialTab} />}
      </Grid>
    </Grid>
  );
};

export default WorkQueue;
