/* eslint-disable max-lines */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  ListItemText,
  MenuItem,
  Radio,
  Stack,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { Form } from 'react-final-form';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import LoadingButton from '@mui/lab/LoadingButton';
import { isArray, isEmpty, map, omit, reduce } from 'lodash';
import PropTypes from 'prop-types';

import TextField from 'common/forms/TextField';
import SelectField from 'common/forms/SelectField';
import { validateEmail, validatePhoneNumber, validateZipCode } from 'common/forms/formValidations';
import { addGuarantor, validateAddress } from 'store/thunks/patientThunks';
import guarantorRelationship from 'common/constants/guarantorRelationship';
import CheckboxField from 'common/forms/CheckboxField';
import usStateAbbreviation from 'common/constants/usStateAbbreviation';
import { formatZipCode } from 'common/utils';
import RadioGroupField from 'common/forms/RadioGroupField';

const NewGuarantorButton = ({ mpi }) => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const patientName = useSelector(
    ({ patient }) => ({
      firstName: patient[mpi]?.firstName,
      middleName: patient[mpi]?.middleName,
      lastName: patient[mpi]?.lastName,
    }),
    shallowEqual
  );
  const [guarantorDataToSave, setGuarantorDataToSave] = useState({});
  const [validatedAddresses, setValidatedAddresses] = useState([]);
  const [isMultipleAddresses, setIsMultipleAddresses] = useState(false);
  const [loading, setLoading] = useState(false);

  const closeConfirmAddressDialog = () => setIsMultipleAddresses(false);

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = (_, reason) => {
    /* istanbul ignore next */
    if (reason !== 'backdropClick') {
      setGuarantorDataToSave({});
      setLoading(false);
      setShowModal(false);
    }
  };

  const handleAddGuarantor = (formData) => {
    setLoading(true);
    const { addressLine1, addressLine2, city, state, zip } = formData;
    const address = { line1: addressLine1, line2: addressLine2, city, state, zip };
    setGuarantorDataToSave(formData);

    return dispatch(validateAddress({ input: address }))
      .then((result) => {
        if (isArray(result)) {
          setValidatedAddresses(result);
          setLoading(false);
          setIsMultipleAddresses(true);
        }
      })
      .catch(() => setLoading(false));
  };

  const handleFinalConfirmAddress = (selectedAddress) => {
    const address = validatedAddresses[selectedAddress.addressChoice];
    const formattedGuarantorData = {
      ...guarantorDataToSave,
      addressLine1: address.line1,
      addressLine2: address.line2,
      city: address.city,
      state: address.state,
      zip: address.zip,
    };

    setGuarantorDataToSave(formattedGuarantorData);
    setIsMultipleAddresses(false);

    const phoneNumbers = reduce(
      formattedGuarantorData,
      (results, value, key) => {
        switch (key) {
          case 'mobilePhone':
            return [...results, { phoneNumber: value, tags: ['MOBILE'] }];
          case 'workPhone':
            return [...results, { phoneNumber: value, tags: ['WORK'] }];
          case 'homePhone':
            return [...results, { phoneNumber: value, tags: ['HOME'] }];
          default:
            return results;
        }
      },
      []
    );

    const transformGuarantor = (guarantor) => ({
      textsEnabled: formattedGuarantorData.textsEnabled ?? false,
      isEmergencyContact: formattedGuarantorData.textsEnabled ?? false,
      ...omit(guarantor, ['mobilePhone', 'workPhone', 'homePhone']),
      ...(guarantor.relationship === 'SELF' && patientName),
      mpi,
      phoneNumbers,
    });

    setLoading(true);

    dispatch(addGuarantor({ guarantor: transformGuarantor(formattedGuarantorData) })).then(
      (success) => {
        if (success) {
          handleCloseModal();
        }

        setLoading(false);
        setGuarantorDataToSave({});
      }
    );
  };

  return (
    <>
      <Button variant='contained' disabled={!patientName.firstName} onClick={handleOpenModal}>
        New
      </Button>

      {isMultipleAddresses && !isEmpty(validatedAddresses) && (
        <Dialog
          open={isMultipleAddresses}
          maxWidth='sm'
          fullWidth
          aria-labelledby='AddPatient-ConfirmAddress-header'
        >
          <Typography variant='h6' sx={{ px: 3, pt: 2 }}>
            Confirm Address
          </Typography>

          <Form
            onSubmit={handleFinalConfirmAddress}
            render={({ values, handleSubmit }) => (
              <form noValidate onSubmit={handleSubmit}>
                <DialogContent>
                  <Typography sx={{ pb: 2 }}>
                    {validatedAddresses.length > 1
                      ? 'Multiple addresses found. Please choose one from the suggestions below.'
                      : 'Please confirm the address below.'}
                  </Typography>
                  <RadioGroupField
                    name='addressChoice'
                    id='AddPatient-AddressChoice-radioField'
                    label=''
                  >
                    <Stack direction='column' alignItems='flex-start' gap={2}>
                      {map(validatedAddresses, (address, index) => (
                        <FormControlLabel
                          key={`validateAddress-${index}`}
                          value={index}
                          control={<Radio sx={{ mr: 1 }} />}
                          sx={{ px: 1 }}
                          label={
                            <ListItemText
                              secondary={
                                <>
                                  <Typography>{address.line1}</Typography>
                                  {address?.line2 && <Typography>{address.line2}</Typography>}
                                  <Typography>
                                    {address.city} , {address.state}, {address.zip}
                                  </Typography>
                                </>
                              }
                            />
                          }
                        />
                      ))}
                    </Stack>
                  </RadioGroupField>
                </DialogContent>
                <DialogActions>
                  <Button variant='outlined' color='secondary' onClick={closeConfirmAddressDialog}>
                    Cancel
                  </Button>
                  <Button
                    variant='contained'
                    disabled={isEmpty(values?.addressChoice)}
                    onClick={handleSubmit}
                    type='submit'
                  >
                    Confirm
                  </Button>
                </DialogActions>
              </form>
            )}
          />
        </Dialog>
      )}

      <Dialog
        open={showModal}
        onClose={handleCloseModal}
        aria-describedby='PatientView-NewGuarantorButton-header'
        fullWidth
      >
        <DialogTitle id='PatientView-NewGuarantorButton-header'>Add Guarantor</DialogTitle>

        <Form
          onSubmit={handleAddGuarantor}
          initialValues={guarantorDataToSave}
          render={({ handleSubmit, invalid, values }) => (
            <form noValidate onSubmit={handleSubmit}>
              <DialogContent>
                <SelectField
                  name='relationship'
                  id='PatientView-NewGuarantorButton-relationship-dropdown'
                  label='Relationship'
                  required
                >
                  {map(guarantorRelationship, (value, key) => (
                    <MenuItem key={key} value={key}>
                      {value}
                    </MenuItem>
                  ))}
                </SelectField>

                {values.relationship !== 'SELF' && (
                  <>
                    <TextField
                      id='PatientView-NewGuarantorButton-firstName-input'
                      label='First Name'
                      name='firstName'
                      required
                    />

                    <TextField
                      id='PatientView-NewGuarantorButton-middleName-input'
                      label='Middle Name'
                      name='middleName'
                    />

                    <TextField
                      id='PatientView-NewGuarantorButton-lastName-input'
                      label='Last Name'
                      name='lastName'
                      required
                    />
                  </>
                )}

                <TextField
                  id='PatientView-NewGuarantorButton-email-input'
                  label='Email'
                  name='email'
                  required
                  validations={[validateEmail()]}
                />

                <TextField
                  id='PatientView-NewGuarantorButton-mobilePhoneNumber-input'
                  label='Mobile Phone Number'
                  name='mobilePhone'
                  required
                  validations={[validatePhoneNumber()]}
                />

                <TextField
                  id='PatientView-NewGuarantorButton-workPhoneNumber-input'
                  label='Work Phone Number'
                  name='workPhone'
                  validations={[validatePhoneNumber()]}
                />

                <TextField
                  id='PatientView-NewGuarantorButton-homePhoneNumber-input'
                  label='Home Phone Number'
                  name='homePhone'
                  validations={[validatePhoneNumber()]}
                />

                <TextField
                  id='PatientView-NewGuarantorButton-addressLine1-input'
                  label='Address Line 1'
                  name='addressLine1'
                  required
                />

                <TextField
                  id='PatientView-NewGuarantorButton-addressLine2-input'
                  label='Address Line 2'
                  name='addressLine2'
                />

                <TextField
                  id='PatientView-NewGuarantorButton-city-input'
                  label='City'
                  name='city'
                  required
                />

                <SelectField
                  id='PatientView-NewGuarantorButton-state-dropdown'
                  label='State'
                  name='state'
                  required
                >
                  {map(usStateAbbreviation, ({ name, abbreviation }) => (
                    <MenuItem value={abbreviation} key={abbreviation}>
                      {name}
                    </MenuItem>
                  ))}
                </SelectField>

                <TextField
                  id='PatientView-NewGuarantorButton-zip-input'
                  label='Zip'
                  name='zip'
                  type='zip'
                  maxLength={10}
                  format={formatZipCode}
                  validations={[validateZipCode()]}
                  required
                />

                <CheckboxField
                  label='Text Notification Preference'
                  name='textsEnabled'
                  id='PatientView-NewGuarantorButton-textNotificationPreference-checkbox'
                />

                <CheckboxField
                  label='Emergency Contact'
                  name='isEmergencyContact'
                  id='PatientView-NewGuarantorButton-isEmergencyContact-checkbox'
                />
              </DialogContent>

              <DialogActions>
                <Button variant='outlined' color='secondary' onClick={handleCloseModal}>
                  Cancel
                </Button>
                <LoadingButton
                  loading={loading}
                  variant='contained'
                  disabled={invalid}
                  type='submit'
                >
                  Save
                </LoadingButton>
              </DialogActions>
            </form>
          )}
        />
      </Dialog>
    </>
  );
};

NewGuarantorButton.propTypes = {
  mpi: PropTypes.string.isRequired,
};

export default NewGuarantorButton;
