import {
  Chip,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import lineItemStatus from 'common/constants/lineItemStatus';
import orderFillType from 'common/constants/orderFillType';
import { useTheme } from '@mui/material/styles';
import { find, map } from 'lodash';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import ShippingIcon from '@mui/icons-material/LocalShipping';

const headCells = [
  {
    id: 'itemName',
    label: 'Drug Name',
  },
  {
    id: 'rxNumber',
    label: 'Rx Number',
  },
  {
    id: 'lineItemStatus',
    label: 'Item Status',
  },
  {
    id: 'fillType',
    label: 'Fill Type',
  },
  {
    id: 'shippingOption',
    label: 'Shipping Option',
  },
  {
    id: 'form',
    label: 'Form',
  },
  {
    id: 'strength',
    label: 'Strength',
  },
  {
    id: 'quantity',
    label: 'Quantity',
  },
  {
    id: 'needsByDate',
    label: 'Needs By Date',
  },
];

const ProductFulfillmentLineItemsRow = ({ lineItemRow, lineItems }) => {
  const theme = useTheme();
  const shippingOptions = useSelector(({ systemConfigs }) => systemConfigs.shippingOptions);

  return (
    <TableContainer sx={{ mb: 2, p: 2, ml: 10 }} key={`lineItemRow-${lineItemRow}`}>
      <Table size='small'>
        <TableHead>
          <TableRow hover>
            {headCells.map((headCell) => (
              <TableCell align='center' key={headCell.id} sx={{ fontWeight: '600' }}>
                {headCell.label}
              </TableCell>
            ))}
            <TableCell />
          </TableRow>
        </TableHead>

        <TableBody>
          {map(lineItems, (item) => (
            <TableRow key={item.lineItemId} hover>
              <TableCell align='center'>{item.itemName}</TableCell>
              <TableCell align='center'>{item.rxNumber}</TableCell>
              <TableCell align='center'>{lineItemStatus[item.lineItemStatus]}</TableCell>
              <TableCell align='center'>
                <Chip
                  sx={{
                    background:
                      item.fillType === 'REFILL'
                        ? theme.palette.info.dark
                        : theme.palette.success.dark,
                    color: theme.palette.primary.contrastText,
                    borderRadius: '5px',
                    height: '25px',
                    width: '70px',
                  }}
                  label={
                    <Typography sx={{ fontSize: '1.1em' }}>
                      {orderFillType[item.fillType]}
                    </Typography>
                  }
                />
              </TableCell>
              <TableCell align='center'>
                {item.shippingOption
                  ? find(shippingOptions, (option) => option.optionCode === item.shippingOption)
                      ?.shippingOption || item.shippingOption
                  : 'NA'}
              </TableCell>
              <TableCell align='center'>{item.form}</TableCell>
              <TableCell align='center'>{item.strength}</TableCell>
              <TableCell align='center'>{item.quantity}</TableCell>
              <TableCell align='center'>
                {format(utcToZonedTime(new Date(item.needsByDate), 'utc'), 'MM/dd/yyyy')}
              </TableCell>
              <TableCell>
                <Tooltip
                  title={item.inBatchShipmentQueue ? 'Item in batch shipping' : ''}
                  arrow
                  placement='top'
                >
                  {item.inBatchShipmentQueue ? <ShippingIcon color='primary' /> : <Grid />}
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

ProductFulfillmentLineItemsRow.propTypes = {
  lineItemRow: PropTypes.string.isRequired,
  lineItems: PropTypes.arrayOf(
    PropTypes.shape({
      lineItemId: PropTypes.string,
      itemName: PropTypes.string,
      rxNumber: PropTypes.string,
      strength: PropTypes.string,
      form: PropTypes.string,
      quantity: PropTypes.string,
      fillType: PropTypes.string,
      needsByDate: PropTypes.string,
    })
  ).isRequired,
};

export default ProductFulfillmentLineItemsRow;
