import moment from 'moment';
import { React, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-final-form';
import { Button, Grid, Popover, Typography } from '@mui/material';
import { validateMinLength } from 'common/forms/formValidations';
import DateField from 'common/forms/DateField';
import TextField from 'common/forms/TextField';
import PracticeProfileFieldKeys from 'enums/practice.enum';
import { updatePracticeProfileFilter } from 'store/thunks/practiceThunks';

const PracticeFilterButton = () => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);

  const { filter } = useSelector(({ practice }) => practice.practiceManagement);

  const handleOpen = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const popoverId = anchorEl ? 'PracticeFilterButton-Popover' : undefined;

  const handleApplyFilter = (formData) => {
    setAnchorEl(null);
    dispatch(
      updatePracticeProfileFilter({
        filter: {
          ...formData,
          createdAt: formData?.createdAt && moment(formData.createdAt).format('YYYY-MM-DD'),
          updatedAt: formData?.updatedAt && moment(formData.updatedAt).format('YYYY-MM-DD'),
        },
      })
    );
  };
  return (
    <>
      <Button
        sx={{ height: 33 }}
        variant='outlined'
        color='secondary'
        onClick={handleOpen}
        aria-describedby={popoverId}
      >
        Filter
      </Button>
      <Popover
        id={popoverId}
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Grid container sx={{ p: 2, maxWidth: 300 }} spacing={2} direction='column'>
          <Grid item>
            <Typography component='h2'>Filter</Typography>
          </Grid>

          <Grid item>
            <Form
              initialValues={{
                ...filter,
                [PracticeProfileFieldKeys.CREATED_AT]: filter[PracticeProfileFieldKeys.CREATED_AT]
                  ? moment(filter[PracticeProfileFieldKeys.CREATED_AT], 'YYYY-MM-DD').toDate()
                  : null,
                [PracticeProfileFieldKeys.UPDATED_AT]: filter[PracticeProfileFieldKeys.UPDATED_AT]
                  ? moment(filter[PracticeProfileFieldKeys.UPDATED_AT], 'YYYY-MM-DD').toDate()
                  : null,
              }}
              onSubmit={handleApplyFilter}
              render={({ handleSubmit, invalid }) => (
                <form onSubmit={handleSubmit} noValidate>
                  <TextField
                    label='Practice Name'
                    name={PracticeProfileFieldKeys.PRACTICE_NAME}
                    id='PracticeFilterButton-practiceName-input'
                    validations={[validateMinLength('Minimum of 3 characters', 3)]}
                  />

                  <TextField
                    label='Partner Name'
                    name={PracticeProfileFieldKeys.PARTNER_NAME}
                    id='PracticeFilterButton-partnerName-input'
                    validations={[validateMinLength('Minimum of 3 characters', 3)]}
                  />
                  <DateField
                    label='Created At'
                    name={PracticeProfileFieldKeys.CREATED_AT}
                    id='PracticeFilterButton-createdAt-input'
                    maxDate={new Date()}
                  />

                  <DateField
                    label='Updated At'
                    name={PracticeProfileFieldKeys.UPDATED_AT}
                    id='PracticeFilterButton-updatedAt-input'
                    maxDate={new Date()}
                  />

                  <Grid container justifyContent='flex-end'>
                    <Button variant='contained' type='submit' disabled={invalid}>
                      Apply
                    </Button>
                  </Grid>
                </form>
              )}
            />
          </Grid>
        </Grid>
      </Popover>
    </>
  );
};

export default PracticeFilterButton;
